export const homeReducer = (state, action) => {
    switch(action.type) {
        case "VALIDATE_USER":
            return {
                ...state,
                data: action.payload,
                error: ''
            }
         case "HOME_PATH":
            return {
                ...state,
                path: action.payload
            }
        default:
            return state
    }
}