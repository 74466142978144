import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Modal, Typography } from '@mui/material';
import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(13, 14, 11, 0.75);',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MultipleLoginComponent = (props) => {
    const history = useHistory();

    return (
        <div>
            <Modal
                open={props.open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-multiple-login'>

                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="center modal-multiple-login-text">
                            Warning!
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className="center modal-multiple-login-message">
                            Multiple logins detected. This incident will be reported to the Administrator.
                        </Typography>
                    </Box>
                </div>
            </Modal>
        </div>
    )
}

export default MultipleLoginComponent;