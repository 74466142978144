import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { firstTImeLoginReducer } from './firstTImeLoginReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
export const FirstTimeLoginContext = createContext();


const FirstTimeLoginContextProvider = (props) => {
  const history = useHistory();
  const [firstTimeLogin, dispatch] = useReducer(firstTImeLoginReducer, {
    results: {}
  });


  return (
    <FirstTimeLoginContext.Provider
      value={{
        firstTimeLogin,
        dispatch,
      }}>
      {props.children}
    </FirstTimeLoginContext.Provider>
  )
}

export default FirstTimeLoginContextProvider;
