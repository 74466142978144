import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from 'axios';
import FadeIn from 'react-fade-in';
import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../../../helpers/env';
import { noty } from '../../../../helpers/noty';
import MultipleLoginComponent from '../multipleLogin';
import './styles.css';
import { assetsUrl } from '../../../../helpers/assetsUrl';
import DeactivatedUserComponent from '../deactivatedUser';

const ShowAllVideosPerSubjectComponent = (props) => {
    const history = useHistory();
    const [infosss, setInformation] = useState({});
    const [videos, setVideos] = useState([]);
    const [listOfMarkVideos, setListOfMarkVideos] = useState([]);
    const [listOfUnMarkVideos, setListOfUnMarkVideos] = useState([]);
    const [markWatchVideoId, setMarkWatchVideoId] = useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [searchBoxDisplay, setSearchBoxDisplay] = React.useState('none');
    const [searchBox, setSearchBox] = React.useState('');
    const [watchedVideoPercentage, setWatchedVideoPercentage] = React.useState('');
    const [totalVideos, setTotalVideos] = React.useState(0);
    const [totalWatchedVideos, setTotalWatchedVideos] = React.useState(0);
    // account deactivated
    const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);


    const percentage = (watchedVideo, totalVideo) => {
        return (100 * watchedVideo) / totalVideo;
    };

    useEffect(() => {
        const subjectId = props.subjectId;
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
        const headers = {
            headers: {
                'x-client-id': pinnacleStreamingV2ClientId,
                'x-client-secret': pinnacleStreamingV2ClientSecret,
                'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
            }
        }
        axios
            .get(`${BasePath}/videos?subjectId=${subjectId}`, headers)
            .then(response => {
                if (response.status === 200) {
                    setInformation(response.data);
                    setVideos(response.data.data)
                    if (response.data.data.length > 0) {
                        const totalVids = response.data.data.length;
                        let totalWatched = 0;
                        response.data.data.forEach(vid => {
                            if (vid.isWatched) {
                                totalWatched = totalWatched + 1;
                            }
                        });
                        setTotalVideos(totalVids);
                        setTotalWatchedVideos(totalWatched);
                        const average = percentage(totalWatched, totalVids)
                        setWatchedVideoPercentage(Math.round(average));
                    }
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.message === "Token Expired") {
                            setModalOpen(true);
                        } else {
                            setModalOpenDeactivate(true);
                        }
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                    if (error.response.status === 500) {
                        setModalOpen(true);
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                } else if (error.request) {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                } else {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                }
            })
    }, [])

    const validateToken = () => {
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
        const headers = {
            headers: {
                'x-client-id': pinnacleStreamingV2ClientId,
                'x-client-secret': pinnacleStreamingV2ClientSecret,
                'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
            }
        }
        axios
            .get(`${BasePath}/auth/validate`, headers)
            .then()
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.message === "Token Expired") {
                            setModalOpen(true);
                        } else {
                            setModalOpenDeactivate(true);
                        }
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                    if (error.response.status === 500) {
                        setModalOpen(true);
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                } else if (error.request) {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                } else {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                }
            })
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setSearchBoxDisplay('none');
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, sort, orderBy) => {
        e.preventDefault();
        setAnchorEl(null);
        if (orderBy === "ASC" || orderBy === "DESC") {
            setVideos([]);
            handleSortVideos(sort, orderBy);
        }
    };

    const handleSortVideos = (sort, orderBy) => {
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            axios
                .get(`${BasePath}/videos?subjectId=${props.subjectId}&sortBy=${sort}&orderBy=${orderBy}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setVideos(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })
        }
    }

    const handleSearchBoxDisplay = () => {
        if (searchBoxDisplay === 'block') {
            setSearchBoxDisplay('none')
        } else {
            setSearchBoxDisplay('block')
        }
    }

    const handleSetSearchBox = (e, keyword) => {
        setSearchBox(keyword);
        if (keyword === "") {
            handleSortVideos('createdAt', 'DESC');
        } else {
            handleSubjectSearch(e);
        }
    }

    const handleClearSearchBox = () => {
        setSearchBox('');
        handleSortVideos('createdAt', 'DESC');
    }

    const handleSubjectSearch = (e) => {
        e.preventDefault();
        if (searchBox === "") {
            handleSortVideos('createdAt', 'DESC');
        } else {
            const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
            if (userDataStorage === null) {
                history.push("/login");
            } else {
                const parseUserDataStorage = JSON.parse(userDataStorage)
                const headers = {
                    headers: {
                        'x-client-id': pinnacleStreamingV2ClientId,
                        'x-client-secret': pinnacleStreamingV2ClientSecret,
                        'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                    }
                }
                axios
                    .get(`${BasePath}/search?keyword=${searchBox}&subjectId=${props.subjectId}&type=video`, headers)
                    .then(response => {
                        if (response.status === 200) {
                            setVideos(response.data.data)
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                if (error.response.data.message === "Token Expired") {
                                    setModalOpen(true);
                                } else {
                                    setModalOpenDeactivate(true);
                                }
                                setTimeout(() => {
                                    history.push("/login");
                                }, 3000);
                            }
                            if (error.response.status === 500) {
                                setModalOpen(true);
                                setTimeout(() => {
                                    history.push("/login");
                                }, 3000);
                            }
                        } else if (error.request) {
                            noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                        } else {
                            noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                        }
                    })
            }
        }
    }

    const handleMarkVideo = async (e, videoId) => {
        validateToken();
        setListOfMarkVideos(oldArray => [...oldArray, videoId]);
        setMarkWatchVideoId(videoId);
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            await axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "add" }, headers)
            const updatedAverage = percentage(totalWatchedVideos + 1, totalVideos)
            setTotalWatchedVideos(totalWatchedVideos + 1)
            setWatchedVideoPercentage(Math.round(updatedAverage));

            const subjectId = props.subjectId;
            await axios
                .get(`${BasePath}/videos?subjectId=${subjectId}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setVideos(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })

        }
    }


    const handleUnMarkVideo = async (e, videoId) => {
        validateToken();
        setListOfUnMarkVideos(oldArray => [...oldArray, videoId]);
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            await axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "remove" }, headers)
            const updatedAverage = percentage(totalWatchedVideos - 1, totalVideos)
            setTotalWatchedVideos(totalWatchedVideos - 1)
            setWatchedVideoPercentage(Math.round(updatedAverage));

            const subjectId = props.subjectId;
            await axios
                .get(`${BasePath}/videos?subjectId=${subjectId}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setInformation(response.data);
                        setVideos(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })
        }
    }


    const redirectWatchVideo = (e, videoInfo) => {
        e.preventDefault();
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
            if (recentWatched === null) {
                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify(videoInfo)
                );
            } else {
                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify({ "status": "none" })
                );

                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify(videoInfo)
                );
            }
            history.push(`/watch/${videoInfo._id}`)
        }
    }

    return (
        <div>
            <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
            <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

            <div className="pb-20 container"></div>
            <div className="pl-30 pr-30 pt-25 pb-25 container center">
                <h3 className="modal-show-all-subject-title">{props.subjectName}</h3>
                <p className="modal-show-all-watched-status"><b className='modal-show-all-status-color'>{watchedVideoPercentage}%</b> watched</p>

                {/* SORT BUTTON */}
                <p className="modal-show-all-search">
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className="modal-video-sort-icon"
                    >
                        <img src={anchorEl === null ? assetsUrl.sort : assetsUrl.sortActive} className="modal-show-all-sort" />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={(e) => handleClose(e, 'createdAt', 'DESC')} className="modal-video-sort-font">By date</MenuItem>
                        <MenuItem onClick={(e) => handleClose(e, 'title', 'ASC')} className="modal-video-sort-font">By name</MenuItem>
                    </Menu>
                </p>

                {/* SEARCH BUTTON */}
                <p className="modal-show-all-search">
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        className="modal-video-sort-icon"
                        onClick={() => handleSearchBoxDisplay()}
                    >
                        <img src={searchBoxDisplay === "block" ? assetsUrl.searchActive : assetsUrl.search} className="modal-show-all-sort" />
                    </Button>
                </p>
                {/* <BrowserView>
                    <h3 className="modal-show-all-subject-title">{props.subjectName}</h3>
                </BrowserView> */}

                {/* SEARCH BOX CONTENT */}
                <div className='pb-50'>
                    <div className='pb-50'></div>
                    <FadeIn visible={searchBoxDisplay === 'block' ? true : false}>
                        {/* <BrowserView> */}
                        <div class="inner-addon left-addon modal-show-all-searchbox">
                            <i class="fa fa-search"></i>
                            <input type="text" value={searchBox} onChange={(e) => handleSetSearchBox(e, e.target.value)} class="custom-searchbox" />
                            {/* <span class="fa fa-times right-addon clear-searchbox" onClick={handleClearSearchBox}></span> */}
                            <img src={assetsUrl.iconCloseWhite} className="fa fa-times right-addon clear-searchbox" />
                        </div>
                        {/* </BrowserView> */}

                        {/* <MobileView>
                            <div class="inner-addon left-addon modal-show-all-searchbox" style={{ width: '100%' }}>
                                <i class="fa fa-search"></i>
                                <input type="text" value={searchBox} onChange={(e) => handleSetSearchBox(e, e.target.value)} class="custom-searchbox" />
                                <span class="fa fa-times right-addon clear-searchbox" onClick={handleClearSearchBox}></span>
                            </div>
                        </MobileView> */}
                    </FadeIn>
                </div>
                <MobileView>
                    <div className='mt-30'></div>
                </MobileView>

                <Grid sx={{ flexGrow: 1 }} container className="center">
                    <Grid item xs={12}>
                        <Grid container justifyContent="left" spacing={2} className="modal-show-all-grid-video-parent">
                            {videos.length > 0 ?
                                videos.map((video) => {
                                    return (
                                        video.videoUrl === "" ?
                                            <Grid key={video._id} item xs={6} sm={4} md={3} rowSpacing={2} columnSpacing={2} className="custom-grid-size topic">
                                                {/* DISPLAY LOCK IF VIDEO IS NOT ACCESSIBLE */}
                                                <div className='container1'>
                                                    <img
                                                        src={video.thumbnail}
                                                        srcSet={video.thumbnail}
                                                        alt={video.thumbnail}
                                                        className="video-thumb-landscape video-thumb-size topic-low-opacity"
                                                    />
                                                    {/* <FontAwesomeIcon icon={faLock} class="video-play-button icon-tag" /> */}
                                                    <img src={assetsUrl.lockVideo} class="video-play-button icon-tag3" />
                                                </div>
                                                <label class='video-name-label'>{video.title.length > 40 ? video.title.substring(0, 39) + '...' : video.title}</label>
                                                <p class='modal-show-all-duration'>
                                                    {video.duration}
                                                    {
                                                        video.isWatched === true ?
                                                            <span class="watch-disabled float-right"><img src={assetsUrl.watchActive} className="watch-disabled" /></span>
                                                            :
                                                            <span class="watch-disabled float-right"><img src={assetsUrl.watch} className="watch-disabled" /></span>
                                                    }
                                                </p>
                                            </Grid>
                                            :
                                            <Grid key={video._id} item xs={6} sm={4} md={3} rowSpacing={2} columnSpacing={2} className="custom-grid-size">
                                                {/* ENABLED VIDEOS */}
                                                <div className="" onClick={(e) => redirectWatchVideo(e, video)}>
                                                    {/* <img
                                                        src={video.thumbnail}
                                                        srcSet={video.thumbnail}
                                                        alt={video.thumbnail}
                                                        className="video-thumb-landscape video-thumb-size"
                                                    />
                                                    <img src={assetsUrl.videoPlay} className="video-play-button icon-tag3" /> */}
                                                    <div className="img-thumb-overlay-v2">
                                                        <img
                                                            src={video.thumbnail}
                                                            alt={video.thumbnail}
                                                            className="video-thumb-landscape-v2"
                                                        />

                                                        <div class="img-icon-overlay-v2">
                                                            <img src={assetsUrl.videoPlay} className="topic-play-v2 img-icon-v2" onClick={(e) => handleWatchVideo(e, infosss.data.video)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label class='video-name-label'>{video.title.length > 40 ? video.title.substring(0, 39) + '...' : video.title}</label>
                                                <p class='modal-show-all-duration'>
                                                    {video.duration}
                                                    {
                                                        video.isWatched === true ?
                                                            <span class="float-right watch" onClick={(e) => handleUnMarkVideo(e, video._id)}><img src={assetsUrl.watchActive} /></span>
                                                            :
                                                            <span class="float-right watch" onClick={(e) => handleMarkVideo(e, video._id)} title=""><img src={assetsUrl.watch} /></span>
                                                    }
                                                </p>
                                            </Grid>

                                    )
                                })
                                : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </div>


        </div>
    )
}

export default ShowAllVideosPerSubjectComponent;