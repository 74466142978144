import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { signupReducer } from './signupReducer';
export const SignupContext = createContext();


const SignupContextProvider = (props) => {
  const history = useHistory();
  const [signup, dispatch] = useReducer(signupReducer, {
    results: {}
  });

  useEffect(() => {
    const initialState = {
      placeholder: 'none',
      spinner: 'block',
      signupStatus: 'start'
    }
    dispatch({
      type: 'PROGRAM_RESULTS',
      initialState
    })
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
    const headers = {
      headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret,
        'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
      }
    }
    axios
      .get(`${BasePath}/programs`, headers)
      .then(response => {
        dispatch({
          type: 'PROGRAM_RESULTS',
          initialState: {
            placeholder: 'none',
            spinner: 'none',
            signupStatus: 'done'
          },
          payload: response.data
        })
      })

  }, [])


  return (
    <SignupContext.Provider
      value={{
        signup,
        dispatch,
      }}>
      {props.children}
    </SignupContext.Provider>
  )
}

export default SignupContextProvider;
