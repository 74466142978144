export const signupReducer = (state, action) => {
    switch (action.type) {
        case "REGISTER_USER":
            return {
                email: action.signup.email,
                firstName: action.signup.firstName,
                lastName: action.signup.lastName
            }
        case "PROGRAM_RESULTS":
            return {
                initialState: action.initialState,
                results: action.payload,
            }
        default:
            return state
    }
}