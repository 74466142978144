import { useState } from 'react';
import { Link } from "react-router-dom";
import FadeIn from 'react-fade-in';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FullScreenLoader from '../../helpers/fullScreenLoader';
import Notification from '../NotificationComponent';
import { BasePath, headerNonUser } from '../../helpers/env';
import '../../assets/css/main-style.css';
import '../../assets/css/form.css';
import './styles.css'
import axios from 'axios';
import { noty } from '../../helpers/noty';
import { assetsUrl } from '../../helpers/assetsUrl';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const inputStyle = {
	borderRadius: "10px",
	fontSize: "13px",
	WebkitBoxShadow: "0 0 0 1000px #454545 inset",
	disableUnderline: true
};


const ForgotPasswordComponent = (props) => {
	const [email, setEmail] = useState('');
	const [fullScreenLoader, setFullScreenLoader] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationType, setNotificationType] = useState('');
	const [showNotif, setShowNotif] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false)


	const handleForgotSubmit = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		axios
			.post(`${BasePath}/auth/password/forgot`, { email }, headerNonUser)
			.then(response => {
				setFullScreenLoader(false);
				setEmail('');
				setFullScreenLoader(false);
				noty("success", `<i class = "fa fa-check"></i> Please check your email for further instruction.`)
				setShowNotif(true)
				setNotificationMessage('Please check your email for further instruction.')
				setNotificationType('notificationSuccess')
				setTimeout(() => {
					setShowNotif(false)
				}, 3000);
			})
			.catch(error => {
				setFullScreenLoader(false);
				setShowNotif(true)
				if (error.response) {
					if (error.response.status === 400) {
						noty("error", `<i class = "fa fa-times"></i> That Pinnacle account doesn't exist. Enter a different account or create a new one`)
						setNotificationMessage("That Pinnacle account doesn't exist. Enter a different account or create a new one")
						setNotificationType('notificationFailed')
						setTimeout(() => {
							setShowNotif(false)
						}, 3000);
					}
				} else {
					noty("error", `<i class = "fa fa-times"></i> Something went wrong. Please try again`)
					setNotificationMessage('Something went wrong. Please try again')
					setNotificationType('notificationFailed')
					setTimeout(() => {
						setShowNotif(false)
					}, 3000);
				}
			})
	}

	const handleEmail = (email) => {
		setEmail(email)
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) && email !== "") {
			setInvalidEmail(false);
		} else {
			setInvalidEmail(true);
		}
	};

	const handleEmailOnClick = (email) => {
		handleEmail(email);
	}

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div className="wrapper form-bg">
				<FullScreenLoader loading={fullScreenLoader} />
				<div className="right-container custom-container">
					<form className="form-container pb-60 pl-35 pr-35 pt-40 mr-90 fpassword-form" onSubmit={handleForgotSubmit}>
						<FadeIn>
							<div className="float-right">
								<Link to="/login" class="btn-back"><img src={assetsUrl.iconClose} alt="arrow-back" /></Link>
							</div>
							<h2 className="header f-bold text-white">Forgot Password</h2>
							<p class="text-white mb-25 forgot-text">Enter your email to request for a new password.</p>
							<div className="form-group textfield-border-radius">
								<TextField
									type="email"
									label="Email"
									variant="filled"
									fullWidth
									onChange={(e) => handleEmail(e.target.value)}
									onClick={(e) => handleEmailOnClick(e.target.value)}
									value={email}
									error={invalidEmail ? true : false}
									color={invalidEmail || email === '' ? 'error' : 'primary'}
									style={{ marginBottom: "15px" }}
									helperText={invalidEmail ? 'Please enter valid email' : ''}
									inputProps={{ style: inputStyle }}
									InputProps={{ disableUnderline: invalidEmail ? false : true }}
									className="inputRounded"
									rows="10"
									margin="normal"
								/>
							</div>
							<div className="mb-15"></div>
							<button type="submit" className="btn btn-submit button-primary mb-20">Submit</button>
						</FadeIn>
						<div className="center bottom">
							<img src={assetsUrl.logo} className="login-logo" />
						</div>
					</form>
				</div>
				{/* <Notification visible={showNotif} message={notificationMessage} type={notificationType} /> */}
			</div>
		</ThemeProvider>
	);
}

export default ForgotPasswordComponent;