export const BasePath = 'https://api.pinnacleonline.com.ph'; // PROD
// export const BasePath = 'http://159.65.8.232:8011'; //UAT

export const currentEnv = 'prod';

export const pinnacleStreamingV2Creds = {
    clientId: {
        uat: 'Jeyk',
        prod: '79cabc11-8fe6-4662-bc00-70535e3a6824'
    },
    clientSecret: {
        uat: 'Qwe12345',
        prod: 'eb3d68db-170d-4d6b-b056-4ec998d327bc',
    }
};


export const pinnacleStreamingV2ClientId = currentEnv === 'prod' ? pinnacleStreamingV2Creds.clientId.prod : pinnacleStreamingV2Creds.clientId.uat
export const pinnacleStreamingV2ClientSecret = currentEnv === 'prod' ? pinnacleStreamingV2Creds.clientSecret.prod : pinnacleStreamingV2Creds.clientSecret.uat

export const headerNonUser = {
    headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret
    }
}

export const applicationType = 'application/json'
export const multipartType = 'multipart/form-data'
