import axios from 'axios';
import React, { createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { watchVideoReducer } from './watchVideoReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { noty } from '../../helpers/noty';

export const WatchVideoContext = createContext();

const WatchVideoContextProvider = (props) => {
  const history = useHistory();
  const [video, dispatch] = useReducer(watchVideoReducer, {});

  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });
  //   const initialState = {
  //     placeholder: 'none',
  //     spinner: true,
  //     videoDetailsStatus: 'start'
  //   }
  //   dispatch({
  //     type: 'VIDEO_DETAILS',
  //     initialState
  //   })
  //   const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
  //   if (userDataStorage === null) {
  //     // history.push("/login");
  //   } else {
  //     const parseUserDataStorage = JSON.parse(userDataStorage)
  //     const headers = {
  //       headers: {
  //         'x-client-id': pinnacleStreamingV2ClientId,
  //         'x-client-secret': pinnacleStreamingV2ClientSecret,
  //         'token': parseUserDataStorage.data.token
  //       }
  //     }
  //     axios
  //       .get(`${BasePath}/auth/validate`, headers)
  //       .then(response => {
  //         var videoId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  //         getVideoDetails(videoId, headers)
  //       })
  //       .catch(error => {
  //         if (error.response) {
  //           if (error.response.status === 401) {
  //             noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
  //             // history.push("/login");
  //           }
  //           if (error.response.status === 500) {
  //             noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
  //             // history.push("/login");
  //           }
  //         } else if (error.request) {
  //           noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
  //         } else {
  //           noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
  //         }
  //       })
  //   }
  // }, [])

  const getVideoDetails = (videoId, headers) => {
    axios
      .get(`${BasePath}/videos/${videoId}`, headers)
      .then(response => {
        const videoRes = response.data;
        dispatch({
          type: 'VIDEO_DETAILS',
          initialState: {
            placeholder: 'none',
            spinner: false,
            videoDetailsStatus: 'done'
          },
          payload: response.data
        })
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 400) {
            noty("error", `<i class = "fa fa-info-circle"></i> ${error.response.data.error}`);
            dispatch({
              type: 'VIDEO_DETAILS',
              initialState: {
                placeholder: 'none',
                spinner: false,
                videoDetailsStatus: 'failed'
              }
            })
            history.push('/');
          }
          if (error.response.status === 500) {
            noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
          }
        } else if (error.request) {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        } else {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        }
      })
  }
  return (
    <WatchVideoContext.Provider value={{ video, dispatch }}>
      {props.children}
    </WatchVideoContext.Provider>
  )
}

export default WatchVideoContextProvider;
