import LoginContent from './loginContent';

const Login = () => {
  return (
    <div>
		<LoginContent />
    </div>
  );
}

export default Login;