import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { referenceReducer } from './referenceReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import moment from 'moment';

export const ReferenceContext = createContext();

const ReferenceContextProvider = (props) => {
  const history = useHistory();


  return (
    <ReferenceContext.Provider>
      {props.children}
    </ReferenceContext.Provider>
  )
}

export default ReferenceContextProvider;
