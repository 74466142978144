import ResetPasswordContent from './resetPasswordContent';

const ResetPassword = () => {
  return (
    <div>
		<ResetPasswordContent />
    </div>
  );
}

export default ResetPassword;