import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import { assetsUrl } from '../../../../helpers/assetsUrl';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../../../helpers/env';
import { noty } from '../../../../helpers/noty';
import DeactivatedUserComponent from '../deactivatedUser';
import MultipleLoginComponent from '../multipleLogin';
import './styles.css';

const VideosPerSubjectComponent = (props) => {
    const history = useHistory();
    const [infosss, setInformation] = useState({});
    const [relatedVideos, setRelatedVideos] = useState([]);
    const [listOfMarkVideos, setListOfMarkVideos] = useState([]);
    const [listOfUnMarkVideos, setListOfUnMarkVideos] = useState([]);
    const [markWatchVideoId, setMarkWatchVideoId] = useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    // account deactivated
    const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);

    useEffect(() => {
        const videoId = props.videoId;
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
        const headers = {
            headers: {
                'x-client-id': pinnacleStreamingV2ClientId,
                'x-client-secret': pinnacleStreamingV2ClientSecret,
                'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
            }
        }
        axios
            .get(`${BasePath}/videos/${videoId}`, headers)
            .then(response => {
                if (response.status === 200) {
                    setInformation(response.data);
                    setRelatedVideos(response.data.data.relatedVideos)
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.message === "Token Expired") {
                            setModalOpen(true);
                        } else {
                            setModalOpenDeactivate(true);
                        }
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                    if (error.response.status === 500) {
                        setModalOpen(true);
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                } else if (error.request) {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                } else {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                }
            })

    }, [])

    const validateToken = async () => {
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
        const headers = {
            headers: {
                'x-client-id': pinnacleStreamingV2ClientId,
                'x-client-secret': pinnacleStreamingV2ClientSecret,
                'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
            }
        }
        await axios
            .get(`${BasePath}/auth/validate`, headers)
            .then()
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        if (error.response.data.message === "Token Expired") {
                            setModalOpen(true);
                        } else {
                            setModalOpenDeactivate(true);
                        }
                        setTimeout(() => {
                            history.push("/login");
                        }, 3000);
                    }
                    if (error.response.status === 500) {
                        setModalOpen(true);
                        // noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
                        setTimeout(() => {
                            // history.push("/login");
                        }, 3000);
                    }
                } else if (error.request) {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                } else {
                    noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                }
            })
    }

    const handleMarkVideo = async (e, videoId) => {
        await validateToken();
        setListOfMarkVideos(oldArray => [...oldArray, videoId]);
        setMarkWatchVideoId(videoId);
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            await axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "add" }, headers)

            const parentVideoId = props.videoId;
            await axios
                .get(`${BasePath}/videos/${parentVideoId}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setRelatedVideos(response.data.data.relatedVideos)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })
        }
    }

    const handleUnMarkVideo = async (e, videoId) => {
        await validateToken();
        // setListOfUnMarkVideos(oldArray => [...oldArray, videoId]);
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "remove" }, headers)
            const parentVideoId = props.videoId;
            await axios
                .get(`${BasePath}/videos/${parentVideoId}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setRelatedVideos(response.data.data.relatedVideos)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, sort, orderBy) => {
        e.preventDefault();
        setAnchorEl(null);
        if (orderBy === "ASC" || orderBy === "DESC") {
            setRelatedVideos([]);
            handleSortRelatedVideos(sort, orderBy);
        }
    };

    const handleSortRelatedVideos = (sort, orderBy) => {
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const headers = {
                headers: {
                    'x-client-id': pinnacleStreamingV2ClientId,
                    'x-client-secret': pinnacleStreamingV2ClientSecret,
                    'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
                }
            }
            axios
                .get(`${BasePath}/videos/${props.videoId}?sortBy=${sort}&orderBy=${orderBy}`, headers)
                .then(response => {
                    if (response.status === 200) {
                        setRelatedVideos(response.data.data.relatedVideos)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            if (error.response.data.message === "Token Expired") {
                                setModalOpen(true);
                            } else {
                                setModalOpenDeactivate(true);
                            }
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                        if (error.response.status === 500) {
                            setModalOpen(true);
                            setTimeout(() => {
                                history.push("/login");
                            }, 3000);
                        }
                    } else if (error.request) {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    } else {
                        noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
                    }
                })

        }
    }

    const handleWatchVideo = async (e, videoInfo) => {
        await validateToken();
        const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
        if (userDataStorage === null) {
            history.push("/login");
        } else {
            const parseUserDataStorage = JSON.parse(userDataStorage)
            const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
            if (recentWatched === null) {
                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify(videoInfo)
                );
            } else {
                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify({ "status": "none" })
                );

                localStorage.setItem(
                    `pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
                    JSON.stringify(videoInfo)
                );
            }
            history.push(`/watch/${videoInfo._id}`)
        }
    }

    return (
        <div>
            {Object.keys(infosss).length === 0 && infosss.constructor === Object ? "" :
                (
                    <div>
                        <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
                        <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

                        {infosss && (infosss.data.video.videoUrl === "") ?
                            <div class="cover-photo container1">
                                <img src={infosss ? infosss.data.video.thumbnail : ''} className="topic-low-opacity" />
                                {/* <FontAwesomeIcon icon={faLock} class="cover-video-play-button icon-tag-cover" /> */}
                                <img src={assetsUrl.lockVideo} class="cover-video-play-button icon-tag-cover" />
                            </div> :
                            <div class="">
                                {/* <img src={infosss ? infosss.data.video.thumbnail : ''} className="" />
                                <img
                                    src={window.location.origin + '/images/icons/video-play.png'}
                                    className="cursor-pointer cover-video-play-button icon-tag-cover"
                                    onClick={(e) => handleWatchVideo(e, infosss.data.video)}
                                /> */}
                                <div className="img-thumb-overlay-v2">
                                    <img
                                        src={infosss ? infosss.data.video.thumbnail : ''}
                                        alt={infosss ? infosss.data.video.thumbnail : ''}
                                        className="video-thumb-landscape-v2"
                                    />

                                    <div class="img-icon-overlay-v2">
                                        <img src={window.location.origin + '/images/icons/video-play.png'} className="cursor-pointer cover-video-play-button img-icon-v2" onClick={(e) => handleWatchVideo(e, infosss.data.video)} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div class="pl-10 pb-10">
                            <p class="modal-subject-name">
                                {infosss ? infosss.data.video.title : ''}
                            </p>
                            <p class="modal-video-duration pr-10">{infosss ? infosss.data.video.duration : ''}
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="modal-video-sort-icon"
                                >
                                    <img src={anchorEl === null ? assetsUrl.sort : assetsUrl.sortActive} className="modal-video-sort-icon" />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={(e) => handleClose(e, 'createdAt', 'DESC')} className="modal-video-sort-font">By date</MenuItem>
                                    <MenuItem onClick={(e) => handleClose(e, 'title', 'ASC')} className="modal-video-sort-font">By name</MenuItem>
                                </Menu>
                            </p>
                        </div>

                        <div class="subject-videos subject-videos-list" style={{ display: 'block' }}>
                            {relatedVideos.length > 0 ?
                                relatedVideos.map((video) => {
                                    return (

                                        video.videoUrl === "" ?
                                            <div>
                                                {/* DISPLAY LOCK IF VIDEO IS NOT ACCESSIBLE */}
                                                <hr className='modal-video-hr' />
                                                <div class="pl-10 pr-10 topic mb-10 mouse-invalid">
                                                    <div class="thumbnail">
                                                        {/* <FontAwesomeIcon icon={faLock} class="topic-lock-icon text-white topic-play" /> */}
                                                        <img src={assetsUrl.lockVideo} class="topic-lock-icon text-white topic-play" />
                                                        <img src={video.thumbnail} className="topic-low-opacity" />
                                                    </div>
                                                    <div class="topic-details pt-15 pb-15 pr-15 pl-15 topic-low-opacity">
                                                        <div class="topic-1st-details watch-video">
                                                            {
                                                                video.isWatched === true ?
                                                                    <span class="watch-disabled"><img src={assetsUrl.watchActive} /></span>
                                                                    :
                                                                    <span class="watch-disabled"><img src={assetsUrl.watch} /></span>
                                                            }
                                                        </div>
                                                        <p class="topic-description" title={video.title}>{video.title.length > 42 ? video.title.substring(0, 42) + '...' : video.title} </p>
                                                        <div class="topic-1st-details">
                                                            <p class="topic-duration mb-15">{video.duration}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                {/* ENABLED VIDEOS */}
                                                <hr className='modal-video-hr' />
                                                <div class="pl-10 pr-10 topic topic-hover">
                                                    <div class="thumbnail ml-5 mr-5 mt-5 mb-5 cursor-pointer" onClick={(e) => handleWatchVideo(e, video)}>
                                                        {/* <span class="topic-play"><img src={assetsUrl.videoPlay} className="related-videos-play-icon" /></span>
                                                        <img src={video.thumbnail} /> */}
                                                        <div className="img-thumb-overlay-v2">
                                                            <img
                                                                src={video.thumbnail}
                                                                alt={video.thumbnail}
                                                                className="video-thumb-landscape-v2"
                                                            />

                                                            <div class="img-icon-overlay-v2">
                                                                <img src={assetsUrl.videoPlay} className="topic-play-v2 img-icon-v2" onClick={(e) => handleWatchVideo(e, infosss.data.video)} />
                                                            </div>
                                                        </div>

                                                    </div>



                                                    <div class="topic-details pt-15 pb-15 pr-15 pl-15">
                                                        <div class="topic-1st-details watch-video">
                                                            {
                                                                video.isWatched === true ?
                                                                    <span class="watch 1111" onClick={(e) => handleUnMarkVideo(e, video._id)}><img src={assetsUrl.watchActive} /></span>
                                                                    :
                                                                    <span class="watch 2222" onClick={(e) => handleMarkVideo(e, video._id)} title=""><img src={assetsUrl.watch} /></span>
                                                            }
                                                        </div>
                                                        <p class="topic-description" title={video.title}>{video.title.length > 42 ? video.title.substring(0, 42) + '...' : video.title} </p>
                                                        <div class="topic-1st-details">
                                                            <p class="topic-duration mb-15">{video.duration}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })
                                : ''
                            }
                        </div>

                    </div>
                )
            }

        </div>
    )
}

export default VideosPerSubjectComponent;