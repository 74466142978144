const initialState = {
    placeholder: 'block',
    spinner: 'none',
    searchStatus: 'pending'
}
export const searchReducer = (state = initialState, action) => {
    switch(action.type) {
        case "VALIDATE_USER":
            return {
                // ...state,
                initialState,
                searchResults: {},
                validateResult: action.payload
            }
        case "SEARCH_RESULTS":
            return {
                initialState: action.initialState,
                searchResults: action.payload,
            }
        default:
            return initialState
    }
}