import NavbarContextProvider from './navbarContext';
import NavbarContent from './navbarContent';

const Navbar = () => {
  return (
    <div>
    	<NavbarContextProvider>
			<NavbarContent />
		</NavbarContextProvider>
    </div>
  );
}

export default Navbar;
