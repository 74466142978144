const currentEnv = 'bucket';

export const assetsUrl = {
    logo: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/logo1.png" : window.location.origin + '/images/logo1.png',
    iconClose: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/icon-close.svg" : window.location.origin + '/images/icon-close.svg',
    iconCloseWhite: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/icon-close-white.svg" : window.location.origin + '/images/icon-close-white.svg',
    search: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/search.png" : window.location.origin + '/images/icons/search.png',
    searchActive: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/search-click.png" : window.location.origin + '/images/icons/search-click.png',
    notification: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/notification-bell.png" : window.location.origin + '/images/icons/notification-bell.png',
    notificationActive: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/notification-bell-click.png" : window.location.origin + '/images/icons/notification-bell-click.png',
    profile: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/profile.png" : window.location.origin + '/images/icons/profile.png',
    profileActive: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/profile-click.png" : window.location.origin + '/images/icons/profile-click.png',
    modalClose: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/modal-close.png" : window.location.origin + '/images/icon-close-white.svg',
    modalCloseBlack: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/modal-close-black.png" : window.location.origin + '/images/icons/modal-close-black.png',
    watch: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/icon-watched.svg" : window.location.origin + '/images/icon-watched.svg',
    watchActive: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/icon-watched-green.svg" : window.location.origin + '/images/icon-watched-green.svg',
    videoPlay: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/video-play.png" : window.location.origin + '/images/icons/video-play.png',
    sort: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/sort-white.png" : window.location.origin + '/images/icons/sort-white.png',
    sortActive: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/sort-click.png" : window.location.origin + '/images/icons/sort-click.png',
    lockVideo: currentEnv === "bucket" ? "https://pinnacleassets.sgp1.digitaloceanspaces.com/assets/streaming-v2-icons/app/lock-v2.png" : window.location.origin + '/images/icons/lock-v2.png'
};
