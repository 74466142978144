import ForgotPasswordContent from './forgotPasswordContent';

const ForgotPassword = () => {
  return (
    <div>
		<ForgotPasswordContent />
    </div>
  );
}

export default ForgotPassword;