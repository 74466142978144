import React from 'react';
import HomeComponent from './components/HomeComponent';
import SearchComponent from './components/SearchComponent';
import LoginComponent from './components/LoginComponent';
import SignupComponent from './components/SignupComponent';
import FirstTimeLoginComponent from './components/FirstTimeLoginComponent';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';
import ResetPasswordComponent from './components/ResetPasswordComponent';
import SubjectVideoComponent from './components/SubjectViewVideosComponent';
import WatchVideoComponent from './components/WatchVideoComponent';
import { Route, HashRouter, BrowserRouter as Router } from 'react-router-dom';
import ReferenceComponent from './components/ReferenceComponent/';

function MainApp() {
  return (
    <Router>
      <div className="App">
        <Route exact={true} path='/' component={HomeComponent} />
        <Route path='/references' component={ReferenceComponent} />
        <Route path='/search' component={SearchComponent} />
        <Route path='/login' component={LoginComponent} />
        <Route path='/create-account' component={SignupComponent} />
        <Route path='/account/setup' component={FirstTimeLoginComponent} />
        <Route path='/password/forgot' component={ForgotPasswordComponent} />
        <Route path='/password/reset' component={ResetPasswordComponent} />
        <Route path='/subject/:subjectId' component={SubjectVideoComponent} />
        <Route path='/watch/:videoId' component={WatchVideoComponent} />
      </div>
    </Router>
  );
}

export default MainApp;
