import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { SearchContext } from './searchContext';
import '../../assets/css/main-style.css';
import '../../assets/css/modal.css';
import '../../assets/css/pages.css';
import '../../assets/css/spinner.css';
import Navbar from '../NavbarComponent'
import SubjectLists from '../SubjectComponent'
import FullScreenLoader from '../../helpers/fullScreenLoader';
import isEmpty from '../../helpers/validations';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { searchReducer } from './searchReducer';
import { noty } from '../../helpers/noty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faSpinner, faLock } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import FadeIn from 'react-fade-in';
import './styles.css';
import { assetsUrl } from '../../helpers/assetsUrl';
import MultipleLoginComponent from '../SubjectComponent/sub-components/multipleLogin';
import DeactivatedUserComponent from '../SubjectComponent/sub-components/deactivatedUser';


const SearchComponent = (props) => {
	const context = useContext(SearchContext)
	const history = useHistory();
	const [searchModalDisplay, setSearchModalDisplay] = useState('block');
	const [showSeachBg, setShowSeachBg] = useState('block');
	const [showSeachSpin, setShowSeachSpin] = useState('none');
	const [searchInput, setSearchInput] = useState('');
	const [currentVideoId, setCurrentVideoId] = useState('');
	const [listOfMarkVideos, setListOfMarkVideos] = useState([]);
	const [listOfUnMarkVideos, setListOfUnMarkVideos] = useState([]);
	const [modalOpen, setModalOpen] = React.useState(false);
	// account deactivated
	const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);


	useEffect(() => {
		if (context.search.initialState) {
			if (context.search.initialState.searchStatus === 'done') {
				setListOfMarkVideos(context.search.initialState.watched);
			}
		}
	}, [])

	const handleWatchVideo = (e, video) => {
		e.preventDefault();
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			validateProfile(video);
		}
	}

	const validateProfile = (video) => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headerUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': parseUserDataStorage.data.token
				}
			}
			axios
				.get(`${BasePath}/users/profile/me`, headerUser)
				.then(response => {
					const info = response.data.message;
					if (info === true) { // if user trial is true and video trial is true
						if (video.isTrial === true) {
							// show video

							const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
							if (recentWatched === null) {
								localStorage.setItem(
									`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
									JSON.stringify(video)
								);
							} else {
								localStorage.setItem(
									`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
									JSON.stringify({ "status": "none" })
								);

								localStorage.setItem(
									`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
									JSON.stringify(video)
								);
							}

							history.push(`/watch/${video._id}`)
						} else {
							noty("error", `<i class = "fa fa-info-circle"></i> Unable to watch video`);
						}
					} else {
						// paid user must redirect to watch

						const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
						if (recentWatched === null) {
							localStorage.setItem(
								`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
								JSON.stringify(video)
							);
						} else {
							localStorage.setItem(
								`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
								JSON.stringify({ "status": "none" })
							);

							localStorage.setItem(
								`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
								JSON.stringify(video)
							);
						}
						history.push(`/watch/${video._id}`)
					}
				})
				.catch(error => {
					if (error.response) {
						if (error.response.status === 401) {
							if (error.response.data.message === "Token Expired") {
								setModalOpen(true);
							} else {
								setModalOpenDeactivate(true);
							}
							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
						if (error.response.status === 500) {
							setModalOpen(true);
							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
					} else if (error.request) {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		}
	}

	const handleMarkVideo = (e, videoId, i) => {
		// setListOfMarkVideos(oldArray => [...oldArray, videoId]);
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			// history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			setCurrentVideoId(videoId)
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "add" }, headers);
			context.handleSearch(e, searchInput)
		}
	}

	const handleUnMarkVideo = (e, videoId) => {
		// const watchedVideos = context.search.initialState.watched;

		// const index = watchedVideos.indexOf(videoId);
		// if (index > -1) { // only splice array when item is found
		// 	watchedVideos.splice(index, 1); // 2nd parameter means remove one item only
		// }
		// // setListOfUnMarkVideos(oldArray => [...oldArray, videoId]);

		setListOfMarkVideos(prev => prev.filter(fruit => fruit !== videoId))

		// setListOfMarkVideos(listOfMarkVideos.filter(item => item !== videoId));
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios.post(`${BasePath}/users/videos/watched`, { videoId, action: "remove" }, headers)
		}
	}


	const handleCloseSearch = () => {
		// history.goBack();
		history.push("/");
	}

	const handleOverAllSearch = (e, keyword) => {
		setSearchInput(keyword);
		// context.handleSearch(e, searchInput)
	}

	return (
		<div class="page-bg">
			<MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
			<DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

			<Navbar path='search' />
			<SubjectLists />

			<div class="search-modal-bg" style={{ display: searchModalDisplay }}>
				<div class="search-close pt-25 pb-90 pr-25 pl-25">
					{/* <img src={window.location.origin + '/images/icon-close-white.svg'} onClick={handleCloseSearch} /> */}
				</div>

				<div class="search-container">
					<FadeIn visible={true}>
						<form onSubmit={(e) => context.handleSearch(e, searchInput)}>
							<div class="input-icons center">
								<i class="fa fa-search icon">
								</i>

								<input class="input-field custom-text-width"
									type="text"
									placeholder="Search"
									value={searchInput}
									onChange={(e) => handleOverAllSearch(e, e.target.value)}
									required
								/>
								<img src={assetsUrl.iconCloseWhite} onClick={handleCloseSearch} className="search-close-position" />
							</div>
						</form>

						<div class="search-results mt-95">
							<div class="spinner-container" style={{ display: context.search.initialState ? context.search.initialState.spinner : 'none' }}>
								<div class="loadingio-spinner-spinner-eyk1pv8nm5w spinner">
									<div class="ldio-ar72xirup2q">
										<div></div><div></div><div></div>
										<div></div><div></div><div></div>
										<div></div><div></div><div></div>
										<div></div><div></div><div></div>
									</div>
								</div>
								<p class="support-text mt-40 text-white">loading please wait...</p>
							</div>

							{context.search.initialState ?
								context.search.initialState.searchStatus === 'done' ?
									<div class="search-results-per-row">
										<div class="search-results-item">
											<div class="search-keywords mb-20 pl-15 pr-15">
												<p class="search-for f-semibold">You have searched for: <span class="keywords text-white f-bold">{searchInput === '' ? context.searchParam : searchInput}</span></p>
												<p class="search-count f-semibold">results: <span class="results-count text-white f-bold">{context.search.searchResults.data.length} videos</span></p>
											</div>

											{context.search.searchResults.data.map((video, index) => {
												return (
													video.videoUrl === "" ?
														<div class="topic mb-10 mouse-invalid">
															<div class="thumbnail">
																{/* <FontAwesomeIcon icon={faLock} class="topic-lock-icon text-white topic-play" /> */}
																<img src={assetsUrl.lockVideo} class="topic-lock-icon text-white topic-play" />
																<img src={video.thumbnail} className="topic-low-opacity" />
															</div>
															<div class="topic-details pr-15 pl-15">
																<p class="topic-description topic-low-opacity">{video.title.length > 51 ? video.title.substring(0, 51) + '...' : video.title}</p>
																<div class="topic-1st-details">
																	<p class="topic-duration f-bold mb-15">{video.duration}</p>
																	{
																		video.isWatched === true || listOfMarkVideos.includes(video._id) ?
																			<span class="watch float-right search-watch-position-enabled"><img src={assetsUrl.watchActive} /></span>
																			:
																			<span class="watch float-right search-watch-position-enabled"><img src={assetsUrl.watch} /></span>
																	}
																</div>
															</div>
														</div>
														:

														<div class="topic topic-hover mb-10">
															<div class="thumbnail" onClick={(e) => handleWatchVideo(e, video)}>
																<span class="topic-play"><img src={assetsUrl.videoPlay} className="video-play-button-search" /></span>
																<img src={video.thumbnail} />
															</div>
															<div class="topic-details pr-15 pl-15">
																<p class="topic-description">{video.title.length > 51 ? video.title.substring(0, 51) + '...' : video.title}
																	{/* {
																		video.isWatched === true || listOfMarkVideos.includes(video._id) ?
																			<span class="watch float-right search-watch-position"><img src={assetsUrl.watchActive} /></span>
																			:
																			<span class="watch float-right search-watch-position" onClick={(e) => handleMarkVideo(e, video._id, index, searchInput)} style={{ display: video._id === currentVideoId ? 'none' : 'block' }}><img src={assetsUrl.watch} /></span>
																	} */}


																	{context.search.initialState ?
																		context.search.initialState.searchStatus === 'done' ? (
																			video.isWatched === true || listOfMarkVideos.includes(video._id) ?
																				<span class="watch float-right search-watch-position 222" onClick={(e) => handleUnMarkVideo(e, video._id)}><img src={assetsUrl.watchActive} /></span>
																				:
																				<span class="watch float-right search-watch-position 111111" onClick={(e) => handleMarkVideo(e, video._id)} title=""><img src={assetsUrl.watch} /></span>
																		) : ""
																		: ""
																	}
																</p>
																<div class="topic-1st-details">
																	<p class="topic-duration f-bold mb-15">{video.duration}</p>
																</div>
															</div>
														</div>
												)
											})}

										</div>
									</div>
									: null
								: null}
						</div>
					</FadeIn>
				</div>


			</div>

		</div>
	);
}

export default SearchComponent;