import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/mint.css";
import "../../node_modules/noty/lib/themes/sunset.css";
import "../../node_modules/noty/lib/themes/metroui.css";
import "../../node_modules/noty/lib/themes/light.css";
import "../../node_modules/noty/lib/themes/nest.css";
import "../../node_modules/noty/lib/themes/relax.css";
export function noty(type, message){
    new Noty({
        text: message,
        theme: 'metroui',
        type: type,
        timeout: 4000,
        layout: 'topRight'
    }).show();
}
