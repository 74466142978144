const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
const parseUserDataStorage = JSON.parse(userDataStorage)
const initialState = {
    profile: {
        firstName: 'parseUserDataStorage.data.user.firstName'
    }
}
export const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "USER_PROFILE":
            // const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
            // const parseUserDataStorage = JSON.parse(userDataStorage)
            // if (userDataStorage !== null) { 
            //  const userProfile = {
            //      firstName: parseUserDataStorage.data.user.firstName,
            //      lastName: parseUserDataStorage.data.user.lastName,
            //      birthDate: parseUserDataStorage.data.user.birthDate,
            //      email: parseUserDataStorage.data.user.email,
            //      gender: parseUserDataStorage.data.user.gender,
            //      school: parseUserDataStorage.data.user.school
            //    }
            //  return userProfile
            // }
            return {
                ...initialState
            }
        case "HOME_PATH":
            return {
                ...state,
                path: action.payload
            }
        case "SCHOOL_LISTS":
            return {
                schools: action.payload
            }
        default:
            return state
    }
}