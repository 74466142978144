import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import FadeIn from 'react-fade-in';
import FullScreenLoader from '../../helpers/fullScreenLoader';
import { BasePath, headerNonUser, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { FirstTimeLoginContext } from './firstTImeLoginContext';
import { noty } from '../../helpers/noty';
import '../../assets/css/form.css';
import '../../assets/css/main-style.css';
import '../../assets/css/modal.css';
import '../../assets/css/pages.css';
import '../../assets/css/spinner.css';
import '../../assets/css/step-by-step.css';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import './styles.css';

// import { faEye, faEdit, faTrash, faTrashAlt, faPlus, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import { Box, TextField, Grid, ThemeProvider, CssBaseline } from '@mui/material';
import { darkTheme } from '../../helpers/darkTheme';

const inputStyle = {
	borderRadius: "10px",
	fontSize: "13px",
	WebkitBoxShadow: "0 0 0 1000px #454545 inset",
	disableUnderline: true
};


const FirstTimeLoginComponent = (props) => {
	const context = useContext(FirstTimeLoginContext)
	const history = useHistory();

	const [checkBoxChecked, setCheckBoxChecked] = useState(false);
	const [step1Status, setStep1Status] = useState('active li-terms');
	const [step1Visibility, setStep1Visibility] = useState('block');
	const [step2Status, setStep2Status] = useState('li-pw');
	const [step2Visibility, setStep2Visibility] = useState('none');
	const [step3Status, setStep3Status] = useState('li-profile');
	const [step3Visibility, setStep3Visibility] = useState('none');

	const [fullScreenLoader, setFullScreenLoader] = useState(false);
	const [maskPasswordStat, setMaskPasswordStat] = useState(false);
	const [verifyMaskPasswordStat, setVerifyMaskPasswordStat] = useState(false);
	const [maskPassword, setMaskPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [maskVerifyPassword, setVerifyMaskPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [password, setPassword] = useState('');
	const [verifyPassword, setVerifyPassword] = useState('');

	const [profileStep3Disabled, setProfileStep3Disabled] = useState(false);
	const [profileGender, setProfileGender] = useState('');
	const [profileBirthDate, setProfileBirthDate] = useState('');
	const [profileContact, setProfileContact] = useState('');
	const [profileSchool, setProfileSchool] = useState('');

	const [userProgram, setUserProgram] = useState('');


	const [newSchoolOption, setNewSchoolOption] = useState(false);


	const [invalidTextBox, setInvalidTextBox] = useState({
		firstName: false,
		lastName: false,
		email: false,
		program: false
	});

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [school, setSchool] = useState('');
	const [yearLevel, setYearLevel] = useState('');
	const [formDisabled, setFormDisabled] = useState(false);
	const [programs, setPrograms] = useState([]);


	useEffect(() => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
		const userInfo = parseUserDataStorage.data.user;
		setFirstName(userInfo.firstName);
		setLastName(userInfo.lastName);
		setEmail(userInfo.email);
		handleFetchProgram();
	}, [])

	const handleFetchProgram = () => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
		const headers = {
			headers: {
				'x-client-id': pinnacleStreamingV2ClientId,
				'x-client-secret': pinnacleStreamingV2ClientSecret,
				'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
			}
		}
		axios
			.get(`${BasePath}/programs`, headers)
			.then(response => {
				const lists = response.data.data;
				setPrograms(lists);
			})
	}

	const handleStep1 = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		if (checkBoxChecked === true) {
			setStep1Status('li-terms success')
			setStep1Visibility('none')
			setStep2Status('li-pw active')
			setStep2Visibility('block')
			setFullScreenLoader(false);
			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headerUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': parseUserDataStorage.data.token
				}
			}
			axios
				.get(`${BasePath}/v1/users/eula/confirmation`, headerUser)
				.then(response => {
					setStep1Status('li-terms success')
					setStep1Visibility('none')
					setStep2Status('li-pw active')
					setStep2Visibility('block')
					setFullScreenLoader(false);
				})
				.catch(error => {
					setFullScreenLoader(false);
					if (error.response) {
						if (error.response.status === 400) {
							noty("error", `<i class = "fa fa-info-circle"></i> ${error.response.data.error}`);
						}
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		} else {
			noty("error", `<i class = "fa fa-info-circle"></i> Please read the terms and condition to proceed`);
		}
	}

	const handleStep2 = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		if (password === verifyPassword) {
			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headerUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': parseUserDataStorage.data.token
				}
			}
			axios
				.put(`${BasePath}/v1/users/profile/update`, { password: password }, headerUser)
				.then(response => {
					setFullScreenLoader(false);
					setVerifyPassword('')
					noty("success", `<i class = "fa fa-info-circle"></i> Successfully changed password`);
					setTimeout(() => {
						setStep2Status('li-pw success')
						setStep2Visibility('none')
						setStep3Status('li-profile active')
						setStep3Visibility('block')
					}, 300);
				})
				.catch(error => {
					setFullScreenLoader(false);
					if (error.response) {
						if (error.response.status === 400) {
							noty("error", `<i class = "fa fa-info-circle"></i> Invalid request. Please make sure you enter valid password`);
						}
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		} else {
			setFullScreenLoader(false);
			noty("error", `<i class = "fa fa-info-circle"></i> New password and verify new password not match`);
		}

	}

	const handleMaskPassword = (status) => {
		if (status === true) {
			setMaskPasswordStat(false)
			setMaskPassword(`${window.location.origin}/images/icon-showpass.svg`)
		} else {
			setMaskPasswordStat(true)
			setMaskPassword(`${window.location.origin}/images/icon-hidepass.svg`)
		}
	}

	const handleMaskVerifyPassword = (status) => {
		if (status === true) {
			setVerifyMaskPasswordStat(false)
			setVerifyMaskPassword(`${window.location.origin}/images/icon-showpass.svg`)
		} else {
			setVerifyMaskPasswordStat(true)
			setVerifyMaskPassword(`${window.location.origin}/images/icon-hidepass.svg`)
		}
	}

	const handleStep3Update = (e) => {
		e.preventDefault();
		setProfileStep3Disabled(true)
		setFullScreenLoader(true);
		if (profileGender === '' || profileSchool === '') {
			noty("error", `<i class = "fa fa-info-circle"></i> Please fill up all required fields`);
		} else {
			const formData = {
				birthDate: profileBirthDate,
				gender: profileGender,
				contactNumber: profileContact,
				school: profileSchool
			}

			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headerUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': parseUserDataStorage.data.token
				}
			}
			axios
				.put(`${BasePath}/v1/users/profile/update`, formData, headerUser)
				.then(response => {
					setFullScreenLoader(false);
					setProfileStep3Disabled(false)
					setPassword('')
					setVerifyPassword('')
					doReLogin(parseUserDataStorage.data.user.email, password)
				})
				.catch(error => {
					setFullScreenLoader(false);
					setProfileStep3Disabled(false)
					if (error.response) {
						if (error.response.status === 400) {
							noty("error", `<i class = "fa fa-info-circle"></i> Invalid request. Please make sure you enter valid password`);
						}
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})

		}
	}

	const doReLogin = (email, password) => {
		axios
			.post(`${BasePath}/auth/signin`, { email, password }, headerNonUser)
			.then(response => {
				localStorage.setItem(
					'pinnacleStreamingV2WebApp',
					JSON.stringify(response.data)
				);
				noty("success", `<i class = "fa fa-info-circle"></i> Successfully updated profile.`);
				setTimeout(() => {
					history.push('/')
				}, 1500);
			})
	}

	const doUpdatePassword = (newPassword) => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		const parseUserDataStorage = JSON.parse(userDataStorage)
		const headerUser = {
			headers: {
				'x-client-id': pinnacleStreamingV2ClientId,
				'x-client-secret': pinnacleStreamingV2ClientSecret,
				'token': parseUserDataStorage.data.token
			}
		}
		axios
			.post(`${BasePath}/auth/password/update`, { newPassword }, headerUser)
			.then(response => {
				localStorage.setItem(
					'pinnacleStreamingV2WebApp',
					JSON.stringify(response.data)
				);
				noty("success", `<i class = "fa fa-info-circle"></i> Successfully updated profile.`);
				setTimeout(() => {
					history.push('/')
				}, 1500);
			})
	}

	const handleAddSchoolOption = () => {
		setNewSchoolOption(true)
	}

	const handleOnSearch = (string, results) => {
		setProfileSchool(string)
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
	}

	const handleOnHover = (result) => {
		setProfileSchool(result.name)
	}
	const handleOnSelect = (item) => {
		setProfileSchool(item.name)
	}

	const handleOnFocus = () => {
	}

	const handleChange = (text, value) => {
		if (text === "firstName") {
			setFirstName(value);
			value === "" ? setInvalidTextBox({ ...invalidTextBox, firstName: true }) : setInvalidTextBox({ ...invalidTextBox, firstName: false })
		}
		// else if (text === "lastName") {
		// 	setLastName(value);
		// 	value === "" ? setInvalidTextBox({ ...invalidTextBox, lastName: true }) : setInvalidTextBox({ ...invalidTextBox, lastName: false })
		// } else if (text === "program") {
		// 	setProgram(value);
		// 	value === "" ? setInvalidTextBox({ ...invalidTextBox, program: true }) : setInvalidTextBox({ ...invalidTextBox, program: false })
		// }
	};

	const handleSubmitProfile = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		setFormDisabled(true);
		if (userProgram === "") {
			noty("error", `<i class = "fa fa-info-circle"></i> Please select program first.`);
			setFullScreenLoader(false);
			setFormDisabled(false);
		} else if (password === verifyPassword) {
			const formData = {
				firstName,
				lastName,
				school,
				isEula: true,
				birthDate: profileBirthDate,
				password,
				programId: userProgram
			}

			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headerUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': parseUserDataStorage.data.token
				}
			}
			axios
				.put(`${BasePath}/users`, formData, headerUser)
				.then(response => {
					setFullScreenLoader(false);
					setFormDisabled(false);
					doReLogin(parseUserDataStorage.data.user.email, password)
				})
				.catch(error => {
					setFullScreenLoader(false);
					setFormDisabled(false);
					if (error.response) {
						if (error.response.status === 400) {
							noty("error", `<i class = "fa fa-info-circle"></i> Invalid request. Please make sure you enter valid password`);
						}
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		} else {
			setFullScreenLoader(false);
			noty("error", `<i class = "fa fa-info-circle"></i> New password and verify new password not match`);
		}
	}

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<body className="form-step-by-step">

				<div class="">
					<FullScreenLoader loading={fullScreenLoader} />
					<div class="about-modal mt-75 about-modal-custom-size block-center pt-25 pl-25 pr-25" style={{ display: step1Visibility }}>
						<h4 class="main-header mb-25 center text-white">Complete Profile</h4>
						<form onSubmit={(e) => handleSubmitProfile(e)} autoComplete="off">
							<Box sx={{ width: '100%' }}>
								<Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 6, md: 6 }}>
									<Grid item xs={12} md={6}>
										<label>First Name</label>
										<div class="form-group-w-label">
											<input
												type="text"
												class="input-form mb-20 mt-5"
												placeholder="First Name"
												disabled={formDisabled}
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<label>Last Name</label>
										<div class="form-group-w-label">
											<input
												type="text"
												class="input-form mb-20 mt-5"
												placeholder="Last Name"
												disabled={formDisabled}
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<label>Birthdate</label>
										<div class="form-group">
											<input
												type="date"
												class="input-form mb-20 mt-5"
												placeholder="Birthdate"
												disabled={formDisabled}
												value={profileBirthDate}
												onChange={(e) => setProfileBirthDate(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<label>Email</label>
										<div class="form-group">
											<input
												type="email"
												class="input-form mb-20 mt-5"
												placeholder="Email"
												disabled={true}
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<label>School</label>
										<div class="form-group">
											<input
												type="text"
												class="input-form mb-20 mt-5"
												placeholder="School"
												disabled={formDisabled}
												value={school}
												onChange={(e) => setSchool(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<div class="form-group-w-label">
											<label for="firstname" class="text-white">Program</label>
											<div class="dropdown mb-20 mt-10">
												<select name="Gender" autocomplete="off" onChange={(e) => setUserProgram(e.target.value)}>
													<option value=""></option>
													{programs.length > 0 ?
														programs.map(prog => (<option key={prog._id} value={prog._id}>{prog.name}</option>)) : ""}
												</select>
											</div>
										</div>

										{/* <div class="form-group"> */}
										{/* <input
												type="number"
												class="input-form mb-20 mt-5"
												placeholder="Year Level"
												disabled={formDisabled}
												name="yearLevel"
												value={yearLevel}
												onChange={(e) => setYearLevel(e.target.value)}
												required
												min="1960"
											/> */}
										{/* </div> */}
									</Grid>
									<Grid item xs={12} md={6}>
										<label>New password</label>
										<div className="mt-5"></div>
										<div className="form-group">
											<span className="show-pw pw" alt="pw" onClick={() => handleMaskPassword(maskPasswordStat)} ><img src={maskPassword} /></span>
											<input
												type={maskPasswordStat === true ? 'text' : 'password'}
												class="input-form mb-20 input-pw"
												placeholder=""
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={6}>
										<label>Confirm new password</label>
										<div className="mt-5"></div>
										<div className="form-group">
											<span className="show-pw pw" alt="pw" onClick={() => handleMaskVerifyPassword(verifyMaskPasswordStat)} ><img src={maskVerifyPassword} /></span>
											<input
												type={verifyMaskPasswordStat === true ? 'text' : 'password'}
												className="input-form mb-20 input-pw"
												placeholder=""
												value={verifyPassword}
												onChange={(e) => setVerifyPassword(e.target.value)}
												required
											/>
										</div>
									</Grid>
								</Grid>
								<div className="center">
									<button type="submit" class="btn btn-submit-signup mb-20 mt-20">Done</button>
								</div>
							</Box>
						</form>
					</div>
				</div>
			</body>
		</ThemeProvider>
	);
}

export default FirstTimeLoginComponent;