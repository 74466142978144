import React, { useState, createContext, useReducer, useEffect, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import FadeIn from 'react-fade-in';
import {
	ThemeProvider,
	InputLabel,
	CssBaseline,
	FormControl,
	FormHelperText,
	FilledInput,
	IconButton,
	InputAdornment,
	TextField,
	Box,
	Input,
	Autocomplete,
	Select,
	MenuItem
} from '@mui/material';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import FullScreenLoader from '../../helpers/fullScreenLoader';
import Notification from '../NotificationComponent';
import { BasePath, headerNonUser, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import '../../assets/css/main-style.css';
import '../../assets/css/form.css';
import './styles.css'
import axios from 'axios';
import { darkTheme } from '../../helpers/darkTheme';
import { SignupContext } from './signupContext';
import { noty } from '../../helpers/noty';
import { assetsUrl } from '../../helpers/assetsUrl';

const inputStyle = {
	borderRadius: "10px",
	fontSize: "13px",
	WebkitBoxShadow: "0 0 0 1000px #454545 inset",
	disableUnderline: true
};


const SigunpComponent = (props) => {
	const history = useHistory();
	const context = useContext(SignupContext);
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [program, setProgram] = useState('');
	const [fullScreenLoader, setFullScreenLoader] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationType, setNotificationType] = useState('');
	const [showNotif, setShowNotif] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [invalidTextBox, setInvalidTextBox] = useState({
		firstName: false,
		lastName: false,
		email: false,
		program: false
	});
	const [programs, setPrograms] = useState([]);

	useEffect(() => {
		// const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		// const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
		// const headers = {
		// 	headers: {
		// 		'x-client-id': pinnacleStreamingV2ClientId,
		// 		'x-client-secret': pinnacleStreamingV2ClientSecret,
		// 		'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
		// 	}
		// }
		// axios
		// 	.get(`${BasePath}/programs`, headers)
		// 	.then(response => {
		// 		const lists = response.data;
		// 		setPrograms(lists);
		// 	})
	}, [])

	const handleSignupSubmit = (e) => {
		e.preventDefault();
		const formData = {
			email,
			firstName,
			lastName
		};
		setFullScreenLoader(true);
		axios
			.post(`${BasePath}/auth/signup`, formData, headerNonUser)
			.then(response => {
				setFullScreenLoader(false);
				setEmail('');
				setFirstName('');
				setLastName('');
				setShowNotif(true)
				noty("success", 'Account successfully created. Kindly check your email for the password.')
				setNotificationType('notificationSuccess')
				setTimeout(() => {
					setShowNotif(false)
					history.push('/login')
				}, 1000);
			})
			.catch(error => {
				// setShowNotif(true)
				setFullScreenLoader(false);
				setNotificationMessage(error.response.data.message)
				// setNotificationType('notificationFailed')
				// setTimeout(() => {
				// 	setShowNotif(false)
				// }, 1000);
				noty("error", `${error.response.data.message}`)
			})
	}

	const handleEmail = (email) => {
		setEmail(email)
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			setInvalidEmail(false);
		} else {
			setInvalidEmail(true);
		}
	};

	const handleEmailOnClick = (email) => {
		handleEmail(email);
	}

	const handleChange = (text, value) => {
		if (text === "firstName") {
			setFirstName(value);
			value === "" ? setInvalidTextBox({ ...invalidTextBox, firstName: true }) : setInvalidTextBox({ ...invalidTextBox, firstName: false })
		} else if (text === "lastName") {
			setLastName(value);
			value === "" ? setInvalidTextBox({ ...invalidTextBox, lastName: true }) : setInvalidTextBox({ ...invalidTextBox, lastName: false })
		} else if (text === "program") {
			setProgram(value);
			value === "" ? setInvalidTextBox({ ...invalidTextBox, program: true }) : setInvalidTextBox({ ...invalidTextBox, program: false })
		}
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div className="wrapper form-bg">
				<FullScreenLoader loading={fullScreenLoader} />
				<div className="right-container">
					<form className="form-container pb-60 pl-35 pr-35 pt-40 mr-90 ftime-login-form" onSubmit={(e) => handleSignupSubmit(e)}>
						<FadeIn>
							<div className="float-right">
								<Link to="/login" class="btn-back"><img src={assetsUrl.iconClose} alt="arrow-back" /></Link>
							</div>
							<h2 className="header f-bold text-white">Create Account</h2>
							<div className="mb-30"></div>
							<div className="form-group textfield-border-radius inputRounded">
								<TextField
									type="text"
									label="First Name"
									variant="filled"
									fullWidth
									onChange={(e) => handleChange('firstName', e.target.value)}
									value={firstName}
									style={{ marginBottom: "15px" }}
									inputProps={{ style: inputStyle }}
									className="inputRounded"
									color={invalidTextBox['firstName'] ? 'error' : 'primary'}
									error={invalidTextBox['firstName'] ? true : false}
									helperText={invalidTextBox['firstName'] ? 'Incorrect entry' : ''}
									InputProps={{ disableUnderline: invalidTextBox['firstName'] ? false : true }}
								/>
							</div>

							<div className='mb-10'></div>

							<div className="form-group textfield-border-radius">
								<TextField
									type="text"
									label="Last Name"
									variant="filled"
									fullWidth
									onChange={(e) => handleChange('lastName', e.target.value)}
									value={lastName}
									inputProps={{ style: inputStyle }}
									className="inputRounded"
									color={invalidTextBox['lastName'] ? 'error' : 'primary'}
									error={invalidTextBox['lastName'] ? true : false}
									helperText={invalidTextBox['lastName'] ? 'Incorrect entry' : ''}
									InputProps={{ disableUnderline: invalidTextBox['lastName'] ? false : true }}
								/>
							</div>

							<div className='mb-10'></div>

							<div className="form-group textfield-border-radius">
								<TextField
									type="email"
									label="Email"
									variant="filled"
									fullWidth
									onChange={(e) => handleEmail(e.target.value)}
									onClick={(e) => handleEmailOnClick(e.target.value)}
									value={email}
									error={invalidEmail ? true : false}
									color={invalidEmail || email === '' ? 'error' : 'primary'}
									helperText={invalidEmail ? 'Please enter valid email' : ''}
									inputProps={{ style: inputStyle }}
									InputProps={{ disableUnderline: invalidEmail ? false : true }}
									className="inputRounded"
									rows="10"
									margin="normal"
									style={{ marginBottom: "15px" }}

								/>
							</div>

							<div className='mb-10'></div>

							{/* <div className="form-group autocomplete-border-radius select-program">
								<FormControl fullWidth variant="filled">
									<InputLabel id="demo-simple-select-label">Program</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Age"
										onChange={(e) => handleChange('program', e.target.value)}
										disableUnderline={true}
										value={program}
									>
										{context.signup.initialState && (context.signup.initialState.signupStatus === 'done') ?
											context.signup.results.data.length > 0 ? context.signup.results.data.map(program => (<MenuItem key={program._id} value={program._id}>{program.name}</MenuItem>)) : ''
											: ''}
									</Select>
								</FormControl>
							</div> */}
							<div className="mb-30"></div>
							<button type="submit" className="btn btn-submit button-primary mb-20">Create</button>
						</FadeIn>
						<div className="center bottom">
							<img src={assetsUrl.logo} className="login-logo" />
						</div>
					</form>
				</div>
				{/* <Notification visible={showNotif} message={notificationMessage} type={notificationType} /> */}
			</div>
		</ThemeProvider>
	);
}

export default SigunpComponent;