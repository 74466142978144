import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { navbarReducer } from './navbarReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import moment from 'moment';

export const NavbarContext = createContext();

const NavbarContextProvider = (props) => {
  const history = useHistory();
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
    gender: '',
    school: '',
    yearLevel: ''
  })
  const [changePassword, setChangePassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [navbar, dispatch] = useReducer(navbarReducer, {
    data: {},
    path: ''
  });


  const onChange = (e) => {
    setUserProfile({
      ...userProfile,
      [e.target.id]: e.target.value,
    });
  };

  const onChangeSchool = (school) => {
    setUserProfile({
      ...userProfile,
      school: school,
    });
  }

  const onChangePassword = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.id]: e.target.value,
    });
  };

  const clearChangePassForm = (e) => {
    setChangePassword({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    })
  }

  // useEffect(() => {
  //   const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")

  //   if (userDataStorage === null) {
  //     history.push("/login");
  //   } else {
  //     const parseUserDataStorage = JSON.parse(userDataStorage)
  //     const currentUser = {
  //       firstName: parseUserDataStorage.data.user.firstName,
  //       lastName: parseUserDataStorage.data.user.lastName,
  //       birthDate: moment(parseUserDataStorage.data.user.birthDate).format('YYYY-MM-DD'),
  //       email: parseUserDataStorage.data.user.email,
  //       gender: parseUserDataStorage.data.user.gender,
  //       school: parseUserDataStorage.data.user.school,
  //       expiresAt: parseUserDataStorage.data.user.expiresAt,
  //       isTrial: parseUserDataStorage.data.user.isTrial,
  //       yearLevel: parseUserDataStorage.data.user.yearLevel
  //     }
  //     setUserProfile(currentUser);
  //     console.log("PROFILE USE EFFECT==> " + JSON.stringify(currentUser))
  //     dispatch({
  //       type: 'USER_PROFILE',
  //       payload: currentUser
  //     })
  //   }
  // }, [])

  // useEffect(() => {
  //   const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
  //   if (userDataStorage === null) {
  //     history.push("/login");
  //   } else {
  //     const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
  //     const headers = {
  //       headers: {
  //         'x-client-id': pinnacleStreamingV2ClientId,
  //         'x-client-secret': pinnacleStreamingV2ClientSecret,
  //         'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
  //       }
  //     }
  //     axios
  //       .get(`${BasePath}/updates`, headers)
  //       .then(response => {
  //         let schoolLists = []
  //         response.data.data.forEach(school => {
  //           schoolLists.push(school.name)
  //         })
  //         dispatch({
  //           type: 'SCHOOL_LISTS',
  //           payload: response.data
  //         })
  //       })

  //   }
  // }, [])


  return (
    <NavbarContext.Provider value={{
      navbar,
      dispatch,
      userProfile,
      onChange,
      onChangeSchool
    }}>
      {props.children}
    </NavbarContext.Provider>
  )
}

export default NavbarContextProvider;
