import FirstTImeLoginProvider from './firstTImeLoginContext';
import FirstTImeLoginContent from './firstTImeLoginContent';

const FirstTImeLogin = () => {
  return (
  	<FirstTImeLoginProvider>
    	<FirstTImeLoginContent />
    </FirstTImeLoginProvider>
  );
}

export default FirstTImeLogin;