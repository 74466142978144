import { useContext } from 'react';
import { HomeContext } from './homeContext';
import '../../assets/css/main-style.css';
import '../../assets/css/modal.css';
import '../../assets/css/pages.css';
import '../../assets/css/spinner.css';
import Navbar from '../NavbarComponent'
import SubjectLists from '../SubjectComponent'
import FadeIn from 'react-fade-in';



const HomeComponent = (props) => {
	return (
		<div class="page-bg">
			<Navbar path='home' />
			<div>
				<SubjectLists />
			</div>
		</div>
	);
}

export default HomeComponent;