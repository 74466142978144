import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { searchReducer } from './searchReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import insertQuery from '../../helpers/url-query';
import { noty } from '../../helpers/noty';

export const SearchContext = createContext();


const SearchContextProvider = (props) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get('q');
  const [search, dispatch] = useReducer(searchReducer, {
    searchResults: {}
  });

  useEffect(() => {
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    if (userDataStorage === null) {
      // history.push("/login");
    } else {
      const parseUserDataStorage = JSON.parse(userDataStorage)
      const headers = {
        headers: {
          'x-client-id': pinnacleStreamingV2ClientId,
          'x-client-secret': pinnacleStreamingV2ClientSecret,
          'token': parseUserDataStorage.data.token
        }
      }
      // axios
      //   .get(`${BasePath}/v1/auth/validate`, headers)
      //   .then(response => {
      //     dispatch({
      //       type: 'VALIDATE_USER',
      //       payload: response.data
      //     })
      //   })
      //   .catch(error => {
      //     if (error.response) {
      //       if (error.response.status === 401) {
      //         noty("error", `<i class = "fa fa-info-circle"></i> ${error.response.data.error}`);
      //         // history.push("/login");
      //       }
      //       if (error.response.status === 500) {
      //         noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
      //         // history.push("/login");
      //       }
      //     } else if (error.request) {
      //       noty("error", `<i class = "fa fa-info-circle"></i> SOmething went wrong. Kindly refresh the page `);
      //     } else {
      //       noty("error", `<i class = "fa fa-info-circle"></i> SOmething went wrong. Kindly refresh the page `);
      //     }
      //   })
    }
  }, [])

  useEffect(() => {
    if (searchParam !== null) {
      const initialState = {
        placeholder: 'none',
        spinner: 'block',
        searchStatus: 'start',
        watched: [],
        notWatched: [],
      }
      dispatch({
        type: 'SEARCH_RESULTS',
        initialState
      })
      const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
      const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
      const headers = {
        headers: {
          'x-client-id': pinnacleStreamingV2ClientId,
          'x-client-secret': pinnacleStreamingV2ClientSecret,
          'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
        }
      }
      axios
        .get(`${BasePath}/search?keyword=${searchParam}&type=video`, headers)
        .then(response => {
          let watched = [];
          let notWatched = [];
          if (response.data.data.length > 0) {
            response.data.data.forEach(result => {
              if (result.isWatched) {
                watched.push(result._id)
              } else {
                notWatched.push(result._id)
              }
            })
          }
          dispatch({
            type: 'SEARCH_RESULTS',
            initialState: {
              placeholder: 'none',
              spinner: 'none',
              searchStatus: 'done',
              watched,
              notWatched,
            },
            payload: response.data
          })
        })
        .catch(error => {
          noty("error", `<i class = "fa fa-info-circle"></i> ${error.response.data.error}`);
        })
    }

  }, [])

  const handleSearch = (e, searchParam) => {
    e.preventDefault();
    history.push(`/search?q=${searchParam}`)
    const initialState = {
      placeholder: 'none',
      spinner: 'block',
      searchStatus: 'start',
      watched: [],
      notWatched: [],
    }
    dispatch({
      type: 'SEARCH_RESULTS',
      initialState
    })
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    const parseUserDataStorage = JSON.parse(userDataStorage)
    const headers = {
      headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret,
        'token': parseUserDataStorage.data.token
      }
    }
    axios
      .get(`${BasePath}/search?keyword=${searchParam}&type=video`, headers)
      .then(response => {
        let watched = [];
        let notWatched = [];
        if (response.data.data.length > 0) {
          response.data.data.forEach(result => {
            if (result.isWatched) {
              watched.push(result._id)
            } else {
              notWatched.push(result._id)
            }
          })
        }
        dispatch({
          type: 'SEARCH_RESULTS',
          initialState: {
            placeholder: 'none',
            spinner: 'none',
            searchStatus: 'done',
            watched,
            notWatched,
          },
          payload: response.data
        })
      })
  }


  return (
    <SearchContext.Provider
      value={{
        search,
        dispatch,
        handleSearch: handleSearch,
        searchParam
      }}>
      {props.children}
    </SearchContext.Provider>
  )
}

export default SearchContextProvider;
