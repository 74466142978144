import { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { ReferenceContext } from './referenceContext';
import '../../assets/css/main-style.css';
import '../../assets/css/modal.css';
import '../../assets/css/pages.css';
import '../../assets/css/spinner.css';
import FadeIn from 'react-fade-in';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import axios from 'axios';
import moment from 'moment';
import { noty } from '../../helpers/noty';
import './styles.css';
import FullScreenLoader from '../../helpers/fullScreenLoader';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useTimer } from 'react-timer-hook';
import {
	ThemeProvider,
	InputLabel,
	CssBaseline,
	FormControl,
	FormHelperText,
	FilledInput,
	IconButton,
	InputAdornment,
	TextField,
	Box,
	Input,
	Autocomplete,
	Select,
	MenuItem,
	AppBar,
	Toolbar,
	Typography,
	Badge,
	FormGroup,
	FormControlLabel,
	Menu,
	Container,
	Button,
	Tooltip,
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Divider
} from '@mui/material';
import {
	Search,
	SearchIcon,
	AccountCircle,
	MailIcon,
	NotificationsIcon,
	MoreIcon,
	SearchIconWrapper
} from '@mui/icons-material';
import AdbIcon from '@mui/icons-material/Adb';

import MenuIcon from '@mui/icons-material/Menu';
import { darkTheme } from '../../helpers/darkTheme';
import MultipleLoginComponent from '../SubjectComponent/sub-components/multipleLogin';
import { isMobile } from 'react-device-detect';
import { List } from 'react-content-loader';
import Navbar from '../NavbarComponent';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash.debounce';
import { assetsUrl } from '../../helpers/assetsUrl';
import DeactivatedUserComponent from '../SubjectComponent/sub-components/deactivatedUser';

const ReferenceComponent = (props) => {
	const context = useContext(ReferenceContext)
	const history = useHistory();

	// multiple login
	const [modalOpen, setModalOpen] = useState(false);


	// account deactivated
	const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);

	// notifications
	const [references, setReferences] = useState([]);
	const [program, setProgram] = useState({});
	const [userInfo, setUserInfo] = useState({});


	const [searchBox, setSearchBox] = useState('');

	const [modalReferenceDisplay, setModalReferenceDisplay] = useState('none');
	const [selectedReferenceId, setSelectedReferenceId] = useState({});

	useEffect(() => {
		getAllReferences();
	}, [])

	const getAllReferences = async () => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			await axios
				.get(`${BasePath}/references`, headers)
				.then(response => {
					getDashboard(headers);
					setReferences(response.data.data);
				})
				.catch(error => {
					if (error.response) {
						if (error.response.status === 401) {
							if (error.response.data.message === "Token Expired") {
								setModalOpen(true);
							} else {
								setModalOpenDeactivate(true);
							}
							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
						if (error.response.status === 500) {
							setModalOpen(true);
							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
					} else if (error.request) {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		}
	}

	const getDashboard = (headers) => {
		axios
			.get(`${BasePath}/dashboard`, headers)
			.then(response => {
				setUserInfo(response.data.data.user);
				setProgram(response.data.data.program);
			})
	}

	const handleSetSearchBox = (e, keyword) => {
		setSearchBox(keyword);
		handleSubjectSearch(e);
	}

	const changeHandler = event => {
		handleSubjectSearch(event);
	};

	const debouncedChangeHandler = useCallback(
		debounce(changeHandler, 300)
		, []);

	const handleSubjectSearch = (e) => {
		e.preventDefault();
		const keyword = e.target.value;
		setSearchBox(keyword);
		if (keyword === "") {
			getAllReferences();
		} else {
			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			if (userDataStorage === null) {
				history.push("/login");
			} else {
				const parseUserDataStorage = JSON.parse(userDataStorage)
				const headers = {
					headers: {
						'x-client-id': pinnacleStreamingV2ClientId,
						'x-client-secret': pinnacleStreamingV2ClientSecret,
						'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
					}
				}
				axios
					.get(`${BasePath}/search?keyword=${keyword}&type=references`, headers)
					.then(response => {
						if (response.status === 200) {
							setReferences(response.data.data)
						}
					})
					.catch(error => {
						if (error.response) {
							if (error.response.status === 401) {
								if (error.response.data.message === "Token Expired") {
									setModalOpen(true);
								} else {
									setModalOpenDeactivate(true);
								}
								setTimeout(() => {
									history.push("/login");
								}, 3000);
							}
							if (error.response.status === 500) {
								setModalOpen(true);
								setTimeout(() => {
									history.push("/login");
								}, 3000);
							}
						} else if (error.request) {
							noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
						} else {
							noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
						}
					})
			}
		}
	}

	const handleClearSearchBox = () => {
		setSearchBox('');
		getAllReferences();
	}

	const closeModal = (e, content) => {
		e.preventDefault();
		if (content === 'modalShowReference') {
			setModalReferenceDisplay('none');
			setSelectedReferenceId({});
		}
	}

	const handleShowReferenceInfo = (e, info) => {
		e.preventDefault();
		setModalReferenceDisplay('block');
		setSelectedReferenceId(info);
	}


	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<div class="page-bg reference-bg">
				<MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
				<DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

				<Navbar path='reference' />
				<div className="pl-20 pr-20 pt-100 pb-20">
					<div className='subject-header pb-10'>
						{/* {program.name} */}
						<div className='pb-20 float-right'>
							<div class="inner-addon left-addon modal-show-all-searchbox">
								<i class="fa fa-search custom-position"></i>
								<input type="text" onChange={debouncedChangeHandler} class="custom-searchbox custom-searchbox-color" />
								<span class="fa fa-times right-addon clear-searchbox custom-position" onClick={() => handleClearSearchBox()}></span>
							</div>
						</div>
					</div>
					<Grid sx={{ flexGrow: 1 }} container className="center">
						<Grid item xs={12}>
							<Grid container justifyContent="left" spacing={2} className="reference-parent">
								{references.length > 0 ?
									references.map((ref) => {
										return (
											ref.isVisible ?
												userInfo.isTrial
													?
													ref.isTrial ? <Grid key={ref._id} item className="custom-grid-size topic center" style={{ textAlign: 'center ' }}>
														<div className='container1 video-zoom ' onClick={(e) => handleShowReferenceInfo(e, ref)}>
															<img
																src={ref.thumbnail}
																srcSet={ref.thumbnail}
																alt={ref.thumbnail}
																className="reference-thumb-landscape video-thumb-size"
															/>

														</div>
														<Tooltip title={ref.title}>

															<label className="reference-name-label">{ref.title.length > 16 ? ref.title.substring(0, 15) + '...' : ref.title}</label>
														</Tooltip>
													</Grid> : <Grid key={ref._id} item className="custom-grid-size topic center" style={{ textAlign: 'center ' }}>
														<div className='container1 topic-low-opacity'>
															<img
																src={ref.thumbnail}
																srcSet={ref.thumbnail}
																alt={ref.thumbnail}
																className="reference-thumb-landscape video-thumb-size"
															/>
															<img src={assetsUrl.lockVideo} class="video-play-button icon-tag4" />
														</div>
														<Tooltip title={ref.title}>

															<label className="reference-name-label">{ref.title.length > 16 ? ref.title.substring(0, 15) + '...' : ref.title}</label>
														</Tooltip>
													</Grid>
													:
													<Grid key={ref._id} item className="custom-grid-size topic center" style={{ textAlign: 'center ' }}>
														<div className='container1 video-zoom ' onClick={(e) => handleShowReferenceInfo(e, ref)}>
															<img
																src={ref.thumbnail}
																srcSet={ref.thumbnail}
																alt={ref.thumbnail}
																className="reference-thumb-landscape video-thumb-size"
															/>

														</div>
														<Tooltip title={ref.title}>

															<label className="reference-name-label">{ref.title.length > 16 ? ref.title.substring(0, 15) + '...' : ref.title}</label>
														</Tooltip>
													</Grid>
												:
												''
										)
									})
									: ''
								}
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>

			{/* MODAL REFERENCE INFORMATION */}
			< div class="modal-show-all modal-bg" style={{ display: modalReferenceDisplay }}>
				<div class="subject-modal modal-container dark mt-75 block-center custom-color-dark">
					<a class="close" onClick={(e) => closeModal(e, 'modalShowReference')}><img className="modal-close reference-modal-close" src={assetsUrl.iconClose} /></a>
					<FadeIn visible={modalReferenceDisplay === 'block' ? true : false}>
						<div className="pl-20 pr-20 pb-20 pt-20 reference-bg-color">
							<h3 className="modal-show-all-subject-title-mobile text-black">{selectedReferenceId.title}</h3>
							{/* <p className="modal-reference-description">{selectedReferenceId.description}</p> */}
							<hr />
							<div className="modal-reference-content details-container-scrollable text-black unselectable" >
								<p className="unselectable" dangerouslySetInnerHTML={{ __html: selectedReferenceId.content }} />
							</div>
						</div>
					</FadeIn>
				</div>
			</div>
		</ThemeProvider>
	);
}

export default ReferenceComponent;