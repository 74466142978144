import axios from 'axios';
import React, { createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { homeReducer } from './homeReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { noty } from '../../helpers/noty';

export const HomeContext = createContext();

const HomeContextProvider = (props) => {
  const history = useHistory();
  const [home, dispatch] = useReducer(homeReducer, {
    data: {},
    path: ''
  });

  // useEffect(() => {
  // const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
  // if (userDataStorage === null) {
  //   // history.push("/login");
  // } else {
  //   const parseUserDataStorage = JSON.parse(userDataStorage)
  //   if (parseUserDataStorage.data.user.isEula === false) {
  //     // history.push('/account/setup')
  //   } else {

  //     const headers = {
  //       headers: {
  //         'x-client-id': pinnacleStreamingV2ClientId,
  //         'x-client-secret': pinnacleStreamingV2ClientSecret,
  //         'token': parseUserDataStorage.data.token
  //       }
  //     }
  //     axios
  //       .get(`${BasePath}/v1/auth/validate`, headers)
  //       .then(response => {
  //         dispatch({
  //           type: 'VALIDATE_USER',
  //           payload: response.data
  //         })
  //       })
  //       .catch(error => {
  //         if (error.response) {
  //           if (error.response.status === 401) {
  //             noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
  //             // history.push("/login");
  //           }
  //           if (error.response.status === 500) {
  //             noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
  //             // history.push("/login");
  //           }
  //         } else if (error.request) {
  //           noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
  //         } else {
  //           noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
  //         }
  //       })
  //   }
  // }
  // }, [])


  return (
    <HomeContext.Provider value={{ home, dispatch }}>
      {props.children}
    </HomeContext.Provider>
  )
}

export default HomeContextProvider;
