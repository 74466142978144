import SearchContextProvider from './searchContext';
import SearchContent from './searchContent';

const Search = () => {
  return (
    <div>
    	<SearchContextProvider>
			<SearchContent />
		</SearchContextProvider>
    </div>
  );
}

export default Search;
