import { useContext, useState, useMemo } from 'react';
import { Link, useHistory } from "react-router-dom";
import {
	ThemeProvider,
	createTheme,
	InputLabel,
	CssBaseline,
	FormControl,
	FormHelperText,
	OutlinedInput,
	IconButton,
	InputAdornment,
	FilledInput
} from '@mui/material';
import { RemoveRedEyeOutlined, Visibility, VisibilityOff, VisibilityOffOutlined } from '@mui/icons-material';
import FadeIn from 'react-fade-in';
import FullScreenLoader from '../../helpers/fullScreenLoader';
import { BasePath, headerNonUser, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import Notification from '../NotificationComponent';
import '../../assets/css/main-style.css';
import '../../assets/css/form.css';
import './styles.css';
import axios from 'axios';
import { noty } from '../../helpers/noty';
import { assetsUrl } from '../../helpers/assetsUrl';

const inputStyle = {
	borderRadius: "10px",
	fontSize: "13px",
	WebkitBoxShadow: "0 0 0 1000px #454545 inset"
};


const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const ResetPasswordComponent = (props) => {
	const history = useHistory();
	const [password, setPassword] = useState('');
	const [verifyPassword, setVerifyPassword] = useState('');
	const [fullScreenLoader, setFullScreenLoader] = useState(false);
	const [maskPasswordStat, setMaskPasswordStat] = useState(false);
	const [verifyMaskPasswordStat, setVerifyMaskPasswordStat] = useState(false);
	const [maskPassword, setMaskPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [maskVerifyPassword, setVerifyMaskPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationType, setNotificationType] = useState('');
	const [showNotif, setShowNotif] = useState(false);
	const [passwordInvalid, setPasswordInvalid] = useState(false);
	const [verifyPasswordInvalid, setVerifyPasswordInvalid] = useState(false);

	const handleResetSubmit = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		if (password === verifyPassword) {
			const urlParams = new URLSearchParams(window.location.search);
			const expireToken = urlParams.get('expire');
			const headerNonUser = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': expireToken
				}
			}
			axios
				.post(`${BasePath}/auth/password/update`, { newPassword: password }, headerNonUser)
				.then(response => {
					setFullScreenLoader(false);
					noty("success", `<i class = "fa fa-check"></i> ${response.data.message}`);
					setShowNotif(true)
					setNotificationMessage(response.data.message)
					setNotificationType('notificationSuccess')
					setTimeout(() => {
						setShowNotif(false)
						history.push('/login')
					}, 3000);
				})
				.catch(error => {
					setFullScreenLoader(false);
					setShowNotif(true)
					if (error.response) {
						if (error.response.status === 400) {
							noty("error", `<i class = "fa fa-times"></i> Invalid request. Please make sure you enter valid password`);
							setNotificationMessage("Invalid request. Please make sure you enter valid password")
							setNotificationType('notificationFailed')
							setTimeout(() => {
								setShowNotif(false)
							}, 3000);
						}
					} else {
						noty("error", `<i class = "fa fa-times"></i> Something went wrong. Please try again`);
						setNotificationMessage('Something went wrong. Please try again')
						setNotificationType('notificationFailed')
						setTimeout(() => {
							setShowNotif(false)
						}, 3000);
					}
				})
		} else {
			setFullScreenLoader(false);
			noty("error", `<i class = "fa fa-times"></i> New password and verify new password not match`);
			setShowNotif(true)
			setNotificationMessage("New password and verify new password not match")
			setNotificationType('notificationFailed')
			setTimeout(() => {
				setShowNotif(false)
			}, 2500);
		}

	}

	const handleMaskPassword = () => {
		setMaskPasswordStat(!maskPasswordStat)
	}

	const handleMaskVerifyPassword = (status) => {
		setVerifyMaskPasswordStat(!verifyMaskPasswordStat)
	}

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handlePassword = (password) => {
		setPassword(password);
		if (password === "") {
			setPasswordInvalid(true);
		}
	}

	const handleVerifyPassword = (verifyPassword) => {
		setVerifyPassword(verifyPassword);
		if (verifyPassword === "" || verifyPassword !== password) {
			setVerifyPasswordInvalid(true);
		} else {
			setVerifyPasswordInvalid(false);
		}
	}

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div className="wrapper form-bg">
				<FullScreenLoader loading={fullScreenLoader} />
				<div className="right-container">
					<form className="form-container pb-60 pl-35 pr-35 pt-40 mr-90 fpassword-form" onSubmit={handleResetSubmit}>
						<FadeIn>
							<div className="float-right">
								<Link to="/login" class="btn-back"><img src={assetsUrl.iconClose} alt="arrow-back" /></Link>
							</div>
							<h2 className="header f-bold text-white">Reset Password</h2>
							<p class="text-white mb-25 forgot-text">Enter your new password.</p>


							<div className="outlined-input-border-radius inputRounded">
								<FormControl variant="filled">
									<InputLabel htmlFor="filled-adornment-password">New</InputLabel>
									<FilledInput
										id="filled-adornment-password"
										type={maskPasswordStat ? 'text' : 'password'}
										value={password}
										inputProps={{ style: inputStyle }}
										onChange={(e) => handlePassword(e.target.value)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMaskPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{maskPasswordStat ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
												</IconButton>
											</InputAdornment>
										}
										error={passwordInvalid && password === '' ? true : false}
										color={passwordInvalid && password === '' ? 'error' : 'primary'}
										className="passwordRounded"
										style={{ border: "none" }}
										disableUnderline={passwordInvalid && password === "" ? false : true}
									/>
									<FormHelperText error>{passwordInvalid && password === "" ? "Incorrect entry" : ""}</FormHelperText>
								</FormControl>
							</div>
							<div className='mb-20'></div>

							<div className="outlined-input-border-radius inputRounded">
								<FormControl variant="filled">
									<InputLabel htmlFor="filled-adornment-password">Verify</InputLabel>
									<FilledInput
										id="filled-adornment-password"
										type={verifyMaskPasswordStat ? 'text' : 'password'}
										value={verifyPassword}
										inputProps={{ style: inputStyle }}
										onChange={(e) => handleVerifyPassword(e.target.value)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMaskVerifyPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{verifyMaskPasswordStat ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
												</IconButton>
											</InputAdornment>
										}
										error={verifyPasswordInvalid && verifyPassword === '' ? true : false}
										color={verifyPasswordInvalid && verifyPassword === '' ? 'error' : 'primary'}
										className="passwordRounded"
										style={{ border: "none" }}
										disableUnderline={verifyPasswordInvalid && verifyPassword === "" ? false : true}
									/>
									<FormHelperText error>{verifyPasswordInvalid ? "Incorrect entry" : ""}</FormHelperText>
								</FormControl>
							</div>

							<div className="outlined-input-border-radius inputRounded">
								{/* <FormControl variant="outlined">
									<InputLabel
										htmlFor='display-name'
										error={verifyPasswordInvalid && verifyPassword === "" ? true : false}
										color={verifyPasswordInvalid && verifyPassword !== password ? 'error' : 'primary'}
									>
										Verify
									</InputLabel>
									<FilledInput
										type={verifyMaskPasswordStat ? 'text' : 'password'}
										label='New'
										onChange={(e) => setVerifyPassword(e.target.value)}
										aria-describedby="base-name-helper-text"
										inputProps={{ style: inputStyle }}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMaskPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{maskPasswordStat ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
												</IconButton>
											</InputAdornment>
										}
										error={verifyPasswordInvalid && verifyPassword === '' ? true : false}
										color={verifyPasswordInvalid && verifyPassword === '' ? 'error' : 'primary'}
										className="passwordRounded"
										variant="outlined"
									/>
									<FormHelperText error>{verifyPasswordInvalid && verifyPassword !== password ? "Password does not match" : ""}</FormHelperText>
								</FormControl> */}



								{/* <FormControl variant="filled">
									<InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
									<FilledInput
										id="filled-adornment-password"
										type={maskPasswordStat ? 'text' : 'password'}
										value={password}
										inputProps={{ style: inputStyle }}
										onChange={(e) => setPassword(e.target.value)}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMaskPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{maskPasswordStat ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
												</IconButton>
											</InputAdornment>
										}
										error={password === '' ? true : false}
										color={password === '' ? 'error' : 'primary'}
										className="passwordRounded"
										style={{ border: "none" }}
									/>
									<FormHelperText error>{verifyPassword !== password ? "Password does not match" : ""}</FormHelperText>
								</FormControl> */}
							</div>
							<div className='mb-30'></div>

							<button type="submit" className="btn btn-submit button-primary mb-20">Submit</button>
						</FadeIn>
						<div className="center bottom">
							<img src={assetsUrl.logo} className="login-logo" />
						</div>
					</form>
				</div>
				{/* <Notification visible={showNotif} message={notificationMessage} type={notificationType} /> */}
			</div>
		</ThemeProvider>
	);
}

export default ResetPasswordComponent;