import SubjectVideoContextProvider from './subjectVideosContext';
import SubjectVideoContent from './subjectVideosContent';

const SubjectVideos = () => {
  return (
    <div>
    	<SubjectVideoContextProvider>
			<SubjectVideoContent />
		</SubjectVideoContextProvider>
    </div>
  );
}

export default SubjectVideos;
