import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { dashboardReducer } from './subjectReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { noty } from '../../helpers/noty';
import MultipleLoginComponent from './sub-components/multipleLogin';
import DeactivatedUserComponent from './sub-components/deactivatedUser';
export const DashboardContext = createContext();


const DashboardContextProvider = (props) => {
  const history = useHistory();
  const [dashboards, dispatch] = useReducer(dashboardReducer, {
    results: {}
  });

  const [data, setData] = useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  // account deactivated
  const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);

  const fetchData = async () => {
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
    const headers = {
      headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret,
        'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
      }
    }

    await axios
      .get(`${BasePath}/dashboard`, headers)
      .then(response => {
        setData(response.data)
        dispatch({
          type: 'DASHBOARD_RESULTS',
          initialState: {
            placeholder: 'none',
            spinner: 'none',
            dashboardStatus: 'done'
          },
          payload: response.data
        })
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            if (error.response.data.message === "Token Expired") {
              setModalOpen(true);
            } else {
              setModalOpenDeactivate(true);
            }
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          if (error.response.status === 500) {
            setModalOpen(true);
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
        } else if (error.request) {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        } else {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        }
      })
  }

  useEffect(() => {
    const initialState = {
      placeholder: 'none',
      spinner: 'block',
      dashboardStatus: 'start'
    }
    dispatch({
      type: 'DASHBOARD_RESULTS',
      initialState
    });
    fetchData()
  }, [])


  return (
    <DashboardContext.Provider
      value={{
        dashboards,
        data,
        dispatch,
      }}>
      {props.children}

      <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
      <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

    </DashboardContext.Provider>
  )
}

export default DashboardContextProvider;
