import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom";
import FadeIn from 'react-fade-in';
import { SubjectVideosContext } from './subjectVideosContext';
import Navbar from '../NavbarComponent'
import SubjectLists from '../SubjectComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faSpinner, faLock } from '@fortawesome/free-solid-svg-icons';
import './styles.css'
import { noty } from '../../helpers/noty';
import Image, { Shimmer } from 'react-shimmer'
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';

import ContentLoader from "react-content-loader"
import { assetsUrl } from '../../helpers/assetsUrl';

const MyLoader = (
	<ContentLoader
		speed={2}
		width={100}
		height={100}
		backgroundColor="white"
		foregroundColor="#585555"
	>
		<rect x="8" y="311" rx="0" ry="0" width="250" height="12" />
	</ContentLoader>
)

const SubjectVideoLists = (props) => {
	const history = useHistory();
	const context = useContext(SubjectVideosContext)
	const [searchModalDisplay, setSearchModalDisplay] = useState('block');
	const [currentVideoDisplay, setCurrentVideoDisplay] = useState('row');
	const [videoRowDisplay, setVideoRowDisplay] = useState('flex');
	const [videoListDisplay, setVideoListDisplay] = useState('none');
	const [videoRowImg, setVideoRowImg] = useState('sort-row-active.svg');
	const [videoListImg, setVideoListImg] = useState('sort-list.svg');
	const [rowFade, setRowFade] = useState(true);
	const [listFade, setListFade] = useState(false);
	// const [watchVideoIcon, setWatchVideoIcon] = useState('/images/icon-watched-green.svg');
	// const [unWatchVideoIcon, setUnWatchVideoIcon] = useState('/images/icon-watched.svg');
	const [watchVideoTrigger, setWatchVideoTrigger] = useState('block');
	const [currentVideoId, setCurrentVideoId] = useState('');


	const handleListView = () => {
		setVideoListDisplay('block')
		setVideoRowDisplay('none')
		setVideoRowImg('sort-row.svg')
		setVideoListImg('sort-list-active.svg')
		setRowFade(false)
		setListFade(true)
	}

	const handleRowView = () => {
		setVideoRowDisplay('flex')
		setVideoListDisplay('none')
		setVideoRowImg('sort-row-active.svg')
		setVideoListImg('sort-list.svg')
		setRowFade(true)
		setListFade(false)
	}

	const handleWatchVideo = (e, video) => {
		e.preventDefault();
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			// history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const isTrialAccount = parseUserDataStorage.data.user.isTrial;
			if (isTrialAccount === true) { // if user trial is true and video trial is true
				if (video.isTrial === true) {
					// show video
					history.push(`/watch/${video._id}`)
				} else {
					noty("error", `<i class = "fa fa-info-circle"></i> Unable to watch video`);
				}
			} else {
				// paid user must redirect to watch
				const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
				if (recentWatched === null) {
					localStorage.setItem(
						`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
						JSON.stringify(videoInfo)
					);
				} else {
					localStorage.setItem(
						`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
						JSON.stringify({ "status": "none" })
					);

					localStorage.setItem(
						`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`,
						JSON.stringify(videoInfo)
					);
				}

				history.push(`/watch/${video._id}`)
			}
		}
	}

	const handleMarkVideo = (e, videoId, i) => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			// history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			setCurrentVideoId(videoId)
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios
				.get(`${BasePath}/v1/videos/${videoId}/watched/toggle`, headers)
				.then(response => {
					setCurrentVideoId('')
					context.retrieveVideosPerSubject()
				})
		}
	}


	return (
		<div class="page-bg">
			<Navbar path='home' />
			<SubjectLists />

			<div class="modal-bg" style={{ display: searchModalDisplay }}>
				<div class="subject-modal modal-container dark mt-75 block-center">
					<Link to='/' class="close"><img src={assetsUrl.iconClose} /></Link>
					{context.subjectVideos.initialState ?
						context.subjectVideos.initialState.subjectVideosStatus === 'done' ?
							<FadeIn>
								<div class="cover-photo">

									<img src={context.subjectVideos.results.data.subject.imageUrl} />
								</div>
								<div class="subject-description pl-25 pr-25 pt-25 pb-20">
									<p class="f-regular">
										{context.subjectVideos.results.data.subject.description}
									</p>
								</div>


								<div class="sort-buttons pr-25">
									<span class="sort sort-list" alt="list" onClick={handleListView}><img src={window.location.origin + '/images/' + videoListImg} class="img-list" /></span>
									<span class="sort sort-row" alt="row" onClick={handleRowView}><img src={window.location.origin + '/images/' + videoRowImg} class="img-row" /></span>
								</div>


								<FadeIn visible={rowFade}>
									<div class="subject-videos pl-25 pr-15 pt-20 subject-videos-row" style={{ display: videoRowDisplay }}>


										{context.subjectVideos.results.data.videos.map((video, index) => {
											return (
												video.videoUrl === "" ?
													<div class="topic mb-10 mouse-invalid">
														<div class="thumbnail">
															<FontAwesomeIcon icon={faLock} class="topic-lock-icon text-white topic-play" />
															<img src={video.imageUrl} className="topic-low-opacity" />
														</div>
														<div class="topic-details pt-15 pb-15 pr-15 pl-15 topic-low-opacity">
															<div class="topic-1st-details">
																<p class="topic-duration f-bold mb-15">{video.duration}</p>
																<span class="watch"><img src={assetsUrl.watchActive} /></span>
															</div>
															<p class="topic-description" title={video.description}>{video.description.length > 42 ? video.description.substring(0, 42) + '...' : video.description} </p>
														</div>
													</div>
													:
													<div class="topic topic-hover mb-10">
														<div class="thumbnail" onClick={(e) => handleWatchVideo(e, video)}>
															<span class="topic-play"><img src={assetsUrl.videoPlay} /></span>
															<img src={video.imageUrl} />
														</div>
														<div class="topic-details pt-15 pb-15 pr-15 pl-15">
															<div class="topic-1st-details">
																<p class="topic-duration f-bold mb-15">{video.duration}</p>
																{
																	video.isWatched === true ?
																		<span class="watch"><img src={assetsUrl.watchActive} /></span>
																		:
																		<span class="watch" onClick={(e) => handleMarkVideo(e, video._id, index)} style={{ display: video._id === currentVideoId ? 'none' : 'block' }}><img src={assetsUrl.watch} /></span>
																}
																{
																	currentVideoId === video._id ? <span class="watch"><i className="fa fa-refresh fa-spin primary"></i></span> : null
																}
															</div>
															<p class="topic-description" title={video.description}>{video.description.length > 42 ? video.description.substring(0, 42) + '...' : video.description} </p>
														</div>
													</div>

											)
										})
										}
									</div>
								</FadeIn>


								<FadeIn visible={listFade}>
									<div class="subject-videos pl-25 pr-15 pt-20 subject-videos-list" style={{ display: videoListDisplay }}>
										{context.subjectVideos.results.data.videos.map((video, index) => {
											return (
												video.videoUrl === "" ?
													<div class="topic mb-10 mouse-invalid">
														<div class="thumbnail ml-5 mr-5 mt-5 mb-5">
															<FontAwesomeIcon icon={faLock} class="topic-lock-icon text-white topic-play" />
															<img src={video.imageUrl} className="topic-low-opacity" />
														</div>

														<div class="topic-details pt-15 pb-15 pr-15 pl-15 topic-low-opacity mouse-invalid">
															<div class="topic-1st-details">
																<p class="topic-duration f-bold mb-15">{video.duration}</p>
																<span class="watch"><img src={assetsUrl.watch} /></span>
															</div>
															<p class="topic-description" title={video.description}>{video.description.length > 42 ? video.description.substring(0, 42) + '...' : video.description}</p>
														</div>
													</div>
													:
													<div class="topic topic-hover mb-10">
														<div class="thumbnail ml-5 mr-5 mt-5 mb-5" onClick={(e) => handleWatchVideo(e, video)}>
															<span class="topic-play"><img src={assetsUrl.videoPlay} /></span>
															<img src={video.imageUrl} />
														</div>

														<div class="topic-details pt-15 pb-15 pr-15 pl-15">
															<div class="topic-1st-details">
																<p class="topic-duration f-bold mb-15">{video.duration}</p>
																{
																	video.isWatched === true ?
																		<span class="watch"><img src={assetsUrl.watchActive} /></span>
																		:
																		<span class="watch" onClick={(e) => handleMarkVideo(e, video._id, index)} style={{ display: video._id === currentVideoId ? 'none' : 'block' }}><img src={assetsUrl.watch} /></span>
																}
																{
																	currentVideoId === video._id ? <span class="watch"><i className="fa fa-refresh fa-spin primary"></i></span> : null
																}
															</div>
															<p class="topic-description" title={video.description}>{video.description.length > 42 ? video.description.substring(0, 42) + '...' : video.description}</p>
														</div>
													</div>
											)
										})}
									</div>
								</FadeIn>
							</FadeIn>
							: <div>
								<div class="cover-photo">
									<img src="https://www.casaportoro.com.br/images/load.gif" style={{ width: '100%' }} />
								</div>
								<div class="subject-description pl-25 pr-25 pt-25 pb-20">
									{MyLoader}
									<p class="f-regular">
										{MyLoader}
									</p>
								</div>


								<div class="sort-buttons pr-25">
									<span class="sort sort-list" alt="list" onClick={handleListView}><img src={window.location.origin + '/images/' + videoListImg} class="img-list" /></span>
									<span class="sort sort-row" alt="row" onClick={handleRowView}><img src={window.location.origin + '/images/' + videoRowImg} class="img-row" /></span>
								</div>
							</div>
						: null}
				</div>
			</div>
		</div>
	);
}

export default SubjectVideoLists;