import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Artplayer from './artplayer';
import { deviceDetect, isWearable, isAndroid, isConsole, isSmartTV, isMobile, isTablet, isIPad13, isIPhone13, isIPod13, isIOS, isIOS13, isMobileSafari } from "react-device-detect";
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { noty } from '../../helpers/noty';
import { WatchVideoContext } from './watchVideoContext';
import Navbar from '../NavbarComponent'
import './styles.css'
import FullScreenLoader from '../../helpers/fullScreenLoader';
import MultipleLoginComponent from '../SubjectComponent/sub-components/multipleLogin';
import DeactivatedUserComponent from '../SubjectComponent/sub-components/deactivatedUser';
import { Box, Container, Modal, Typography } from '@mui/material';
import { customSelectStyle } from '../../helpers/selectStyles';
import WatchV2VideoComponent from './videoPlayer2';

const selectPlayerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgba(13, 14, 11, 0.75);',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const WatchVideoComponent = (props) => {
  const history = useHistory();

  const [videoInfo, setVideoInfo] = useState({});


  // multiple login
  const [modalOpen, setModalOpen] = useState(false);

  // account deactivated
  const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);

  const [pipConfig, setPipConfig] = useState(true);

  // Current player selected
  const [selectedVideoPlayer, setSelectedVideoPlayer] = useState('');

  // Current player modal state
  const [selectedVideoPlayerOpen, setSelectedVideoPlayerOpen] = useState(true);

  // Player Options
  const [playerOptions, setPlayerOptions] = useState([]);


  let qualitySelector = new Array()

  var pageHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;
  var pageWidth = (document.width !== undefined) ? document.width : document.body.offsetWidth;
  const handlePrevious = (e) => {
    e.preventDefault();
    setPipConfig(false);
    history.goBack();
  }
  useEffect(() => {
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    if (userDataStorage === null) {
      history.push("/login");
    } else {
      getVideoDetails(userDataStorage);
    }
  }, [])

  const getVideoDetails = (userDataStorage) => {
    const parseUserDataStorage = JSON.parse(userDataStorage)
    const headers = {
      headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret,
        'token': parseUserDataStorage.data.token
      }
    }
    var videoId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    axios
      .get(`${BasePath}/videos/${videoId}`, headers)
      .then(response => {
        const videoRes = response.data.data.video;
        // Set player1 as default if videoUrl2 not exists
        if (videoRes.videoUrl2 == null || videoRes.videoUrl2 == "") {
          setSelectedVideoPlayer("player1");
          setSelectedVideoPlayerOpen(false);
        }
        // Set rumble as default player
        let playOptions = [{ value: "player1", label: "Video Player 1" }]
        if ('videoUrl2' in videoRes) {
          playOptions.push({ value: "player2", label: "Video Player 2" })
        }
        setPlayerOptions(playOptions)
        setVideoInfo(videoRes);


        // setQualitySelector(oldQualitySelector => [...oldQualitySelector, {
        //   default: true,
        //   name: '1080P',
        //   url: videoRes.videoUrl
        // }])
        // console.log(JSON.stringify(qualitySelector))
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            if (error.response.data.message === "Token Expired") {
              setModalOpen(true);
            } else {
              setModalOpenDeactivate(true);
            }
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
          if (error.response.status === 500) {
            setModalOpen(true);
            setTimeout(() => {
              history.push("/login");
            }, 3000);
          }
        } else if (error.request) {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        } else {
          noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
        }
      })
  }

  const handleSelectPlayer = (e) => {
    setSelectedVideoPlayer(e.value);
    setSelectedVideoPlayerOpen(false);
  }

  if ('videoUrl' in videoInfo) {
    qualitySelector.push({
      html: 'FHD 1080P',
      url: videoInfo.videoUrl
    });
  }

  if ('videoUrl_720p' in videoInfo) {
    qualitySelector.push({
      default: true,
      html: 'HD 720P',
      url: videoInfo.videoUrl_720p
    })
  }

  if ('videoUrl_480p' in videoInfo) {
    qualitySelector.push({
      html: 'SD 480P',
      url: videoInfo.videoUrl_480p
    })
  }

  // if (isAndroid || isSmartTV || isConsole || isMobile || isTablet || isIPad13 || isIPhone13 || isIPod13 || isIOS || isIOS13 || isMobileSafari) {
  //   return (
  //     <body className="watch-video-bgcolor">
  //       <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
  //       <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

  //       <Navbar path="watch" />
  //       {Object.keys(videoInfo).length === 0 ? <FullScreenLoader loading={true} /> : (
  //         <div className="pl-5 pr-5 pt-70">
  //           <div class="videoWrapper">
  //             <iframe width="560" height="349" src={videoInfo.videoUrl} frameborder="0" allowfullscreen></iframe>
  //           </div>
  //           <div className="pl-10 pr-10 pb-10 pt-10">
  //             <h3 className="white">{videoInfo.title}</h3>
  //             <hr />
  //             <p className='white'>{videoInfo.description}</p>
  //           </div>
  //         </div>
  //       )
  //       }
  //     </body>
  //   )
  // } else {
  return (
    <div>
      <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
      <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />
      {Object.keys(videoInfo).length === 0 ? <FullScreenLoader loading={true} /> : (
        selectedVideoPlayer === "" ?
          <div>
            <Modal
              open={selectedVideoPlayerOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className='modal-multiple-login'>
                <Container>

                  <Box sx={selectPlayerStyle} className="">
                    <Typography id="modal-modal-title" variant="h6" component="h2" className="center modal-select-player-text">
                      Select Video Player
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className="center modal-multiple-login-message">
                      <Select options={playerOptions} styles={customSelectStyle} onChange={handleSelectPlayer} />
                    </Typography>
                  </Box>
                </Container>
              </div>
            </Modal>
          </div>

          :
          <div>
            {selectedVideoPlayer === 'player1' ? <div className="watch-video-bgcolor hide-scroll">
              <div className="">
                <h2 className="close-video" onClick={(e) => handlePrevious(e)}> <FontAwesomeIcon icon={faTimes} /> </h2>
                <div class="videoWrapper">
                  <iframe width="560" height="349" src={videoInfo.videoUrl} frameborder="0" allowfullscreen></iframe>
                </div>
                <div className="pl-10 pr-10 pb-10 pt-10">
                  <h3 className="white">{videoInfo.title}</h3>
                  <hr />
                  <p className='white'>{videoInfo.description}</p>
                </div>
                {/* <Artplayer
                      className="myDIV"
                      option={{
                        container: '.artplayer-app',
                        url: videoInfo.videoUrl,
                        title: videoInfo.title,
                        poster: videoInfo.thumbnail,
                        volume: 0.5,
                        muted: false,
                        autoplay: false,
                        pip: pipConfig,
                        autoSize: false,
                        screenshot: false,
                        setting: true,
                        loop: true,
                        aspectRatio: true,
                        fullscreen: true,
                        fullscreenWeb: false,
                        mutex: true,
                        theme: "#d43a3a",
                        lang: "en",
                        quality: qualitySelector,
                        playbackRate: true,
                        miniProgressBar: true,
                        hotkey: true,
                        moreVideoAttr: {
                        }
                      }}
                      style={{
                        height: pageHeight,
                        margin: "0 auto",
                        width: '100% !important'
                      }}
                      getInstance={ins => console.log("ins")}
                    /> */}
              </div>
            </div>
              :
              <WatchV2VideoComponent videoInfo={videoInfo} />
            }

          </div>
      )
      }

    </div>
  );
  // }
  // }
}
export default WatchVideoComponent;