import React, { useContext, useState, createContext, useRef, useEffect, useCallback } from 'react';
import { Link, useHistory } from "react-router-dom";
import { deviceDetect, isWearable, isAndroid, isConsole, isSmartTV, isMobile, isTablet, isIPad13, isIPhone13, isIPod13, isIOS, isIOS13, isMobileSafari } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faPlay, faPause, faCompress, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import screenfull from 'screenfull';
import Navbar from '../../NavbarComponent'
import './styles.css'
import FullScreenLoader from '../../../helpers/fullScreenLoader';
import MultipleLoginComponent from '../../SubjectComponent/sub-components/multipleLogin';
import DeactivatedUserComponent from '../../SubjectComponent/sub-components/deactivatedUser';
import ReactPlayer from './player/ReactPlayer'
import Duration from './player/Duration'
import { Icon, Slider, Grid, Button, MenuItem, Menu, Tooltip, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { Fullscreen, Settings, Speed, VolumeDown, VolumeMute, VolumeMuteRounded, VolumeOff, VolumeUp } from '@mui/icons-material';
import { assetsUrl } from '../../../helpers/assetsUrl';
import ResponsiveWatchVideo from './index-responsive';


const WatchV2VideoComponent = (props) => {
  const history = useHistory();
  const [videoInfo, setVideoInfo] = useState(props.videoInfo);

  // multiple login
  const [modalOpen, setModalOpen] = useState(false);
  // account deactivated
  const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);

  var pageHeight = (document.height !== undefined) ? document.height : document.body.offsetHeight;
  var pageWidth = (document.width !== undefined) ? document.width : document.body.offsetWidth;


  // YOUTUBE PLAYER STATES
  const [url, setUrl] = useState(videoInfo.videoUrl2);
  const [pip, setPip] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [controls, setControls] = useState(false);
  const [light, setLight] = useState(false);
  const [volume, setVolume] = useState(1);
  const [sliderVolume, setSliderVolume] = useState(5);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [loop, setLoop] = useState(true);
  const [startTimeOut, setStartTimeOut] = useState(true);
  const [topVideoContentHeight, setTopVideoContentHeight] = useState(0);
  const [topDefaultDifference, setTopDefaultDifference] = useState(52);

  const [videoStart, setVideoStart] = useState(false);
  const [playingClass, setPlayingClass] = useState("iframe-content-bottom-play");
  const [timeout, setTimeout] = useState(30000);
  const [isTimedOut, setIsTimedOut] = useState(false);

  const [isFullscreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const height = videoRef.current.clientHeight;
    setTopVideoContentHeight(height)

    if (isAndroid || isSmartTV || isConsole || isMobile || isIPhone13 || isIPod13 || isIOS || isIOS13 || isMobileSafari) {
      setTimeout(() => {
        setTopDefaultDifference(0); //20
        setTopVideoContentHeight(0)
      }, 5000);
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (screenfull.isFullscreen) {
          setIsFullScreen(!isFullscreen);
        }
        if (!screenfull.isFullscreen) {
          setIsFullScreen(false);
        }
        // console.log('Am I fullscreen?', screenfull.isFullscreen ? 'Yes' : 'No');
      });
    }


    const keyDownHandler = event => {
      // console.log('User pressed: ', event.key);

      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [])

  const handlePlayPause = () => {
    setPlaying(!playing);
  }

  const handleStop = () => {
    setPlaying('');
    setPlaying(false);
  }

  const handleVolumeChange = e => {
    setSliderVolume(e);
    if (e === 1) {
      setVolume(0.2);
    } else if (e === 2) {
      setVolume(0.4);
    } else if (e === 3) {
      setVolume(0.6);
    } else if (e === 4) {
      setVolume(0.8);
    } else if (e === 5) {
      setVolume(1);
    }
  }

  const handleVolumeClick = () => {
    setSliderVolume(sliderVolume + 1);
    if (sliderVolume === 1) {
      setVolume(0.2);
    } else if (sliderVolume === 2) {
      setVolume(0.4);
    } else if (sliderVolume === 3) {
      setVolume(0.6);
    } else if (sliderVolume === 4) {
      setVolume(0.8);
    } else if (sliderVolume === 5) {
      setVolume(1);
    }
  }

  const handleToggleMuted = () => {
    setMuted(!muted);
  }

  const handleSetPlaybackRate = e => {
    setPlaybackRate(parseFloat(e))
  }

  // handleTogglePIP = () => {
  //   this.setState({ pip: !this.state.pip })
  // }

  const handlePlay = () => {
    setPlaying(true);
    setPlayingClass('iframe-content-bottom-play');
  }

  const handleEnablePIP = () => {
    // this.setState({ pip: true })
    setPip(true);
  }

  const handleDisablePIP = () => {
    // this.setState({ pip: false })
    setPip(false);
  }

  const handlePause = () => {
    setPlaying(false);
    setPlayingClass("iframe-content-bottom-pause");
  }

  const handleSeekMouseDown = e => {

    setPlayed(parseFloat(e.target.value));
    setSeeking(true)
  }

  const handleSeekChange = e => {
    // new
    // setSeeking(true)
    // setPlayed(e);


    // old
    setPlayed(parseFloat(e.target.value));
    player.seekTo(e.target.value);

    // setPlayed(parseFloat(e.target.value));
    // player.seekTo(parseFloat(e.target.value));
  }

  const handleSeekMouseUp = e => {
    setSeeking(false)
    player.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = state => {
    setPlayed(state.played);

    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setSeeking(state)
    }
  }

  const handleEnded = () => {
    setPlayed(0);
    setDuration(0);
    setPlaying(loop);
  }

  const handleDuration = (duration) => {
    setDuration(duration);
  }


  const handleClickFullscreen = () => {
    setIsFullScreen(true);
    // alert(screenfull.isEnabled)
    screenfull.request(videoRef.current);
    // setIsFullScreen(false);
    // if (!screenfull.isFullscreen) {
    // }
    // if (playerWrapper?.current) {
    //   if (screenfull.isFullscreen) {
    //     screenfull.exit(playerWrapper.current);
    //   } else {
    //     screenfull.request(playerWrapper.current);
    //   }
    // }
    // setState({ ...state, fullscreen: !state.fullscreen });
  };

  const playerRef = player => {
    // player.seekTo(0);
    setPlayer(player);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, playRate) => {
    e.preventDefault();
    setAnchorEl(null);
    handleSetPlaybackRate(playRate)
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    history.goBack();
  }

  const handleOnReady = (e) => {
    player.seekTo(0)
  }

  return (
    <div>
      <MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
      <DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />
      {Object.keys(videoInfo).length === 0 ? <FullScreenLoader loading={true} /> : (
        <div>
          <div className="">
            <h2 className="close-video" onClick={(e) => handlePrevious(e)}> <FontAwesomeIcon icon={faTimes} /> </h2>
            <div className="player-wrapper" ref={videoRef}>
              <div className="player-test">
                <div>
                  <ReactPlayer
                    ref={playerRef}
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={videoInfo.videoUrl2}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    pip={pip}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onReady={(e) => handleOnReady(e)}
                    onStart={() => console.log('')}
                    onPlay={handlePlay}
                    onEnablePIP={handleEnablePIP}
                    onDisablePIP={handleDisablePIP}
                    onPause={handlePause}
                    onBuffer={() => console.log('')}
                    onSeek={e => console.log('')}
                    onEnded={handleEnded}
                    onError={e => console.log('')}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                  />
                  <div className="iframe-content-top"></div>
                  {/* <div className={playingClass} style={{ top: topVideoContentHeight - 52 }}></div> */}
                  <div className={playingClass} style={{ top: topVideoContentHeight - topDefaultDifference, visibility: isFullscreen === true ? "hidden" : "visible" }}></div>

                </div>


              </div>
            </div>
            <div>
              <div class="video-slider">
                {/* <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      color: "#FF0000"
                    },
                    '& .MuiSlider-track': {
                      color: "#FF0000"
                    },
                    '& .MuiSlider-rail': {
                      color: "#acc4e4"
                    },
                    '& .MuiSlider-active': {
                      color: "green"
                    }
                  }}
                  aria-label="Slider"
                  defaultValue={played}
                  min={1} max={100}
                  color="primary"
                  value={played}
                  onChange={(_, v) => handleSeekChange(v)}
                  onChangeCommitted={(_, v) => handleSeekChange(v)}
                /> */}
                <div className="">
                  <input
                    class="custom-range form-control win10-thumb"
                    id="customRange1"
                    type='range' min={0} max={1} step='any'
                    value={played}
                    onMouseDown={(e) => handleSeekMouseDown(e)}
                    onChange={(e) => handleSeekChange(e)}
                    onMouseUp={(e) => handleSeekMouseUp(e)}
                  />
                </div>

                {isAndroid || isSmartTV || isConsole || isMobile || isTablet || isIPad13 || isIPhone13 || isIPod13 || isIOS || isIOS13 || isMobileSafari
                  ?
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                      }}
                    >
                      <div className="btn-play-video-responsive" onClick={handlePlayPause}>
                        {playing ? <Icon baseClassName="fa" className="fa-pause" sx={{ fontSize: 25 }} /> : <Icon baseClassName="fa" className="fa-play" sx={{ fontSize: 25 }} />}
                      </div>
                      <div className="btn-text-responsive">
                        <label className="text-white"><Duration seconds={duration * played} /> </label>
                      </div>
                      <div className="btn-play-video volume-content">
                        <Stack spacing={2} direction="row" alignItems="center" className="pointer">

                          {muted ? <Tooltip title="Unmute" ><VolumeOff onClick={handleToggleMuted} /></Tooltip> : <Tooltip title="Mute" ><VolumeDown onClick={handleToggleMuted} /></Tooltip>}
                          <Slider aria-label="Volume" defaultValue={sliderVolume} value={sliderVolume} min={1} max={5} onChange={(_, v) => handleVolumeChange(v)} onChangeCommitted={(_, v) => handleVolumeChange(v)} />
                          <Tooltip title="Volume up" ><VolumeUp onClick={handleVolumeClick} /></Tooltip>
                        </Stack>
                      </div>

                      <div className="btn-play-video-responsive">
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          className="modal-video-sort-icon"
                        >
                          <Settings sx={{ fontSize: 30 }} className="pointer text-white" />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={(e) => handleClose(e, '0.25')} className={playbackRate === 0.25 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>0.25x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '0.5')} className={playbackRate === 0.5 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>0.5x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.0')} className={playbackRate === 1 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.25')} className={playbackRate === 1.25 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1.25x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.5')} className={playbackRate === 1.5 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1.5x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '2')} className={playbackRate === 2 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>2x</MenuItem>
                        </Menu>
                      </div>
                      <Box className="btn-play-video-responsive" sx={{ alignContent: 'flex-end' }}>
                        <Fullscreen sx={{ fontSize: 30 }} className="pointer" onClick={handleClickFullscreen} />
                      </Box>
                    </Box>
                    {/* <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                      }}
                    >
                      <div className=""><label className="text-white"><Duration seconds={duration * (1 - played)} /></label></div>
                    </Box> */}
                  </div>
                  :
                  <Grid container spacing={3}>
                    <Grid item style={{ width: "60px" }}>
                      <div className="btn-play-video" onClick={handlePlayPause}>
                        {playing ? <Icon baseClassName="fa" className="fa-pause" sx={{ fontSize: 25 }} /> : <Icon baseClassName="fa" className="fa-play" sx={{ fontSize: 25 }} />}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="btn-play-video volume-content">
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" className="pointer">

                          {muted ? <Tooltip title="Unmute" ><VolumeOff onClick={handleToggleMuted} /></Tooltip> : <Tooltip title="Mute" ><VolumeDown onClick={handleToggleMuted} /></Tooltip>}
                          <Slider aria-label="Volume" defaultValue={sliderVolume} value={sliderVolume} min={1} max={5} onChange={(_, v) => handleVolumeChange(v)} onChangeCommitted={(_, v) => handleVolumeChange(v)} />
                          <Tooltip title="Volume up" ><VolumeUp onClick={handleVolumeClick} /></Tooltip>
                        </Stack>
                      </div>
                    </Grid>
                    <Grid item className="grid-right text-duration" style={{ width: "80px" }}>
                      <label className="text-white"><Duration seconds={duration * played} /> </label>
                      {/* <label className='text-white'><Duration seconds={duration * played} /></label> */}
                    </Grid>
                    <Grid item style={{ width: "68px", paddingLeft: "4rem" }}>
                      <div className="btn-play-video">
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          className="modal-video-sort-icon"
                        >
                          <Settings sx={{ fontSize: 30 }} className="pointer text-white" />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={(e) => handleClose(e, '0.25')} className={playbackRate === 0.25 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>0.25x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '0.5')} className={playbackRate === 0.5 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>0.5x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.0')} className={playbackRate === 1 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.25')} className={playbackRate === 1.25 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1.25x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '1.5')} className={playbackRate === 1.5 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>1.5x</MenuItem>
                          <MenuItem onClick={(e) => handleClose(e, '2')} className={playbackRate === 2 ? 'play-rate-active modal-video-sort-font' : 'modal-video-sort-font'}>2x</MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                    <Grid item style={{ width: "80px" }}>
                      <div className="btn-play-video">
                        <Fullscreen sx={{ fontSize: 30 }} className="pointer" onClick={handleClickFullscreen} />
                      </div>
                    </Grid>
                  </Grid>
                }
              </div>
              <div className="video-information">
                <h3 className="white">{videoInfo.title}</h3>
                <hr />
                <p className='white'>{videoInfo.description}</p>
              </div>
            </div>

            {/* <div className="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button className="btn btn-primary" onClick={handlePlayPause}>{playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}</button> &nbsp;
                    <input type='range' className="volume-seeker" min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} />
                  </div>
                  <input
                    class="custom-range form-control"
                    id="customRange1"
                    type='range' min={0} max={1} step='any'
                    value={played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"><Duration seconds={duration * played} /> / <Duration seconds={duration * (1 - played)} /></span>
                    <button class={muted ? "input-group-text text-danger" : "input-group-text"} onClick={handleToggleMuted}> <FontAwesomeIcon icon={faVolumeMute} /> </button>
                    <button class={playbackRate === 1 ? "input-group-text text-success" : "input-group-text"} onClick={handleSetPlaybackRate} value={1}>1x</button>
                    <button class={playbackRate === 1.5 ? "input-group-text text-success" : "input-group-text"} onClick={handleSetPlaybackRate} value={1.5}>1.5x</button>
                    <button class={playbackRate === 2 ? "input-group-text text-success" : "input-group-text"} onClick={handleSetPlaybackRate} value={2}>2x</button>
                    <button class="input-group-text" onClick={handleClickFullscreen}> <FontAwesomeIcon icon={faCompress} /></button>

                  </div>
                </div>
              </div> */}


            {/* <div className="pl-10 pr-10 pb-10 pt-10">
                <h3 className="white">{videoInfo.title}</h3>
                <hr />
                <p className='white'>{videoInfo.description}</p>
              </div> */}

          </div>
        </div>
      )
      }

    </div>
  );

  // }
}
export default WatchV2VideoComponent;