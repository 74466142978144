import { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import FadeIn from 'react-fade-in';
import {
	ThemeProvider,
	InputLabel,
	CssBaseline,
	FormControl,
	FormHelperText,
	FilledInput,
	IconButton,
	InputAdornment,
	TextField,
	Box,
	Input
} from '@mui/material';
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import FullScreenLoader from '../../helpers/fullScreenLoader';
import { BasePath, headerNonUser } from '../../helpers/env';
import Notification from '../NotificationComponent';
import '../../assets/css/main-style.css';
import '../../assets/css/form.css';
import './styles.css';
import axios from 'axios';
import { darkTheme } from '../../helpers/darkTheme';
import { noty } from '../../helpers/noty';
import { assetsUrl } from '../../helpers/assetsUrl';

const inputStyle = {
	borderRadius: "10px",
	fontSize: "13px",
	WebkitBoxShadow: "0 0 0 1000px #454545 inset",
	disableUnderline: true
};

const ariaLabel = { 'aria-label': 'description' };

const LoginComponent = (props) => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isRemember, setIsRemember] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationType, setNotificationType] = useState('');
	const [showNotif, setShowNotif] = useState(false);
	const [fullScreenLoader, setFullScreenLoader] = useState(false);
	const [maskPasswordStat, setMaskPasswordStat] = useState(false);
	const [maskPassword, setMaskPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [passwordBlurError, setpasswordBlurError] = useState(false);

	useEffect(() => {
		const rememberUser = localStorage.getItem("loginUserRemember");
		if (rememberUser !== null) {
			const extractCreds = JSON.parse(rememberUser);
			setEmail(extractCreds.email);
			setPassword(extractCreds.password);
		}
	}, [])


	const handleLoginSubmit = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		axios
			.post(`${BasePath}/auth/signin`, { email, password }, headerNonUser)
			.then(response => {
				localStorage.setItem(
					'pinnacleStreamingV2WebApp',
					JSON.stringify(response.data)
				);
				setFullScreenLoader(false);
				setEmail('');
				setPassword('');
				setFullScreenLoader(false);
				setShowNotif(true)
				setNotificationMessage('Login successful')
				noty("success", '<i class = "fa fa-check"></i> Login successful')
				setNotificationType('notificationSuccess')
				setTimeout(() => {
					setShowNotif(false)
					if (response.data.data.user.isEula === true) {
						if (isRemember) {
							localStorage.setItem(
								'loginUserRemember',
								JSON.stringify({ email, password })
							);
						}
						history.push('/')
					} else {
						history.push('/account/setup')
					}
				}, 2500);

			})
			.catch(error => {
				if (error.response) {
					if (error.response.status === 401) {
						setFullScreenLoader(false);
						noty("error", `<i class = "fa fa-info-circle"></i> ${error.response.data.message}`);
						setShowNotif(true)
						setNotificationMessage(error.response.data.error)
						setNotificationType('notificationFailed')
						setTimeout(() => {
							setShowNotif(false)
						}, 3000);
					}
					if (error.response.status === 500) {
						setFullScreenLoader(false);
						noty("error", `<i class = "fa fa-info-circle"></i> Warning! Multiple logins detected. This incident will be reported.`);
						setShowNotif(true)
						setNotificationMessage('Warning! Multiple logins detected. This incident will be reported.')
						setNotificationType('notificationFailed')
						setTimeout(() => {
							setShowNotif(false)
						}, 4000);
					}
				} else if (error.request) {
					setFullScreenLoader(false);
					setShowNotif(true)
					noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					setNotificationMessage('Something went wrong. Please try again')
					setNotificationType('notificationFailed')
					setTimeout(() => {
						setShowNotif(false)
					}, 4000);
				} else {
					setFullScreenLoader(false);
					setShowNotif(true)
					noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					setNotificationMessage('Something went wrong. Please try again')
					setNotificationType('notificationFailed')
					setTimeout(() => {
						setShowNotif(false)
					}, 4000);
				}
			})

	}

	const handleMaskPassword = () => {
		setMaskPasswordStat(!maskPasswordStat)
	}

	const handleCreateAccount = () => {
		history.push('/create-account')
	}

	const handleEmail = (email) => {
		setEmail(email)
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) && email !== "") {
			setInvalidEmail(false);
		} else {
			setInvalidEmail(true);
		}
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleEmailOnClick = (email) => {
		handleEmail(email);
	}

	const handlePassword = (password) => {
		setPassword(password);
		if (password === "") {
			setpasswordBlurError(true);
		}
	}

	const handleOnBlur = () => {
		if (password === "") {
			setpasswordBlurError(true);
		}
	}

	const onChangeCheckbox = (event) => {
		setIsRemember(event.target.checked)
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div className="wrapper form-bg">
				<FullScreenLoader loading={fullScreenLoader} />
				<div className="right-container">
					<form className="form-container pb-40 pl-35 pr-35 mr-90 login-form" onSubmit={handleLoginSubmit}>
						<FadeIn>

							<h2 className="header f-bold mt-45 mb-20 text-white">Sign In</h2>
							<div className="form-group textfield-border-radius">

								{/* <TextField
									className="textfield"
									fullWidth
									variant="filled"
									label="Debugger"
									rows="10"
									margin="normal"
									style={{ display: 'none' }}
								/> */}

								<TextField
									type="email"
									label="Email"
									variant="filled"
									fullWidth
									onChange={(e) => handleEmail(e.target.value)}
									onClick={(e) => handleEmailOnClick(e.target.value)}

									value={email}
									error={invalidEmail ? true : false}
									color={invalidEmail || email === '' ? 'error' : 'primary'}
									style={{ marginBottom: "15px" }}
									helperText={invalidEmail ? 'Please enter valid email' : ''}
									inputProps={{ style: inputStyle }}
									InputProps={{ disableUnderline: invalidEmail ? false : true }}
									className="inputRounded"
									rows="10"
									margin="normal"

								/>
							</div>
							<div className="mb-15"></div>


							<div className="outlined-input-border-radius inputRounded">
								<FormControl variant="filled">
									<InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
									<FilledInput
										type={maskPasswordStat ? 'text' : 'password'}
										value={password}
										inputProps={{ style: inputStyle }}
										InputProps={{ disableUnderline: true }}
										onChange={(e) => handlePassword(e.target.value)}
										onBlur={handleOnBlur}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMaskPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{maskPasswordStat ? <VisibilityOffOutlined /> : <RemoveRedEyeOutlined />}
												</IconButton>
											</InputAdornment>
										}
										error={passwordBlurError && password === "" ? true : false}
										color={passwordBlurError && password === "" ? 'error' : 'none'}
										className="passwordRounded"
										style={{ border: "none" }}
										disableUnderline={passwordBlurError && password === "" ? false : true}
									/>
									<FormHelperText error>{passwordBlurError && password === "" ? "Incorrect entry" : ""}</FormHelperText>
								</FormControl>
							</div>
							<div className='mb-30'></div>
							<button type="submit" class="btn button-primary">Sign In</button>
							<div className='padding-top-10px'></div>
							<span>
								<input type="checkbox" value="lsRememberMe" className='float-left' onChange={(e) => onChangeCheckbox(e)} /> <label for="rememberMe" className="text-white f-medium float-left">Remember me</label>
								<Link to='/password/forgot' className="text-white f-medium float-right">Forgot Password?</Link>
							</span> <br /><br />
							<div className="center">
								<button type="button" onClick={handleCreateAccount} class="btn btn-create-account mb-20 btn-clear">Create Account</button>
							</div>
						</FadeIn>
						<div className="center bottom">
							<img src={assetsUrl.logo} className="login-logo" />
						</div>
					</form>
				</div>

				{/* <Notification visible={showNotif} message={notificationMessage} type={notificationType} /> */}
			</div>
		</ThemeProvider >
	);
}

export default LoginComponent;