const initialState = {
    placeholder: 'block',
    spinner: 'none',
    dashboardStatus: 'pending'
}
export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DASHBOARD_RESULTS":
            return {
                initialState: action.initialState,
                results: action.payload,
            }
        default:
            return initialState
    }
}