import WatchVideoContextProvider from './watchVideoContext';
import WatchVideoContent from './watchVideoContent';

const Home = () => {
  return (
    <div>
    	<WatchVideoContextProvider>
			<WatchVideoContent />
		</WatchVideoContextProvider>
    </div>
  );
}

export default Home;
