const initialState = {
    placeholder: 'block',
    spinner: false,
    videoDetailsStatus: 'pending'
}

export const watchVideoReducer = (state, action) => {
    switch(action.type) {
        case "VALIDATE_USER":
            return {
                email: action,
                password: action.login.password
            }
        case "VIDEO_DETAILS":
            return {
                initialState: action.initialState,
                results: action.payload,
            }
        default:
            return state
    }
}