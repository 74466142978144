import {v1 as uuid} from "uuid"; 

export const firstTImeLoginReducer = (state, action) => {
    switch(action.type) {
        case "SCHOOL_LISTS":
            return {
                schools: action.payload
            }
        default:
            return state
    }
}