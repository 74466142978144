const initialState = {
    placeholder: 'block',
    spinner: 'none',
    subjectVideosStatus: 'pending'
}
export const subjectVideosReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SUBJECT_VIDEOS_RESULTS":
            return {
                initialState: action.initialState,
                results: action.payload,
            }
        default:
            return initialState
    }
}