import React, { useContext, useState, createContext, useReducer, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { DashboardContext } from './subjectContext';
import ContentLoader from "react-content-loader";
import FadeIn from 'react-fade-in';
import "./styles.css";
import axios from 'axios';
import { Box, TextField, Grid, ThemeProvider, CssBaseline, Button, ImageList, ImageListItem, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Modal, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import useHover from "@react-hook/hover";
import { darkTheme } from '../../helpers/darkTheme';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import VideosPerSubjectComponent from './sub-components/videosPerSubject/VideosPerSubjectComponent';
import ShowAllVideosPerSubjectComponent from './sub-components/showAllVideosPerSubject/ShowAllVideosPerSubjectComponent';
import MultipleLoginComponent from './sub-components/multipleLogin';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { assetsUrl } from '../../helpers/assetsUrl';


const MyLoader = (
	<ContentLoader
		speed={2}
		width={300}
		height={170}
		backgroundColor="#413e3e"
		foregroundColor="#585555"
	>
		<rect width="553" height="376" />
	</ContentLoader>
)

const settings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
	arrows: false
};

const SubjectLists = (props) => {
	const history = useHistory();
	const context = useContext(DashboardContext)
	const [modalShowAllDisplay, setModalShowAllDisplay] = useState('none');
	const [modalShowVideosDisplay, setModalShowVideosDisplay] = useState('none');
	const [modalMultipleLoginDisplay, setModalMultipleLoginDisplay] = useState('none');
	const [selectedVideoId, setSelectedVideoId] = useState('');
	const [selectedSubjectId, setSelectedSubjectId] = useState('');
	const [selectedSubjectName, setSelectedSubjectName] = useState('');
	const [recentWatched, setRecentWatched] = useState({});
	const [tokenValid, setTokenValid] = useState(false);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [hoverSubjectId, setHoverSubjectId] = useState('');

	if (context.dashboards.initialState) {
		if (context.dashboards.initialState.dashboardStatus === 'done') {
			const program = context.dashboards.results.data.program;
			const pageBg = document.querySelectorAll('.page-bg');
			pageBg.forEach(page => {
				page.style.backgroundColor = 'red';
				page.style.backgroundImage = `url('${program.coverPhoto}')`;
				page.style.backgroundRepeat = "no-repeat, repeat";
				page.style.backgroundColor = "#0D0D0D";
				page.style.backgroundSize = "cover";
				page.style.height = "500px";
			});
		}
	}

	const showModal = async (content, id, extra) => {
		await validateToken();
		if (content === 'modalShowAll') {
			setModalShowAllDisplay('block');
			setSelectedSubjectId(id);
			setSelectedSubjectName(extra);
		} else if (content === 'modalShowVideos') {
			setModalShowVideosDisplay('block');
			setSelectedVideoId(id);
		} else if (content === 'modalMultipleLogin') {
			setModalMultipleLoginDisplay('block');
		}
	}

	const closeModal = (e, content) => {
		e.preventDefault();
		if (content === 'modalShowAll') {
			setModalShowAllDisplay('none');
			setSelectedSubjectId('');
		} else if (content === 'modalShowVideos') {
			setSelectedVideoId('');
			setModalShowVideosDisplay('none');
		} else if (content === 'modalShowVideos') {
			setModalMultipleLoginDisplay('none');
		}
		// window.location.reload(false);
	}

	const validateToken = () => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
		const headers = {
			headers: {
				'x-client-id': pinnacleStreamingV2ClientId,
				'x-client-secret': pinnacleStreamingV2ClientSecret,
				'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
			}
		}
		// axios
		// 	.get(`${BasePath}/auth/validate`, headers)
		// 	.then()
		// 	.catch(error => {
		// 		if (error.response) {
		// 			if (error.response.status === 401) {
		// 				setModalOpen(true);
		// 				setTimeout(() => {
		// 					history.push("/login");
		// 				}, 3000);
		// 			}
		// 			if (error.response.status === 500) {
		// 				setModalOpen(true);
		// 				setTimeout(() => {
		// 					history.push("/login");
		// 				}, 3000);
		// 			}
		// 		} else if (error.request) {
		// 			noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
		// 		} else {
		// 			noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
		// 		}
		// 	})
	}

	useEffect(() => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const recentWatched = localStorage.getItem(`pinnacleRecWatched_${parseUserDataStorage.data.user._id}`)
			if (recentWatched !== null) {
				setRecentWatched(JSON.parse(recentWatched));
			}
		}
	}, [])

	const handleGoWatchVideo = (e, videoId) => {
		e.preventDefault();
		history.push(`/watch/${videoId}`)
	}

	const handlePlayRecentVid = (e, videoId) => {
		e.preventDefault();
		history.push(`/watch/${videoId}`);
	}

	const handleHover = (status, subjectId) => {
		if (status === "active") {
			setHoverSubjectId(subjectId);
		} else {
			setHoverSubjectId('');
		}
	}

	let renderSubjectVideos = '';
	if (context.dashboards.initialState) {
		if (context.dashboards.initialState.dashboardStatus === 'done') {
			const subjectLists = context.dashboards.results.data.subjects;
			renderSubjectVideos = (
				subjectLists.map((subject) => (
					subject.videos.length > 0 ?
						<Box sx={{ width: '100%' }}>
							<div className='subject-header' onMouseOver={() => handleHover('active', subject._id)} onMouseOut={() => handleHover('out', subject._id)}>
								<u className="subject-text-box">{subject.name}</u>
								{hoverSubjectId === subject._id ? (
									<a className="show-all subject-text-box" onClick={() => showModal('modalShowAll', subject._id, subject.name)}>
										&nbsp; Show all &gt;
									</a>
								) : (<u className='text-decoration-none'>&nbsp; &gt;</u>)}


							</div>
							<div className="mb-5"></div>
							<BrowserView>
								<Grid sx={{ flexGrow: 1 }} container className="center">
									<Grid item xs={12}>
										<Grid container justifyContent="left" spacing={2} className="grid-video-parent">
											{subject.videos.slice(0, 5).map((video) => (
												<Grid key={video._id} item xs={6} md rowSpacing={2} columnSpacing={2} className="custom-grid-size">
													<div className='container1 subject-video-zoom block-icon' onClick={() => showModal('modalShowVideos', video._id, void 0)}>
														{/* <img
															src={video.thumbnail}
															srcSet={video.thumbnail}
															alt={video.thumbnail}
															className="video-thumb-landscape video-thumb-size"
														/>
														<img src={assetsUrl.videoPlay} className="video-play-button icon-tag2" /> */}
														<div className="img-thumb-overlay-v2">
															<img
																src={video.thumbnail}
																srcSet={video.thumbnail}
																alt={video.thumbnail}
																className="video-thumb-landscape-v2"
															/>

															<div class="img-icon-overlay-v2">
																<img src={assetsUrl.videoPlay} className="video-play-button img-icon-v2" />
															</div>
														</div>
													</div>
													<label class='video-name-label center'>{video.title.length > 40 ? video.title.substring(0, 39) + '...' : video.title}</label>
												</Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
								<div className="mb-20"></div>
							</BrowserView>
							<MobileView>
								<Slider {...settings}>
									{subject.videos.slice(0, 5).map((video) => (
										<div style={{ 'padding': '5px' }} className='' onClick={() => showModal('modalShowVideos', video._id, void 0)}>
											<div className="img-thumb-overlay-v2">
												<img
													src={video.thumbnail}
													srcSet={video.thumbnail}
													alt={video.thumbnail}
													className="video-thumb-landscape-mobile-v2"
												/>

												<div class="img-icon-overlay-v2">
													<img src={assetsUrl.videoPlay} className="video-play-button img-icon-v2" />
												</div>
											</div>
											<div className="mb-5"></div>
											<label class='video-name-label center'>{video.title.length > 40 ? video.title.substring(0, 39) + '...' : video.title}</label>
										</div>
									))}
								</Slider>
								<div className="mb-40"></div>
							</MobileView>
						</Box>
						: ""
				))

			)
		}
	}

	const target = React.useRef(null);
	const isHovering = useHover(target, { enterDelay: 100, leaveDelay: 100 });
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
			{/* MODAL SELECT VIDEO INSIDER SUBJECT CONTENT */}
			<div class="modal-show-all modal-bg " style={{ display: modalShowVideosDisplay }}>
				<div class="subject-modal modal-container dark mt-75 block-center custom-color-dark">
					<a class="close" onClick={(e) => closeModal(e, 'modalShowVideos')}><img className="modal-close" src={assetsUrl.modalCloseBlack} /></a>
					<FadeIn visible={modalShowVideosDisplay === 'block' ? true : false}>
						{selectedVideoId !== "" ? <VideosPerSubjectComponent videoId={selectedVideoId}></VideosPerSubjectComponent> : ''}
					</FadeIn>
				</div>
			</div>



			{/* MODAL SHOW ALL */}
			< div class="modal-show-all modal-bg" style={{ display: modalShowAllDisplay }}>
				<div class="subject-modal modal-container dark mt-75 block-center custom-color-dark">
					<a class="close" onClick={(e) => closeModal(e, 'modalShowAll')}><img className="modal-close" src={assetsUrl.modalClose} /></a>
					<FadeIn visible={modalShowAllDisplay === 'block' ? true : false}>
						{selectedSubjectId !== "" ? <ShowAllVideosPerSubjectComponent subjectId={selectedSubjectId} subjectName={selectedSubjectName}></ShowAllVideosPerSubjectComponent> : ''}
					</FadeIn>
				</div>
			</div>

			<div>
				<BrowserView>
					{/* <div className="mb-82"></div> */}
				</BrowserView>
				<div class="main-container pl-25 pr-25 mt-25 pt-50 gif-background">
					<br /><br />
					{/* RECENTLY WATCHED SECTION */}
					{Object.keys(recentWatched).length === 0 ? "" : (
						<div><div className='subject-header font-16'><p className='subject-text-box'>Recently Watched</p></div>
							<Box sx={{ width: '100%' }} className="pb-20">
								<Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
									<Grid item xs={12} md={12} className='recently-section'>
										<div class="subject-recent-watch">
											<img src={recentWatched.thumbnail} className="subject-watch-cover br-5" />
										</div>
										{/* <button type="button" class="btn btn-create-account btn-recently mb-20 mr-10">Recently watched</button> */}
										<Button variant="outlined" startIcon={<PlayArrow />} className="btn-white" onClick={(e) => handlePlayRecentVid(e, recentWatched._id)}> Play</Button>
									</Grid>
								</Grid>
							</Box>
						</div>
					)}

					{/* LIST OF SUBJECTS WITH VIDEOS */}
					{renderSubjectVideos}
				</div>
			</div>
		</ThemeProvider >
	);
}

export default SubjectLists;