import HomeContextProvider from './homeContext';
import HomeContent from './homeContent';

const Home = () => {
  return (
    <div>
    	<HomeContextProvider>
			<HomeContent />
		</HomeContextProvider>
    </div>
  );
}

export default Home;
