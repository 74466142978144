import SignupContent from './signupContent';
import SignupContextProvider from './signupContext';

const Signup = () => {
  return (
    <div>
      <SignupContextProvider>
        <SignupContent />
      </SignupContextProvider>
    </div>
  );
}

export default Signup;