import { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavbarContext } from './navbarContext';
import '../../assets/css/main-style.css';
import '../../assets/css/modal.css';
import '../../assets/css/pages.css';
import '../../assets/css/spinner.css';
import FadeIn from 'react-fade-in';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import axios from 'axios';
import moment from 'moment';
import { noty } from '../../helpers/noty';
import './styles.css';
import FullScreenLoader from '../../helpers/fullScreenLoader';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useTimer } from 'react-timer-hook';
import {
	ThemeProvider,
	InputLabel,
	CssBaseline,
	FormControl,
	FormHelperText,
	FilledInput,
	IconButton,
	InputAdornment,
	TextField,
	Box,
	Input,
	Autocomplete,
	Select,
	MenuItem,
	AppBar,
	Toolbar,
	Typography,
	Badge,
	FormGroup,
	FormControlLabel,
	Menu,
	Container,
	Button,
	Tooltip,
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Divider
} from '@mui/material';
import {
	Search,
	SearchIcon,
	AccountCircle,
	MailIcon,
	NotificationsIcon,
	MoreIcon,
	SearchIconWrapper
} from '@mui/icons-material';
import AdbIcon from '@mui/icons-material/Adb';

import MenuIcon from '@mui/icons-material/Menu';
import { darkTheme } from '../../helpers/darkTheme';
import MultipleLoginComponent from '../SubjectComponent/sub-components/multipleLogin';
import { isMobile } from 'react-device-detect';
import { List } from 'react-content-loader';
import { assetsUrl } from '../../helpers/assetsUrl';
import trimNotificationTitle from '../../helpers/trim';
import DeactivatedUserComponent from '../SubjectComponent/sub-components/deactivatedUser';

const NavbarComponent = (props) => {
	const context = useContext(NavbarContext)
	const history = useHistory();

	//SideNav Toggle
	const [sideNavMarginLeft, setSideNavMarginLeft] = useState('-275px');

	// About Link toggle
	const [aboutVisibility, setAboutVisibility] = useState(false);
	const [aboutVisibilityDisplay, setAboutVisibilityDisplay] = useState('none');

	// Profile Modal
	const [profileDisplay, setProfileDisplay] = useState('none');
	const [profileFade, setProfileFade] = useState(false);
	const [profileInputDisable, setProfileInputDisable] = useState(true);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [school, setSchool] = useState('');
	const [yearLevel, setYearLevel] = useState('');
	const [profileBirthDate, setProfileBirthDate] = useState('');
	const [formUpdateProfileDisabled, setFormUpdateProfileDisabled] = useState(true);

	// Change Password
	const [changePassDisplay, setChangePassDisplay] = useState('none');
	const [changePassFade, setChangePassFade] = useState(false);
	const [changePassInputDisable, setChangePassInputDisable] = useState(false);

	const [maskOldPasswordStat, setMaskOldPasswordStat] = useState(false);
	const [maskOldPassword, setMaskOldPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);

	const [maskNewPasswordStat, setMaskNewPasswordStat] = useState(false);
	const [maskNewPassword, setMaskNewPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);

	const [maskConfirmNewPasswordStat, setMaskConfirmNewPasswordStat] = useState(false);
	const [maskConfirmNewPassword, setMaskConfirmNewPassword] = useState(`${window.location.origin}/images/icon-showpass.svg`);
	const [changePassword, setChangePassword] = useState({
		oldPassword: '',
		newPassword: '',
		confirmNewPassword: ''
	})

	// Terms and Condition
	const [aboutPrivacyDisplay, setAboutPrivacyDisplay] = useState('none');
	const [aboutPrivacyFade, setAboutPrivacyFade] = useState(false);
	const [aboutActiveTab, setAboutActiveTab] = useState('privacy');

	// Reference
	const [referenceDisplay, setReferenceDisplay] = useState('none');
	const [referenceFade, setReferenceFade] = useState(false);
	const [referencesLists, setReferencesLists] = useState([])
	const [currentReferenceId, setCurrentReferenceId] = useState('')

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const [currentSideNav, setCurrentSideNav] = useState('home')

	// logout
	const [fullScreenLoader, setFullScreenLoader] = useState(false);

	// multiple login
	const [modalOpen, setModalOpen] = useState(false);

	// account deactivated
	const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);


	// notifications
	const [notifications, setNotifications] = useState([]);
	const [notificationVisibility, setNotificationVisibility] = useState(false);

	const [currentPage, setCurrentPage] = useState('/');

	const [activeNav, setActiveNav] = useState('home');



	useEffect(() => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			validateActiveNav();
			const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios
				.get(`${BasePath}/updates`, headers)
				.then(response => {
					setNotifications(response.data.data);
				})
		}
	}, [])

	const validateActiveNav = () => {
		const location = window.location.pathname;

		var pathName = location.split('/')[1];

		setCurrentPage(pathName)
		if (pathName === "") {
			setActiveNav("home")
		} else if (pathName === "references") {
			setActiveNav("references")
		} else if (pathName === "search") {
			setActiveNav("search")
		} else if (pathName === "watch") {
			setActiveNav("watch")
		}
	}

	const MyTimer = ({ expiryTimestamp }) => {
		const {
			seconds,
			minutes,
			hours,
			days,
			isRunning,
			start,
			pause,
			resume,
			restart,
		} = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called'), autoStart: true });


		return (
			<div>
				<div style={{ fontSize: '15px' }}>
					<span>Your free trial will expire in: {days} Days</span>, <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
				</div>
			</div>
		);
	}


	const handleOpenSideNav = () => {
		setSideNavMarginLeft('0px')
	}

	const handleCloseSideNav = () => {
		setSideNavMarginLeft('-275px')
	}

	const handleAbout = () => {
		if (aboutVisibility === true) {
			setAboutVisibilityDisplay('none');
			setAboutVisibility(false)
		} else {
			setAboutVisibility(true)
			setAboutVisibilityDisplay('block');
		}
	}

	const handleProfileModal = () => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")

		if (userDataStorage === null) {
			history.push("/login");
		} else {
			setActiveNav('profile')
			const parseUserDataStorage = JSON.parse(userDataStorage)
			setAnchorElUser(null);
			setCurrentSideNav('profile')
			setProfileDisplay('block');
			setAboutPrivacyDisplay('none');
			setChangePassDisplay('none');
			setProfileFade(true)
			// setTimeLeft(calculateTimeLeft());
			// setYear(new Date().getFullYear());

			setFirstName(parseUserDataStorage.data.user.firstName)
			setLastName(parseUserDataStorage.data.user.lastName)
			setEmail(parseUserDataStorage.data.user.email)
			setSchool(parseUserDataStorage.data.user.school)
			setYearLevel(parseUserDataStorage.data.user.yearLevel)
			setProfileBirthDate(moment(parseUserDataStorage.data.user.birthDate).format('YYYY-MM-DD'))
		}
	}

	const calculateTimeLeft = () => {
		let year = new Date().getFullYear();
		const difference = +new Date(`10/01/${year}`) - +new Date();

		let timeLeft = {};
		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			};
		}
		return timeLeft;
	};
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	const handleCloseProfileModal = () => {
		validateActiveNav();
		setProfileDisplay('none')
		setCurrentSideNav('home')
		setProfileFade(false)
	}

	const handleDisable = (state) => {
		if (state === 'Edit') {
			setProfileInputDisable(false)
		} else {
			setProfileInputDisable(true)
			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			if (userDataStorage === null) {
				// history.push("/login");
			} else {
				const parseUserDataStorage = JSON.parse(userDataStorage)
				const headers = {
					headers: {
						'x-client-id': pinnacleStreamingV2ClientId,
						'x-client-secret': pinnacleStreamingV2ClientSecret,
						'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
					}
				}
				axios
					.put(`${BasePath}/v1/users/profile/update`, context.userProfile, headers)
					.then(response => {
						const res = response.data;
						parseUserDataStorage.data.user.firstName = res.data.firstName
						parseUserDataStorage.data.user.lastName = res.data.lastName
						parseUserDataStorage.data.user.birthDate = res.data.birthDate
						parseUserDataStorage.data.user.gender = res.data.gender
						parseUserDataStorage.data.user.school = res.data.school
						localStorage.clear();
						localStorage.setItem(
							'pinnacleStreamingV2WebApp',
							JSON.stringify(parseUserDataStorage)
						);
					})
			}
		}
	}

	const handleChangePasswordModal = () => {
		setActiveNav('change-password');
		setProfileDisplay('none');
		setAboutPrivacyDisplay('none');
		setChangePassDisplay('block');
		setChangePassFade(true)
		setCurrentSideNav('change-password');
		setAnchorElUser(null);
	}

	const handleCloseChangePassModal = () => {
		validateActiveNav();
		setChangePassDisplay('none')
		setCurrentSideNav('home')
		setChangePassFade(false)
	}

	const clearChangePassForm = (e) => {
		setChangePassword({
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: ''
		})
	}

	const handleChangePassword = (e) => {
		e.preventDefault();
		if (changePassword.newPassword === changePassword.confirmNewPassword) {
			const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
			if (userDataStorage === null) {
				history.push("/login");
			} else {
				setFullScreenLoader(true);
				setChangePassInputDisable(true);
				const parseUserDataStorage = JSON.parse(userDataStorage)
				const headerUser = {
					headers: {
						'x-client-id': pinnacleStreamingV2ClientId,
						'x-client-secret': pinnacleStreamingV2ClientSecret,
						'token': parseUserDataStorage.data.token
					}
				}

				axios
					.put(`${BasePath}/auth/password`, { oldPassword: changePassword.oldPassword, newPassword: changePassword.newPassword }, headerUser)
					.then(response => {
						setChangePassInputDisable(false)
						setFullScreenLoader(false);
						clearChangePassForm()
						handleCloseChangePassModal()
						noty("success", `<i class = "fa fa-info-circle"></i> Successfully changed password`);
					})
					.catch(error => {
						setChangePassInputDisable(false)
						setFullScreenLoader(false);
						if (error.response) {
							if (error.response.status === 400) {
								noty("error", `<i class = "fa fa-info-circle"></i> Invalid request. Please make sure you enter valid password`);
							}
						} else {
							noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
						}
					})
			}
		} else {
			noty("error", `<i class = "fa fa-info-circle"></i> New password and confirm new password must match`);
		}
	}

	const handleOldMaskPassword = (status) => {
		if (status === true) {
			setMaskOldPasswordStat(false)
			setMaskOldPassword(`${window.location.origin}/images/icon-showpass.svg`)
		} else {
			setMaskOldPasswordStat(true)
			setMaskOldPassword(`${window.location.origin}/images/icon-hidepass.svg`)
		}
	}

	const handleNewMaskPassword = (status) => {
		if (status === true) {
			setMaskNewPasswordStat(false)
			setMaskNewPassword(`${window.location.origin}/images/icon-showpass.svg`)
		} else {
			setMaskNewPasswordStat(true)
			setMaskNewPassword(`${window.location.origin}/images/icon-hidepass.svg`)
		}
	}

	const handleConfirmNewMaskPassword = (status) => {
		if (status === true) {
			setMaskConfirmNewPasswordStat(false)
			setMaskConfirmNewPassword(`${window.location.origin}/images/icon-showpass.svg`)
		} else {
			setMaskConfirmNewPasswordStat(true)
			setMaskConfirmNewPassword(`${window.location.origin}/images/icon-hidepass.svg`)
		}
	}

	const onChangePassword = (e) => {
		setChangePassword({
			...changePassword,
			[e.target.id]: e.target.value,
		});
	}

	const handleAboutPrivacyModal = () => {
		setActiveNav('terms')
		setAboutPrivacyDisplay('block')
		setProfileDisplay('none');
		setChangePassDisplay('none');
		setCurrentSideNav('privacy')
		setAboutPrivacyFade(true);
		setAnchorElNav(null);
		setAnchorElUser(null);
	}

	const handleCloseAboutPrivacyModal = () => {
		validateActiveNav();
		setAboutPrivacyDisplay('none')
		setCurrentSideNav('home')
		setAboutPrivacyFade(false)
	}

	const handleReferenceModal = () => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			// history.push("/login");
		} else {
			setReferenceDisplay('block')
			setCurrentSideNav('reference')
			setReferenceFade(true)
			const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios
				.get(`${BasePath}/v1/news`, headers)
				.then(response => {
					const news = response.data
					setReferencesLists(news.data)

				})

		}
	}

	const handleCloseReferenceModal = () => {
		validateActiveNav();
		setReferenceDisplay('none')
		setCurrentSideNav('home')
		setReferenceFade(false)
		setCurrentReferenceId('')
	}

	const handleViewNews = (e, refId) => {
		setCurrentReferenceId(refId)
	}


	const handleLogout = () => {
		setAnchorElUser(null);
		setFullScreenLoader(true);
		setCurrentSideNav('logout');
		localStorage.removeItem("pinnacleStreamingV2WebApp");
		setTimeout(() => {
			setFullScreenLoader(false)
			noty("success", `<i class = "fa fa-info-circle"></i> Logout successful`);
			history.push('/login')
		}, 500);
	}


	const handleOnSearch = (string, results) => {
		context.onChangeSchool(string)
	}

	const handleOnHover = (result) => {
		context.onChangeSchool(result.name)
	}

	const handleOnSelect = (item) => {
		// the item selected
		context.onChangeSchool(item.name)
	}

	const handleOnFocus = () => {
	}

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [anchorElNotif, setAnchorElNotif] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		// if (
		// 	profileDisplay === "none"
		// 	referenceDisplay === "none" ||
		// 	changePassDisplay === "none" ||
		// 	aboutPrivacyDisplay === "none"
		// ) {
		// }
		setAnchorElUser(event.currentTarget);
		setNotificationVisibility(false);
	};

	const handleOpenNotifMenu = (event) => {
		setNotificationVisibility(!notificationVisibility);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleCloseNotifMenu = () => {
		setAnchorElNotif(null);
	};

	const handleSubmitProfile = (e) => {
		e.preventDefault();
		setFullScreenLoader(true);
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = JSON.parse(userDataStorage)
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			const updateForm = {
				firstName,
				lastName,
				birthDate: profileBirthDate,
				school,
				yearLevel,
			}
			axios
				.put(`${BasePath}/users`, updateForm, headers)
				.then(response => {
					const res = response.data;
					parseUserDataStorage.data.user.firstName = res.data.firstName
					parseUserDataStorage.data.user.lastName = res.data.lastName
					parseUserDataStorage.data.user.birthDate = res.data.birthDate
					parseUserDataStorage.data.user.gender = res.data.gender
					parseUserDataStorage.data.user.school = res.data.school
					parseUserDataStorage.data.user.yearLevel = res.data.yearLevel
					localStorage.removeItem("pinnacleStreamingV2WebApp");
					localStorage.setItem(
						'pinnacleStreamingV2WebApp',
						JSON.stringify(parseUserDataStorage)
					);
					setFormUpdateProfileDisabled(true);
					handleCloseProfileModal();
					setFullScreenLoader(false)

					noty("success", `<i class = "fa fa-info-circle"></i> Successfully updated profile`);
				})
				.catch(error => {
					if (error.response) {
						if (error.response.status === 401) {
							if (error.response.data.message === "Token Expired") {
								setModalOpen(true);
							} else {
								setModalOpenDeactivate(true);
							}

							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
						if (error.response.status === 500) {
							setModalOpen(true);
							setTimeout(() => {
								history.push("/login");
							}, 3000);
						}
					} else if (error.request) {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					} else {
						noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
					}
				})
		}
	}

	const handleEditProfile = (e) => {
		e.preventDefault();
		setFormUpdateProfileDisabled(false);
	}

	const handleTaD = (e, content) => {
		setAboutActiveTab(content);
		setAnchorElUser(null);
	}

	const handlePage = (page) => {
		if (document.pictureInPictureElement) {
			location.replace(page)
		} else {
			if (page === '/references') {
				setActiveNav('references')
			}
			history.push({
				pathname: page
			})
		}
	}

	const [modalReferenceDisplay, setModalReferenceDisplay] = useState('none');
	const [selectedReferenceId, setSelectedReferenceId] = useState({});

	const handleRedirectNotif = (e, notif) => {
		if (notif.type === "VIDEO") {
			history.push(`/watch/${notif.resourceId}`);
		} else if (notif.type === "REFERENCE") {
			setModalReferenceDisplay('block');
			handleFetchReference(notif.resourceId)
		}
	}

	const handleFetchReference = (refId) => {
		const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
		if (userDataStorage === null) {
			history.push("/login");
		} else {
			const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
			const headers = {
				headers: {
					'x-client-id': pinnacleStreamingV2ClientId,
					'x-client-secret': pinnacleStreamingV2ClientSecret,
					'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
				}
			}
			axios
				.get(`${BasePath}/references/${refId}`, headers)
				.then(response => {
					setSelectedReferenceId(response.data.data);
				})
		}
	}
	const closeModal = (e, content) => {
		e.preventDefault();
		if (content === 'modalShowReference') {
			setModalReferenceDisplay('none');
			setSelectedReferenceId({});
		}
	}

	const time = new Date(context.userProfile.expiresAt);
	time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div>
				<MultipleLoginComponent open={modalOpen} setOpen={setModalOpen} />
				<DeactivatedUserComponent open={modalOpenDeactivate} setOpen={setModalOpenDeactivate} />

				<FullScreenLoader loading={fullScreenLoader} />

				<FadeIn visible={notificationVisibility}>
					<div className="user-notification-list-content" style={{ display: notificationVisibility ? 'block' : 'none' }}>
						{notifications.length > 0 ?
							notifications.map(notif => {
								return (
									<div className="user-notification-parent">
										<div className="user-notification-list-main pl-10 pb-10 pr-10 pt-10">
											<div className="user-notification-list-item cursor-pointer">

												<Grid container rowSpacing={1}>
													<Grid item>
														<div className="parent-notif-thumb">
															<img
																src={notif.thumbnail}
																className="user-notif-thumbnail"
																onClick={(e) => handleRedirectNotif(e, notif)}
															/>
														</div>
													</Grid>
													<Grid item className="user-notif-text-position">
														<Tooltip title={trimNotificationTitle(notif.description)} >
															<label className="user-notif-text"> &nbsp; {trimNotificationTitle(notif.description).length > 21 ? trimNotificationTitle(notif.description).substring(0, 20) + '...' : trimNotificationTitle(notif.description)}</label>
														</Tooltip>
													</Grid>
												</Grid>

											</div>
										</div>
										<hr className="notification-border" />
									</div>
								)
							})
							:
							""
						}
					</div>
				</FadeIn>

				<AppBar position="fixed" className="app-bar-custom">
					<Container maxWidth="xl">
						<Toolbar disableGutters>
							<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }} className="nav-link">
								<div class="topnav">
									<a class={activeNav === "home" ? "custom-nav-link nav-link-button active" : "custom-nav-link nav-link-button"} onClick={() => handlePage('/')}>Home</a>
								</div>
								<div class="topnav">
									<a class={activeNav === "references" ? "custom-nav-link nav-link-button active" : "custom-nav-link nav-link-button"} onClick={() => handlePage('/references')}>References</a>
								</div>
								{/* <Button
									key={Math.random()}
									sx={{ my: 2, color: 'white', display: 'block' }}
									className={currentPage === "/" ? "custom-nav-link-active nav-link-button" : "custom-nav-link nav-link-button"}
									onClick={() => handlePage('/')}
								>
									<b>Home</b>
								</Button>
								<Button
									key={Math.random()}
									sx={{ my: 2, color: 'white', display: 'block' }}
									className={currentPage === "/references" ? "custom-nav-link-active nav-link-button" : "custom-nav-link nav-link-button"}
									onClick={() => handlePage('/references')}
								>
									<b>References</b>
								</Button> */}
							</Box>

							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Search Videos">
									<label onClick={() => handlePage('/search')}>
										<img src={currentPage === 'search' ? assetsUrl.searchActive : assetsUrl.search} className="appbar-profile-menu" />
									</label>
								</Tooltip>
							</Box>

							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="">
									<img src={notificationVisibility ? assetsUrl.notificationActive : assetsUrl.notification} className="appbar-profile-menu" onClick={handleOpenNotifMenu} />
								</Tooltip>
							</Box>

							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Open settings">
									<img src={anchorElUser === null ? assetsUrl.profile : assetsUrl.profileActive} className="appbar-profile-menu" onClick={handleOpenUserMenu} />
								</Tooltip>
								<Menu
									sx={{ mt: '45px' }}
									id="menu-appbar"
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									<MenuItem key={Math.random()} onClick={handleProfileModal} className="">
										<Typography textAlign="center" className={profileDisplay === "block" ? "navbar-parent-item navbar-custom-font nav-is-active" : "navbar-parent-item navbar-custom-font"}>
											Profile
										</Typography>
									</MenuItem>
									<MenuItem key={Math.random()} onClick={handleChangePasswordModal}>
										<Typography textAlign="center" className={changePassDisplay === "block" ? "navbar-custom-font nav-is-active navbar-parent-item" : "navbar-parent-item navbar-custom-font"}>Change Password</Typography>
									</MenuItem>
									<MenuItem key={Math.random()} onClick={handleAboutPrivacyModal}>
										<Typography textAlign="center" className={aboutPrivacyDisplay === "block" ? "navbar-custom-font nav-is-active navbar-parent-item" : "navbar-parent-item navbar-custom-font"}>Terms and Services</Typography>
									</MenuItem>
									<MenuItem key={Math.random()} onClick={handleLogout}>
										<Typography textAlign="center" className="navbar-custom-font navbar-parent-item">Logout</Typography>
									</MenuItem>
								</Menu>
							</Box>

						</Toolbar>
					</Container>
				</AppBar>


				<div class="profile-modal-bg" style={{ display: profileDisplay }}>
					<div class="profile-modal modal-container dark custom-color-dark mt-75 block-center pt-40 pb-50 pl-35 pr-35">
						<FadeIn visible={profileFade}>
							<span class="close" onClick={handleCloseProfileModal}><img src={assetsUrl.iconClose} /></span>
							<div style={{ display: 'block' }}>
								<h4 class="main-header mb-25 center text-white">Profile</h4>
								<form autoComplete="off" onSubmit={(e) => handleSubmitProfile(e)} >
									<Box sx={{ width: '100%' }}>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 6, md: 6 }}>
											<Grid item xs={12} md={6}>
												<label>First Name</label>
												<div class="form-group-w-label">
													<input
														type="text"
														class="input-form mb-20 mt-5"
														placeholder="First Name"
														disabled={formUpdateProfileDisabled}
														value={firstName}
														onChange={(e) => setFirstName(e.target.value)}
														required
													/>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<label>Last Name</label>
												<div class="form-group-w-label">
													<input
														type="text"
														class="input-form mb-20 mt-5"
														placeholder="Last Name"
														disabled={formUpdateProfileDisabled}
														value={lastName}
														onChange={(e) => setLastName(e.target.value)}
														required
													/>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<label>Birthdate</label>
												<div class="form-group">
													<input
														type="date"
														class="input-form mb-20 mt-5"
														placeholder="Birthdate"
														disabled={formUpdateProfileDisabled}
														value={profileBirthDate}
														onChange={(e) => setProfileBirthDate(e.target.value)}
														required
													/>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<label>Email</label>
												<div class="form-group">
													<input
														type="email"
														class="input-form mb-20 mt-5"
														placeholder="Email"
														disabled={true}
														value={email}
														onChange={(e) => setEmail(e.target.value)}
														required
													/>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<label>School</label>
												<div class="form-group">
													<input
														type="text"
														class="input-form mb-20 mt-5"
														placeholder="School"
														disabled={formUpdateProfileDisabled}
														value={school}
														onChange={(e) => setSchool(e.target.value)}
														required
													/>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<label>Year Level</label>
												<div class="form-group">
													<input
														type="number"
														class="input-form mb-20 mt-5"
														placeholder="Year Level"
														disabled={formUpdateProfileDisabled}
														name="yearLevel"
														value={yearLevel}
														onChange={(e) => setYearLevel(e.target.value)}
														required
														min="1960"
													/>
												</div>
											</Grid>
										</Grid>
										<div className="center">
											{formUpdateProfileDisabled ?
												<button type="button" class="btn btn-submit-signup mb-20 mt-20" onClick={(e) => handleEditProfile(e)}>Edit</button>
												:
												<button type="submit" class="btn btn-submit-signup mb-20 mt-20">Submit</button>
											}
										</div>
									</Box>
								</form>
							</div>
						</FadeIn>
					</div>
				</div>

				<div class="about-modal-bg" style={{ display: referenceDisplay }}>
					<div class="about-modal modal-privacy modal-container mt-75 block-center pt-25 pb-25 pl-25">
						<span class="close" onClick={handleCloseReferenceModal}><img src={assetsUrl.iconClose} /></span>
						<FadeIn visible={referenceFade}>
							<h4 class="f-bold text-red main-header mb-25">References</h4>
							<div class="details-container-scrollable details-container-reference-scrollable">
								<div class="details-container pr-15">
									{referencesLists.length > 0 ?
										<div className="details-container pr-15">
											{referencesLists.map(ref => {
												return (
													<div>

														<div class={ref._id === currentReferenceId ? "header-w-bg pt-10 pb-10 mb-15 pointer reference-list-active text-white text-left" : "header-w-bg pt-10 pb-10 mb-15 pointer text-left"} onClick={(e) => handleViewNews(e, ref._id)}>
															<h4 class={ref._id === currentReferenceId ? "f-bold text-white-primary" : "f-bold text-black-primary"}> &nbsp; {ref.title}</h4>
														</div>
														{ref._id === currentReferenceId ?
															<FadeIn visible={true}>
																<iframe
																	src={ref.resource}
																	width="100%"
																	height="100%" className="iframe-ref">
																</iframe>
															</FadeIn>
															: null}
													</div>
												)
											})}
										</div>
										: null}
								</div>
							</div>
						</FadeIn>
					</div>
				</div>

				<div class="profile-modal-bg" style={{ display: changePassDisplay }}>
					<div class="profile-modal modal-container custom-color-dark dark mt-75 block-center pt-40 pb-50 pl-145 pr-145 profile-modal-bg-width">
						<FadeIn visible={changePassFade}>
							<span class="close" onClick={handleCloseChangePassModal}><img src={assetsUrl.iconClose} /></span>
							<h3 class="f-regular mb-25 text-white center change-password-text">Change Password</h3>
							<form onSubmit={handleChangePassword}>
								<div class="form-group center">
									<span className="show-pw pw" alt="pw" onClick={() => handleOldMaskPassword(maskOldPasswordStat)} ><img src={maskOldPassword} /></span>
									<input type={maskOldPasswordStat === true ? 'text' : 'password'} class="input-form mb-20 input-pw border-rad-5 text-width-50" placeholder="Old password" disabled={changePassInputDisable} value={changePassword.oldPassword} id="oldPassword" onChange={onChangePassword} />
								</div>
								<div class="form-group center">
									<span className="show-pw pw" alt="pw" onClick={() => handleNewMaskPassword(maskNewPasswordStat)} ><img src={maskNewPassword} /></span>
									<input type={maskNewPasswordStat === true ? 'text' : 'password'} class="input-form mb-20 input-pw border-rad-5 text-width-50" placeholder="New password" disabled={changePassInputDisable} value={changePassword.newPassword} id="newPassword" onChange={onChangePassword} />
								</div>
								<div class="form-group center">
									<span className="show-pw pw" alt="pw" onClick={() => handleConfirmNewMaskPassword(maskConfirmNewPasswordStat)} ><img src={maskConfirmNewPassword} /></span>
									<input type={maskConfirmNewPasswordStat === true ? 'text' : 'password'} class="input-form mb-20 input-pw border-rad-5 text-width-50" placeholder="Confirm new password" disabled={changePassInputDisable} value={changePassword.confirmNewPassword} id="confirmNewPassword" onChange={onChangePassword} />
								</div>

								<div align="center">
									<button class="btn btn-edit-profile" type="submit">Update</button>
								</div>
							</form>
						</FadeIn>
					</div>
				</div>

				<div class="about-modal-bg" style={{ display: aboutPrivacyDisplay }}>
					<div class="custom-border-tad modal-privacy modal-container custom-color-dark mt-75 block-center pt-25 pb-25 pl-25" style={{ border: '0' }}>
						<FadeIn visible={aboutPrivacyFade}>
							<span class="close" onClick={handleCloseAboutPrivacyModal}><img src={assetsUrl.iconClose} /></span>


							<Box sx={{ width: '100%' }}>
								<Grid container className="center"
									spacing={0}
									direction={isMobile ? "column" : "row"}
									alignItems="center"
									justify="center">
									<Grid item xs={6} md={6}>
										<h4 class="f-bold text-red main-header mb-25">
											<span className={aboutActiveTab === "privacy" ? "tad-custom-style about-active-tab" : "tad-custom-style"} onClick={(e) => handleTaD(e, "privacy")}>Privacy Policy</span>
										</h4>
									</Grid>
									<Grid item xs={6} md={6}>
										<h4 class="f-bold text-red main-header mb-25">
											<span className={aboutActiveTab === "t&c" ? "tad-custom-style about-active-tab" : "tad-custom-style"} onClick={(e) => handleTaD(e, "t&c")}>Terms and Services</span>
										</h4>
									</Grid>
								</Grid>
							</Box>

							<div class="details-container-scrollable">
								<div class="details-container pr-15">
									<div style={{ display: aboutActiveTab === "privacy" ? 'block' : 'none' }} className="about-text-color">
										<p>This is the Privacy Policy of Pinnacle Online and all content and services that we provide. The website is owned and operated by the Pinnacle Online Team.</p>
										<p>&nbsp;</p>
										<p>The Pinnacle Online Team values the security of any data that our Customers provide. Our Privacy Policy, which belongs under our Terms of Use, explains the kind of information we collect, how we use and protect it, how to access and update existing information, and how to manage the privacy settings whenever the Customer uses our website.</p>
										<p>&nbsp;</p>
										<p>Pinnacle Online Team is committed to comply with Republic Act. No. 10173 or the Data Privacy Act of 2012, and its implementing rules and regulations.</p>
										<p>&nbsp;</p>
										<p><strong>Collection and Use of Information</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We collect different types of information to deliver and improve our website services to the user. While using our website, we may ask the user to provide us with certain personally identifiable information that can be used to contact or identify the user (&ldquo;Personal Data&rdquo;).</p>
										<p>&nbsp;</p>
										<p>Personally identifiable information may include, but is not limited to:</p>
										<ul>
											<li>First and last name</li>
											<li>E-mail address</li>
											<li>School</li>
											<li>Mobile number</li>
											<li>Address</li>
											<li>Course/Program</li>
										</ul>
										<p><strong>&nbsp;</strong></p>
										<p><strong>What do we do with the user&rsquo;s personal information?</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>By providing us with Information, you authorize and consent to our collection, use and processing of your personal information for the following purposes:</p>
										<p>&nbsp;</p>
										<ul>
											<li>Provide and communicate with you about our website services and other updates;</li>
											<li>Fulfill your requests regarding the website including, without limitation, send e-mails, text messages and other notifications;</li>
											<li>Respond to your concerns and inquiries;</li>
											<li>Communicate with you about other events, announcements, new launches, updates, offers, programs, or other services that we believe may be of interest to you;</li>
											<li>Enforce the legal terms that govern your use of our website and/or for the purposes for which you provided the Information</li>
											<li>Provide technical support for the website;</li>
											<li>Prevent fraud or potentially illegal activities including, without limitation, copyright infringement on or through the platform;</li>
											<li>Protect the safety of our users;</li>
											<li>Perform analysis regarding how you use the website or any part thereof;</li>
											<li>Comply with statutory and regulatory requirements to any competent authority, regulator, supervisory body, enforcement agency, exchange, court, quasi-judicial body, or tribunal</li>
											<li>Establish, exercise, or defend legal claims;</li>
											<li>Fulfill any other purposes directly related to the above-stated purposes</li>
										</ul>
										<p>&nbsp;</p>
										<p><strong>Who is the Personal Information Controller?</strong></p>
										<p>&nbsp;</p>
										<p>Pinnacle Online is the Personal Information Controller under the Data Privacy Act of 2012 (hereafter, &ldquo;DPA&rdquo;), which means that it determines what purposes personal information held will be used for. It may also be that your personal information is disclosed to third parties under a data sharing agreement, in which case, such third parties are also the personal information controllers.</p>
										<p>&nbsp;</p>
										<p><strong>What does Pinnacle Online do with your Information?</strong></p>
										<p>&nbsp;</p>
										<p>We collect, record, organize, store, update/modify, retrieve, consult, use, or consolidate your Information pursuant to the purposes above.</p>
										<p>&nbsp;</p>
										<p><strong>Will Pinnacle Online share your Information?</strong></p>
										<p>&nbsp;</p>
										<p>Your Information may be disclosed to third parties to enable Pinnacle Online to achieve the purposes set out above, including but not limited to the following:</p>
										<p>&nbsp;</p>
										<p>With service providers that we have engaged to perform business-related functions on our behalf:</p>
										<p>&nbsp;</p>
										<ul>
											<li>Conduct research and analytics;</li>
											<li>Provide user, technical or operational support;</li>
											<li>Conduct or support marketing, such as emails and advertising platforms</li>
											<li>Respond to queries and other user requests;</li>
											<li>Host our services and maintain databases;</li>
											<li>Investigate, prevent, or take action, in our sole discretion, regarding potentially illegal activities, suspected fraud, situations involving potential threats to any person, us, or the services, or violations of our policies, or the law;</li>
											<li>In response to legal process, such as in response to a court order or a subpoena, a law enforcement or government agency&rsquo;s request or similar request;</li>
										</ul>
										<ul>
											<li>With third parties, in order to investigate, prevent, or take action, in our sole discretion, regarding potentially illegal activities, suspected fraud, situations involving potential threats to any person, us, or the website services, or violations of our policies, the law or our Terms of Use, to verify or enforce compliance with the policies governing our website services;</li>
											<li>As a requirement of business transfer, if we, or one of our business units, undergo a business transaction, like a merger, acquisition by another company, or sale of all or part of our assets, or if a substantial portion of our or of a business unit&rsquo;s assets is sold or merged in this way;</li>
											<li>Carry out lawful business activities;</li>
											<li>Comply with statutory requirements;</li>
											<li>Carry out digitization and storage processes;</li>
											<li>Carry out data collection and analysis;</li>
											<li>Conduct company audits or investigate a complaint or security threat;</li>
											<li>Establish, exercise, or defend legal claims.</li>
										</ul>
										<p>&nbsp;</p>
										<p>When the processing of Information is outsourced to a third party, the processing will be subject to written agreements between us and the third parties processing the data, in accordance with the requirements of the DPA. These written agreements specify the rights and obligations of each party and will provide that the third party has adequate security measures in place and will only process your Information on our specific written instructions. &nbsp;</p>
										<p>&nbsp;</p>
										<p>We may also transfer your Information to third parties as required by law or legal instrument, to protect our rights or assets, to facilitate the acquisition or disposition of our businesses, and in emergencies where the health or safety of a person is endangered.</p>
										<p>&nbsp;</p>
										<p>We will not sell, rent, share, trade, or disclose any of your Information to any other party without your prior written consent, with the exception of entities within Pinnacle Online and any third-party service provider, such as IT consultants and website developers, which we have engaged whose services necessarily require the processing of your Information. Pinnacle Online shall ensure, using contractual and other reasonable means, that the third-party service provider implements proper safeguards to ensure the confidentiality, integrity and availability of the personal data processed, prevent its use for unauthorized purposes, and comply with the requirements of the DPA, its Implementing Rules and Regulations, and other applicable laws for processing of personal data, and other issuances of the National Privacy Commission.</p>
										<p>&nbsp;</p>
										<p><strong>How does Pinnacle Online store your data?</strong></p>
										<p>&nbsp;</p>
										<p>Electronic data are stored in a secure online storage platform, online drive, and on the local drives of the review employees and officers, while physical files containing personal information are kept on premise in secure cabinets within a locked room, to which only employees have access.</p>
										<p>&nbsp;</p>
										<p><strong>How long will Pinnacle Online retain your Information?</strong></p>
										<p>&nbsp;</p>
										<p>You agree that the above-mentioned Information will be retained or stored for as long as the purposes for which they are being process have not been satisfied. Pinnacle Online will retain and use your Information as necessary to comply with its legal obligations, resolve disputes, and enforce its agreements.</p>
										<p>&nbsp;</p>
										<p><strong>Are you allowed to access and update your Information?</strong></p>
										<p>&nbsp;</p>
										<p>Pinnacle Online encourages all its users to keep their Information current and updated. You may email us to request your Information updated. When you request for access to your Information, we will take reasonable steps to confirm your identity before granting you access and updating your Information.</p>
										<p>&nbsp;</p>
										<p><strong>How does Pinnacle Online protect your Information?</strong></p>
										<p>&nbsp;</p>
										<p>We have put in place physical, electronic, and managerial procedures designed to help prevent unauthorized access, to maintain data security, and to use correctly the Information we collect online. These safeguards vary based on the sensitivity of the Information that we collect and store.</p>
										<p>&nbsp;</p>
										<p><strong>What are your rights as a data subject?</strong></p>
										<p>&nbsp;</p>
										<p>You, as the data subject, have rights under the Data Privacy Act, which include the following:</p>
										<p><strong>&nbsp;</strong></p>
										<ul>
											<li>The right to access his/her personal information;</li>
											<li>The right to make corrections to his/her personal information;</li>
											<li>The right to object to the processing of his/her personal information;</li>
											<li>The right to erasure or blocking of his/her personal information;</li>
											<li>The right to be informed of the existence of processing of his/her personal information;</li>
											<li>The right to damages;</li>
											<li>The right to lodge a complaint before the National Privacy Commission.</li>
										</ul>
										<p>&nbsp;</p>
										<p><strong>Contact Us</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>If you have any complaints, concerns, or questions regarding the processing of your Information, please contact:</p>
										<p><strong>&nbsp;</strong></p>
										<p><strong>Email:</strong> <a href="mailto:pinnaclecpareviewschool@gmail.com" className="about-email-text">pinnaclecpareviewschool@gmail.com</a></p>
										<p><em>&nbsp;</em></p>
										<p>&nbsp;</p>
									</div>

									<div style={{ display: aboutActiveTab === "t&c" ? 'block' : 'none' }} className="about-text-color">
										<p>These Terms of Services constitute a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;you&rdquo;) and Pinnacle Online Team (&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the Pinnacle Online (&ldquo;website&rdquo;).</p>
										<p>&nbsp;</p>
										<p>You agree that by accessing the &ldquo;website&rdquo;, you have read, understood, and agree to be bound by all of these Terms of Services. If you do not agree with all of these Terms of Services, then you are expressly prohibited from using the &ldquo;website&rdquo; and you must discontinue your registration immediately.</p>
										<p>&nbsp;</p>
										<p>Supplemental terms and conditions or documents that may be added from time to time and we reserve the right, in our sole discretion, to make changes or modifications to these Terms of Services at any time and for any reason.</p>
										<p>&nbsp;</p>
										<p>We will alert you about any changes by sending you an email notification containing the updates on Terms of Services. It is your responsibility to periodically review these Terms of Services to stay informed of updates. You will be subject to, and will be deemed to, have been made aware of and to have accepted the changes in any revised Terms of Services by your continued use of the &ldquo;website&rdquo; after the email notification of such revised Terms of Services have been sent.</p>
										<p>&nbsp;</p>
										<p>The information provided on the &ldquo;website&rdquo; is not intended for distribution to or use by any person or entity outside the jurisdiction of Pinnacle Online or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement.</p>
										<p>&nbsp;</p>
										<p>Please read these Terms of Services carefully before using the Pinnacle Online operated by the Pinnacle Online Team ("us", "we", or "our"). Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all users and others who wish to access or use the website. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the website.</p>
										<p>&nbsp;</p>
										<p><strong>Intellectual property rights</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>Unless otherwise indicated, Pinnacle Online Team has all the proprietary rights to Pinnacle Online and all its source codes, databases, functionalities, software, designs, audios, videos, texts, photographs, and graphics on the &ldquo;website&rdquo; (collectively, the &ldquo;Content&rdquo;) are owned or controlled by Pinnacle Online Team or licensed to us, and are protected by copyright and laws and various other intellectual property rights and unfair competition laws of the Republic of the Philippines.</p>
										<p>&nbsp;</p>
										<p>Except as expressly provided in these Terms of Services, no part of the &ldquo;website&rdquo; and no &ldquo;content&rdquo; may be copied, printed, downloaded, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
										<p>&nbsp;</p>
										<p>Provided that you are eligible to use the &ldquo;website&rdquo;, you are granted a limited access to use the &ldquo;website&rdquo; and its content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the &ldquo;website&rdquo;.</p>
										<p>&nbsp;</p>
										<p><strong>User representations</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>By using Pinnacle Online, you represent and warrant that:</p>
										<p><strong>&nbsp;</strong></p>
										<ul>
											<li>You agree to comply with these Terms of Services.</li>
											<li>All registration information you submit will be true, accurate, current, and complete. You will also maintain the accuracy of such information and promptly update such registration information as necessary.</li>
											<li>You will not access Pinnacle Online through automated or non-human means; whether through a bot, script, or otherwise.</li>
											<li>You will not use the &ldquo;website&rdquo; for any illegal or unauthorized purposes that will violate any applicable law or regulation.</li>
											<li>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of Pinnacle Online (or any portion thereof).</li>
										</ul>
										<p><strong>&nbsp;</strong></p>
										<p>You may be required to register to fully access the &ldquo;website&rsquo;s&rdquo; functionality. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
										<p>&nbsp;</p>
										<p><strong>Prohibited activities</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>You may not access or use the &ldquo;website&rdquo; for any purpose other than that for which we make the &ldquo;website&rdquo; available. The &ldquo;website&rdquo; may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
										<p>&nbsp;</p>
										<p>As a user of the &ldquo;website&rdquo;, you agree not to:</p>
										<p><strong>&nbsp;</strong></p>
										<ul>
											<li>Systematically retrieve data or other content from the &ldquo;website&rdquo; to create or compile, directly or indirectly create a collection, compilation, database, or directory without written permission from us.</li>
											<li>Make any unauthorized use of the &ldquo;website&rdquo;, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
											<li>Circumvent, disable, or otherwise interfere with security-related features of the &ldquo;website&rdquo;, including features that prevent or restrict the use or copying of any &ldquo;content&rdquo; or enforce limitations on the use of the &ldquo;website&rdquo; and/or the &ldquo;content&rdquo; therein.</li>
											<li>Engage in unauthorized framing of or linking to the &ldquo;website&rdquo;.</li>
											<li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
											<li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
											<li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
											<li>Interfere with, disrupt, or create an undue burden on the &ldquo;website&rdquo; or the networks or services connected to the &ldquo;website&rdquo;.</li>
											<li>Attempt to impersonate another user or person or use the username of another user.</li>
											<li>Sell or otherwise transfer your profile.</li>
											<li>Use any information obtained from the &ldquo;website&rdquo; in order to harass, abuse, or harm another person.</li>
											<li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the &ldquo;website&rdquo;.</li>
											<li>Attempt to bypass any measures of the &ldquo;website&rdquo; designed to prevent or restrict access to the &ldquo;website&rdquo;, or any portion of the &ldquo;website&rdquo;.</li>
											<li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the &ldquo;website&rdquo; to you.</li>
											<li>Delete the copyright or other proprietary rights notice from any &ldquo;website&rdquo;.</li>
											<li>Copy or adapt the &ldquo;website&rsquo;s&rdquo; software, including but not limited to Flash, PHP, HTML, JavaScript, or other codes.</li>
											<li>Upload or transmit (or attempt to transmit) viruses or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the &ldquo;website&rdquo; or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operations, or maintenance of the &ldquo;website&rdquo;.</li>
											<li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear Graphics Interchange Formats (GIFs), 1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).</li>
											<li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the website, or using or launching any.</li>
											<li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the &ldquo;website&rdquo;.</li>
											<li>Use the &ldquo;website&rdquo; in a manner inconsistent with any applicable laws or regulations.</li>
										</ul>
										<p>&nbsp;</p>
										<p><strong>User generated contributions</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>The &ldquo;website&rdquo; may invite you to chat or leave comments in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials, including but not limited to texts, writings, videos, audios, photographs, graphics, comments, suggestions, or personal information or other materials (collectively, &ldquo;Contributions&rdquo;). &ldquo;Contributions&rdquo; may be viewable by other users of the &ldquo;website&rdquo; and through third-party websites. As such, any &ldquo;contributions&rdquo; you transmit may be treated as non-confidential and non-proprietary.</p>
										<p>&nbsp;</p>
										<p>When you create or make available any &ldquo;contributions, you thereby represent and warrant that:</p>
										<p>&nbsp;</p>
										<ul>
											<li>The creation, distribution, transmission, public display, accessing, downloading, or copying of your &ldquo;contributions&rdquo; do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third-party.</li>
											<li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the &ldquo;website&rdquo;, and other users of the &ldquo;website&rdquo; to use your &ldquo;contributions&rdquo; in any manner contemplated by the &ldquo;website&rdquo; and these Terms of Services.</li>
											<li>You have the written consent, release, and/or permission of each and every identifiable individual person in your &ldquo;contributions&rdquo; to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your &ldquo;contributions&rdquo; in any manner contemplated by the &ldquo;website&rdquo; and these Terms of Services.</li>
											<li>Your &ldquo;contributions&rdquo; are not false, inaccurate, or misleading.</li>
											<li>Your &ldquo;contributions&rdquo; are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
											<li>Your &ldquo;contributions&rdquo; are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
											<li>Your &ldquo;contributions&rdquo; do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
											<li>Your &ldquo;contributions&rdquo; do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.</li>
											<li>Your &ldquo;contributions&rdquo; do not violate any applicable law, regulation, or rule.</li>
											<li>Your &ldquo;contributions&rdquo; do not violate the privacy or publicity rights of any third-party service provider.</li>
											<li>Your &ldquo;contributions&rdquo; do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.</li>
											<li>Your &ldquo;contributions&rdquo; do not violate any federal or state law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
											<li>Your &ldquo;contributions&rdquo; do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
										</ul>
										<p>&nbsp;</p>
										<p>Any use of the &ldquo;website&rdquo; in violation of the foregoing, infringes these Terms of Services and may result in, among other things, termination or suspension of your rights to use the &ldquo;website&rdquo;.</p>
										<p>&nbsp;</p>
										<p><strong>Contribution license</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>By posting your &ldquo;contributions&rdquo; to any part of the &ldquo;website&rdquo;, or making these &ldquo;contributions&rdquo; accessible to the &ldquo;site&rdquo; by linking your account to any of your social networking accounts, you automatically grant, and you represent and warrant that you have the right to permit to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such &ldquo;contributions&rdquo; (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such &ldquo;contributions&rdquo;, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>
										<p>&nbsp;</p>
										<p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any &ldquo;contributions&rdquo;; (2) to re-categorize any &ldquo;contributions&rdquo; to place them in more appropriate locations on the &ldquo;site&rdquo;; and (3) to pre-screen or delete any &ldquo;contributions&rdquo; at any time and for any reason, without notice. We have no obligation to monitor your &ldquo;contributions&rdquo;.</p>
										<p>&nbsp;</p>
										<p><strong>Guidelines for reviews</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We may provide you areas on the website to leave reviews or ratings. When posting a review, you must comply with the following criteria:</p>
										<p>&nbsp;</p>
										<ul>
											<li>You should have first used the website.</li>
											<li>Your reviews should not contain offensive profanity, or abusive, racist, offensive, hate language, discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability.</li>
											<li>Your reviews should not contain references to illegal activity.</li>
											<li>You should not be affiliated with competitors if posting negative reviews.</li>
											<li>You should not make any conclusions as to the legality of conduct.</li>
											<li>You may not post any false or misleading statements.</li>
											<li>You may not organize a campaign encouraging others to post reviews, whether positive or negative.</li>
										</ul>
										<p>&nbsp;</p>
										<p>We may accept, reject, or remove reviews in our own discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.</p>
										<p>&nbsp;</p>
										<p>By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.</p>
										<p>&nbsp;</p>
										<p><strong>Social media</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>As part of the functionality of the &ldquo;website&rdquo;, you may link your third-party accounts by either: (1) providing your Third-Party Account login information through the &ldquo;website&rdquo;; or (2) allowing us to access your Third-Party Account, as is permitted under the applicable terms of services that govern your use of each Third-Party Account.</p>
										<p>&nbsp;</p>
										<p>By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content that you have provided to and stored in your Third-Party Account (the &ldquo;Social Network Content&rdquo;) so that it is available on and through the &ldquo;website&rdquo; via your account, including without limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional information to the extent you are notified when you link your account with the Third-Party Account.</p>
										<p>&nbsp;</p>
										<p><strong>Submissions</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the &ldquo;website&rdquo; provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these &ldquo;submissions&rdquo; for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
										<p>&nbsp;</p>
										<p><strong>Website management</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We reserve the right, but not the obligation, to:</p>
										<p><strong>&nbsp;</strong></p>
										<ul>
											<li>Monitor the &ldquo;website&rdquo; for violations of these Terms of Services.</li>
											<li>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Services, including without limitation, reporting such user to law enforcement authorities.</li>
											<li>In our sole discretion and without limitation, notice, or liability, to remove from the &ldquo;website&rdquo; or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</li>
											<li>Otherwise manage the &ldquo;website&rdquo; in a manner designed to protect our rights and property and to facilitate the proper functioning of the &ldquo;website&rdquo;.</li>
										</ul>
										<p>&nbsp;</p>
										<p><strong>Availability, Errors and Inaccuracies</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We are constantly updating our website. We may experience delays in updating information on the website. The information found on the website may contain errors or inaccuracies and may not be complete or current. We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
										<p>&nbsp;</p>
										<p><strong>Privacy policy</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We care about data privacy and security. Please review our Privacy Policy. By using the &ldquo;website&rdquo;, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Services. Please be advised the &ldquo;website&rdquo; is hosted in the Republic of the Philippines.</p>
										<p>&nbsp;</p>
										<p><strong>Termination</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>These Terms of Use shall remain in full force and effect while you use the &ldquo;website&rdquo;. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF SERVICES, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE &ldquo;WEBSITE&rdquo; TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE &ldquo;WEBSITE&rdquo; OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
										<p>&nbsp;</p>
										<p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
										<p>&nbsp;</p>
										<p>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
										<p>&nbsp;</p>
										<p><strong>Modifications and interruptions</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We reserve the right to change, modify, or remove the contents of the &ldquo;website&rdquo; at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our &ldquo;website&rdquo;. We also reserve the right to modify or discontinue all or part of the &ldquo;website&rdquo; without notice at any time.</p>
										<p>&nbsp;</p>
										<p>We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the &ldquo;website&rdquo;.</p>
										<p>&nbsp;</p>
										<p>We cannot guarantee the &ldquo;website&rdquo; will always be available. We may experience hardware, software, or other problems or need to perform maintenance related to the &ldquo;website&rdquo;, resulting in interruptions, delays, or errors.</p>
										<p>&nbsp;</p>
										<p><strong>Changes</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the website.</p>
										<p>&nbsp;</p>
										<p><strong>Indemnification</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>You agree to defend, indemnify and hold harmless Pinnacle Online Team and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>
										<p>&nbsp;</p>
										<p><strong>Limitation of Liability</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>In no event shall Pinnacle Online Team, nor its directors, employees, partners, agents, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
										<p>&nbsp;</p>
										<p><strong>Disclaimer</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>Your use of the website is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. Pinnacle Online do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
										<p>&nbsp;</p>
										<p><strong>Contact us</strong></p>
										<p><strong>&nbsp;</strong></p>
										<p>In order to resolve a complaint regarding the &ldquo;website&rdquo; or to receive further information regarding use of the &ldquo;website&rdquo;, please contact us at:</p>
										<p><strong>&nbsp;</strong></p>
										<p><strong>Email:</strong> <a href="mailto:pinnaclecpareviewschool@gmail.com" className="about-email-text">pinnaclecpareviewschool@gmail.com</a></p>
										<p>&nbsp;</p>
									</div>

								</div>
							</div>
						</FadeIn>
					</div>
				</div>
			</div >


			{/* MODAL REFERENCE INFORMATION */}
			< div class="modal-show-all modal-bg" style={{ display: modalReferenceDisplay }}>
				<div class="subject-modal modal-container dark mt-75 block-center reference-bg-color">
					<a class="close" onClick={(e) => closeModal(e, 'modalShowReference')}><img className="modal-close reference-modal-close" src={assetsUrl.iconClose} /></a>
					<FadeIn visible={modalReferenceDisplay === 'block' ? true : false}>
						<div className="pl-20 pr-20 pb-20 pt-20">
							<h3 className="modal-show-all-subject-title-mobile text-black">{selectedReferenceId.title}</h3>
							{/* <p className="modal-reference-description">{selectedReferenceId.description}</p> */}
							<hr />
							<div className="text-black modal-reference-content details-container-scrollable" >
								<p dangerouslySetInnerHTML={{ __html: selectedReferenceId.content }} />
							</div>
						</div>
					</FadeIn>
				</div>
			</div>

		</ThemeProvider >
	);
}

export default NavbarComponent;