import SubjectContent from './subjectContent';
import DashboardContextProvider from './subjectContext';

const Subjects = () => {
  return (
    <div>
      <DashboardContextProvider>
        <SubjectContent />
      </DashboardContextProvider>
    </div>
  );
}

export default Subjects;
