import axios from 'axios';
import React, { useState, createContext, useReducer, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { subjectVideosReducer } from './subjectVideosReducer';
import { BasePath, pinnacleStreamingV2ClientId, pinnacleStreamingV2ClientSecret } from '../../helpers/env';
import { noty } from '../../helpers/noty';
export const SubjectVideosContext = createContext();


const SubjectVideosContextProvider = (props) => {
  const history = useHistory();
  const [subjectVideos, dispatch] = useReducer(subjectVideosReducer, {
    results: {}
  });

  const retrieveVideosPerSubject = () => {
    var videoId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const initialState = {
      placeholder: 'none',
      spinner: 'block',
      subjectVideosStatus: 'start'
    }
    dispatch({
      type: 'SUBJECT_VIDEOS_RESULTS',
      initialState
    })
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    const parseUserDataStorage = userDataStorage !== null ? JSON.parse(userDataStorage) : null;
    const headers = {
      headers: {
        'x-client-id': pinnacleStreamingV2ClientId,
        'x-client-secret': pinnacleStreamingV2ClientSecret,
        'token': userDataStorage !== null ? parseUserDataStorage.data.token : null
      }
    }
    axios
      .get(`${BasePath}/v1/videos/subjects/${videoId}`, headers)
      .then(response => {
        dispatch({
          type: 'SUBJECT_VIDEOS_RESULTS',
          initialState: {
            placeholder: 'none',
            spinner: 'none',
            subjectVideosStatus: 'done'
          },
          payload: response.data
        })
      })
  }

  useEffect(() => {
    const userDataStorage = localStorage.getItem("pinnacleStreamingV2WebApp")
    if (userDataStorage === null) {
      //  history.push("/login");
    } else {
      const parseUserDataStorage = JSON.parse(userDataStorage)
      const headers = {
        headers: {
          'x-client-id': pinnacleStreamingV2ClientId,
          'x-client-secret': pinnacleStreamingV2ClientSecret,
          'token': parseUserDataStorage.data.token
        }
      }
      // axios
      //   .get(`${BasePath}/v1/auth/validate`, headers)
      //   .then(response => {
      //     dispatch({
      //       type: 'VALIDATE_USER',
      //       payload: response.data
      //     })
      //   })
      //   .catch(error => {
      //     if (error.response) {
      //       if (error.response.status === 401) {
      //         noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
      //         // history.push("/login");
      //       }
      //       if (error.response.status === 500) {
      //         noty("error", `<i class = "fa fa-info-circle"></i> Something went wrong. Please try again`);
      //         // history.push("/login");
      //       }
      //     }
      //   })
    }
  }, [])

  useEffect(() => {
    retrieveVideosPerSubject()
  }, [])




  return (
    <SubjectVideosContext.Provider
      value={{
        subjectVideos,
        dispatch,
        retrieveVideosPerSubject
      }}>
      {props.children}
    </SubjectVideosContext.Provider>
  )
}

export default SubjectVideosContextProvider;
