import ReferenceContextProvider from './referenceContext';
import ReferenceContent from './referenceContent';

const Reference = () => {
  return (
    <div>
      <ReferenceContextProvider>
        <ReferenceContent />
      </ReferenceContextProvider>
    </div>
  );
}

export default Reference;
